import React from 'react'
import PropTypes from 'prop-types'
import { Heart, Spade, Diamond, Club, NoTrump } from 'components/elements/Icons'

const Suit = ({ suit, inverted }) => {
  switch (suit ? suit.toUpperCase() : '') {
    case 'S':
      return <Spade />
    case 'H':
      return <Heart />
    case 'D':
      return <Diamond />
    case 'C':
      return <Club />
    case 'N':
      return <NoTrump />
    default:
      return null
  }
}

Suit.propTypes = {
  suit: PropTypes.string,
}

export default Suit
