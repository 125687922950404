import styled from 'styled-components'
import { Colors } from 'helpers/colors'

export const HomeContainer = styled.div`
  max-width: 768px;
`

export const CardRole = styled.div`
  text-transform: uppercase;
  font-size: ${p => (p.small ? '70%' : '85%')};
`

export const ActionBox = styled.div`
  background-color: white;
  border: 2px solid ${Colors.primary};
  border-radius: 10px;
  padding: 10px;
  margin: 0 10px;
`

export const ActionHeader = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`

export const ShareGrid = styled.div`
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 1fr min-content;
  align-items: stretch;
  row-gap: 5px;
`

export const ShareRow = styled.div`
  display: contents;
  &:hover div {
    background-color: ${Colors.highlight};
  }
`
export const ShareName = styled.div`
  grid-column: 1;
  display: flex;
  line-height: 2rem;
  justify-content: space-between;
  padding-right: 0.5rem;
  @media (max-width: 499px) {
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1.1;
  }
`

export const ShareButtons = styled.div`
  grid-column: 2;
  height: 2rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 0.25em;
    white-space: nowrap;
  }
  @media (max-width: 499px) {
    display: block;
    height: auto;
    padding-bottom: 1em;
  }
`

export const NameSearchResults = styled.div`
  margin-top: 10px;
  margin-left: 20px;
`

export const NameSearchRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: ${Colors.highlight};
  }
`
