// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { FormControl } from 'react-bootstrap'
import { HtmlFromMarkdown } from 'components/elements/Misc'

// import components

// import styles
import { EditNotesContainer, Heading, Editor, Content } from './styles'

// local constants

// --------------------

const EditNotes = ({ cardId, notes, updateNotes }) => {
  const handleChange = e => updateNotes(e.target.value)

  return (
    <EditNotesContainer>
      <Heading>
        Enter content in Markdown format{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.markdownguide.org/basic-syntax/'
        >
          About Markdown...
        </a>
      </Heading>
      <Heading>Will display as:</Heading>
      <Editor>
        <FormControl
          name='notes'
          as='textarea'
          value={notes}
          onChange={handleChange}
          autoFocus
        />
      </Editor>
      <Content>
        <HtmlFromMarkdown markdown={notes} />
      </Content>
    </EditNotesContainer>
  )
}

EditNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  updateNotes: PropTypes.func.isRequired,
}

export default EditNotes
