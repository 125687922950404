// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Diamond, Spade, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'
import GridArea from './parts/GridArea'

// import styles
import { Line, Text } from './styles/parts'
import { NoTrumpsGrid1, NtResponseGrid, ThirdLeft } from './styles/grids'
import { SectionIcon, SectionDivider } from './styles/sections'

// local constants

// --------------------

const NoTrumps = () => {
  return (
    <Section title='No Trump' area='no-trumps'>
      <NoTrumpsGrid1>
        <GridArea area='nta-header'>
          <Line>
            <SectionIcon announce>
              1<NoTrump />
            </SectionIcon>
            <Input center announce field='1_no_trump#a_range_min' />
            <Input
              noPad
              center
              announce
              title='to'
              field='1_no_trump#a_range_max'
            />
            <Input
              smaller
              title='(Seat/Vul'
              after=')'
              field='1_no_trump#a_range_seat_vul'
            />
          </Line>
        </GridArea>

        <GridArea area='ntb-header'>
          <Line>
            <SectionIcon announce>
              1<NoTrump />
            </SectionIcon>
            <Input noPad center announce field='1_no_trump#b_range_min' />
            <Input
              noPad
              center
              announce
              title='to'
              field='1_no_trump#b_range_max'
            />
            <Check title='Same Resp' field='1_no_trump#b_range_same_resp' />
          </Line>
        </GridArea>

        <GridArea area='nta'>
          <Line>
            <Check noPad title='5-Card Major' field='1_no_trump#5_card_major' />
            <Text leftPad></Text>
            <Input title='Sys On vs' field='1_no_trump#sys_on_vs' />
          </Line>
          <NtResponseGrid>
            <Text noPad>
              2<Club />:
            </Text>
            <div style={{ gridColumn: '2 / 6' }}>
              <Line>
                <Check leftPad title='Stayman' field='1_no_trump#2c_stayman' />
                <Check title='Puppet' field='1_no_trump#2c_puppet' />
                <Check title='Other' alert field='1_no_trump#2c_other' />
              </Line>
            </div>
            <Text noPad>
              2<Diamond />:
            </Text>
            <Check title='Nat' field='1_no_trump#2d_nat' />
            <Check announce title='Tfr' field='1_no_trump#2d_tfr' />
            <Input title='Other' alert field='1_no_trump#2d_other' />

            <Text noPad>
              2<Heart />:
            </Text>
            <Check title='Nat' field='1_no_trump#2h_nat' />
            <Check announce title='Tfr' field='1_no_trump#2h_tfr' />
            <Input title='Other' alert field='1_no_trump#2h_other' />

            <Text noPad>
              2<Spade />:
            </Text>
            <Check title='Nat' field='1_no_trump#2s_nat' />
            <Check announce title='Tfr' field='1_no_trump#2s_tfr' />
            <Input title='Other' alert field='1_no_trump#2s_other' />

            <Text noPad>
              2<NoTrump />:
            </Text>
            <Check title='Nat' field='1_no_trump#2nt_nat' />
            <Check announce title='Tfr' field='1_no_trump#2nt_tfr' />
            <Input title='Other' alert field='1_no_trump#2nt_other' />
          </NtResponseGrid>

          <Line flexStart>
            <Check noPad alert title='Smolen' field='1_no_trump#smolen' />
            <Text announce leftPad>
              Tfr:
            </Text>
            <Check
              announce
              title={
                <span>
                  4<Club />
                </span>
              }
              field='1_no_trump#tfr_4c'
            />
            <Check
              announce
              title={
                <span>
                  4<Diamond hollow />
                </span>
              }
              field='1_no_trump#tfr_4d'
            />
            <Check
              flexStart
              announce
              title={
                <span>
                  4<Heart hollow />
                </span>
              }
              field='1_no_trump#tfr_4h'
            />
          </Line>
        </GridArea>

        <GridArea area='ntb'>
          <Line>
            <Input
              title={
                <span>
                  3<Club />
                </span>
              }
              field='1_no_trump#3c'
            />
          </Line>
          <Line>
            <Input
              title={
                <span>
                  3<Diamond hollow />
                </span>
              }
              field='1_no_trump#3d'
            />
          </Line>
          <Line>
            <Input
              title={
                <span>
                  3<Heart hollow />
                </span>
              }
              field='1_no_trump#3h'
            />
          </Line>
          <Line>
            <Input
              title={
                <span>
                  3<Spade />
                </span>
              }
              field='1_no_trump#3s'
            />
          </Line>
          <Line>
            <Input alert title='Other' field='1_no_trump#other' />
          </Line>
          <Line>
            <Input alert field='1_no_trump#more' />
          </Line>
        </GridArea>

        <GridArea area='other'>
          <Line>
            <Text>Dbl:</Text>
            <Check title='Neg' field='1_no_trump#dbl_neg' />
            <Input field='1_no_trump#dbl_neg_desc' />
            <Check title='Penalty' field='1_no_trump#dbl_pen' />
            <Input title='Other' alert field='1_no_trump#dbl_other' />
            <Check alert title='Lebensohl' field='1_no_trump#lebensohl' />
            <Input alert field='1_no_trump#lebensohl_desc' />
          </Line>
        </GridArea>
      </NoTrumpsGrid1>

      <SectionDivider />

      <ThirdLeft>
        <GridArea area='left'>
          <Line>
            <SectionIcon>
              2<NoTrump />
            </SectionIcon>
            <Input center field='2_no_trump#range_min' />
            <Input center title='to' field='2_no_trump#range_max' />
          </Line>
        </GridArea>
        <GridArea area='right'>
          <Line>
            <Check title='Puppet' field='2_no_trump#puppet' />
            <Check
              alert
              title={
                <span>
                  3<Spade />
                </span>
              }
              field='2_no_trump#3s'
            />
            <Input alert field='2_no_trump#3s_desc' />
          </Line>
        </GridArea>
      </ThirdLeft>

      <ThirdLeft>
        <GridArea area='left'>
          <Line>
            <Check alert title='Conv' field='2_no_trump#conv' />
            <Input alert field='2_no_trump#conv_desc' />
          </Line>
        </GridArea>
        <GridArea area='right'>
          <Line>
            <Text announce>Tfr:</Text>
            <Check announce title='3Lvl' field='2_no_trump#tfr_3lvl' />
            <Check announce title='4Lvl' field='2_no_trump#tfr_4lvl' />
            <Check title='Neg Dbl' field='2_no_trump#neg_dbl' />
            <Input alert title='Other' field='2_no_trump#other' />
          </Line>
        </GridArea>
      </ThirdLeft>

      <SectionDivider />

      <ThirdLeft>
        <GridArea area='left'>
          <Line>
            <SectionIcon>
              3<NoTrump />
            </SectionIcon>
            <Input center field='3_no_trump#range_min' />
            <Input center title='to' field='3_no_trump#range_max' />
          </Line>
        </GridArea>
        <GridArea area='right'>
          <Line>
            <Check alert title='One Suit' field='3_no_trump#one_suit' />
            <Input alert field='3_no_trump#one_suit_desc' />
          </Line>
        </GridArea>
      </ThirdLeft>
    </Section>
  )
}
export default NoTrumps
