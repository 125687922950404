// import packages
import React from 'react'

// import apis

// import redux, selectors, actions
import { useSelector, useDispatch } from 'react-redux'
import { updateMemory } from 'redux/actions/memory'

// import contexts

// import hooks

// import utilities
import { cardSorter } from 'helpers'

// import common elements
import { Button, Collapse } from 'react-bootstrap'

// import components

// import styles
import { CardActionContainer } from './styles'

// local constants

// --------------------

export const useCompare = displayedCardId => {
  const reduxDispatch = useDispatch()
  const cards = useSelector(state => state.cards)

  const [showCompare, setShowCompare] = React.useState(false)
  const compareCard = useSelector(state => state.memory.compareCard)

  const toggleCompare = () => setShowCompare(t => !t)

  const setCompareWith = cardId => {
    reduxDispatch(updateMemory({ compareCard: cardId }))
    setShowCompare(false)
  }

  const stopCompare = () => {
    reduxDispatch(updateMemory({ compareCard: null }))
    setShowCompare(false)
  }

  const comparableCards = Object.values(cards)
    .filter(c => c.id !== displayedCardId)
    .sort(cardSorter)

  const compareButton = (
    <Button
      size='sm'
      className={compareCard ? '' : 'btn-outline-white-bg'}
      variant={compareCard ? 'info' : 'outline-info'}
      onClick={toggleCompare}
    >
      {compareCard ? `Comparing with ${cards[compareCard].title}` : 'Compare'}
    </Button>
  )

  const compareSelector = (
    <Collapse in={showCompare}>
      <div>
        <CardActionContainer>
          {comparableCards.length === 0 ? (
            <p>
              Sorry, you do not have any other cards available for comparison.
            </p>
          ) : (
            <div>
              <p>Select a card for comparison:</p>
              {compareCard && (
                <Button
                  size='sm'
                  variant='outline-danger'
                  className='mb-1'
                  onClick={stopCompare}
                >
                  Stop comparing
                </Button>
              )}
              {comparableCards.map(c => (
                <div className='mb-1' key={c.id}>
                  <Button
                    size='sm'
                    variant={compareCard === c.id ? 'info' : 'outline-info'}
                    onClick={() => setCompareWith(c.id)}
                  >
                    {c.title}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </CardActionContainer>
      </div>
    </Collapse>
  )

  return { compareButton, compareSelector }
}
