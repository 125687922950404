// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Diamond } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line } from './styles/parts'
import { SectionDivider } from './styles/sections'

// local constants

// --------------------

const Preempts = () => {
  return (
    <Section title='Preempts' area='preempts'>
      <Line>
        <Input title='3-Level Style' field='preempts#3_lvl_style' />
      </Line>
      <Line>
        <Input field='preempts#3_lvl_more' />
      </Line>
      <Line>
        <Input title='Resp' field='preempts#3_lvl_resp' />
      </Line>

      <SectionDivider />

      <Line>
        <Input title='4-Level Style' field='preempts#4_lvl_style' />
      </Line>
      <Line>
        <Input title='Resp' field='preempts#4_lvl_resp' />
      </Line>
      <Line>
        <Check
          announce
          title={
            <span>
              4<Club />
              /4
              <Diamond hollow /> Tfr
            </span>
          }
          field='preempts#4_lvl_minors_transfer'
        />
        <Input alert title='Other' field='preempts#4_lvl_other' />
      </Line>
    </Section>
  )
}

export default Preempts
