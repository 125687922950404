// import packages
import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

// import apis
import { apiUpdateUser } from 'api/user'

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'
import { NavContext } from 'contexts/NavContext'

// import hooks

// import utilities

// import common elements
import { PageLayout } from 'components/elements/Layout'

// import components
import { Form, Button, Spinner, FloatingLabel, Row, Col } from 'react-bootstrap'
import AuthenticatedNav from 'components/navs/AuthenticatedNav'
// import styles

// local constants

const ProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Must have at least 2 characters')
    .max(100, 'Cannot longer than 100 characters')
    .required('First name is required'),
  last_name: Yup.string()
    .min(2, 'Must have at least 2 characters')
    .max(100, 'Cannot longer than 100 characters')
    .required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .max(100, 'Cannot longer than 100 characters')
    .required('Email is required'),
  pdf_size: Yup.string().matches(
    /(letter|card|A4|large_print)/,
    'Must be letter, card, A4 or large print',
  ),
  text_align: Yup.string().matches(/(left|center)/, 'Must be left or center'),
})

// --------------------

const Profile = () => {
  const [loading, setLoading] = React.useState(false)
  const { authAxios } = React.useContext(FetchContext)
  const { retrieveHistoryPoint } = React.useContext(NavContext)
  const history = useHistory()

  const authContext = React.useContext(AuthContext)
  const { authState, updateUserInfo } = authContext
  const { userInfo } = authState

  const initialValues = {
    first_name: userInfo ? userInfo.first_name : '',
    last_name: userInfo ? userInfo.last_name : '',
    email: userInfo ? userInfo.email : '',
    pdf_size: userInfo ? userInfo.pdf_size : 'card',
    text_align: userInfo ? !!userInfo.text_align : 'left',
  }

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const goBack = () => history.push(retrieveHistoryPoint())

  const updateUser = async (values, setErrors) => {
    apiUpdateUser(authAxios, userInfo.id, values, {
      loading: setLoading,
      successClose: response => {
        updateUserInfo(response)
        goBack()
      },
      successMsg: 'Profile updated.',
    })
  }

  return (
    <PageLayout title='Profile'>
      <AuthenticatedNav />
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values,
          {
            authError,
            setError,
            setErrors,
            setSubmitting,
            resetForm,
            setFieldError,
          },
        ) => updateUser(values, setErrors)}
        validationSchema={ProfileSchema}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const input = field => {
            return (
              <Form.Group
                className='mt-4'
                key={field.name}
                controlId={field.name}
              >
                <FloatingLabel controlId={field.name} label={field.label}>
                  <Form.Control
                    name={field.name}
                    type={field.type}
                    placeholder=' '
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[field.name]}
                    isInvalid={touched[field.name] && !!errors[field.name]}
                  />
                </FloatingLabel>
                <Form.Control.Feedback type='invalid'>
                  {errors[field.name]}
                </Form.Control.Feedback>
                {field.help && <Form.Text>{field.help}</Form.Text>}
              </Form.Group>
            )
          }

          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={6}>
                  {input({
                    name: 'first_name',
                    type: 'text',
                    label: 'First name',
                  })}
                </Col>
                <Col xs={12} md={6}>
                  {input({
                    name: 'last_name',
                    type: 'text',
                    label: 'Last name',
                  })}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  {input({ name: 'email', type: 'text', label: 'Email' })}
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className='mt-3' controlId='pdf_size'>
                    <Form.Label>Default PDF Size:</Form.Label>
                    <Form.Check
                      checked={values.pdf_size === 'card'}
                      name='pdf_size'
                      type='radio'
                      id='card'
                      value='card'
                      label='Card (8"x8.5")'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Check
                      checked={values.pdf_size === 'letter'}
                      name='pdf_size'
                      type='radio'
                      id='letter'
                      value='letter'
                      label='Letter (8.5"x11")'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Check
                      checked={values.pdf_size === 'A4'}
                      name='pdf_size'
                      type='radio'
                      id='card'
                      value='A4'
                      label='A4 (21cm x 29.7cm)'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Check
                      checked={values.pdf_size === 'large_print'}
                      name='pdf_size'
                      type='radio'
                      id='large_print'
                      value='large_print'
                      label='Large Print on Letter'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className='mt-3' controlId='text_align'>
                    <Form.Label>Text field alignment in PDFs:</Form.Label>
                    <Form.Check
                      checked={!values.text_align}
                      name='text_align'
                      type='radio'
                      id='left'
                      value='left'
                      label='Left aligned'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Check
                      checked={values.text_align}
                      name='text_align'
                      type='radio'
                      id='center'
                      value='center'
                      label='Centered'
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col lg={12}>
                  <Button type='submit' variant='primary' disabled={loading}>
                    {loading && <Spinner />}
                    Update profile
                  </Button>
                  <Button onClick={goBack} variant='default' disabled={loading}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
      <p className='mt-5'>
        <strong>Preferred PDF Size:</strong> This sets the page size for PDFs
        that you generate. The 8.5"x11" size has a 0.5" top margin, 2" bottom
        margin, and 0.25in size margins. The A4 size is centered on the page.
      </p>
    </PageLayout>
  )
}

export default Profile
