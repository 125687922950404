// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiCardSharing } from 'api/cards'
import { apiFindSomeone } from 'api/user'

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { FormControl, InputGroup, Alert } from 'react-bootstrap'

// import components

// import styles
import { ShareGrid, NameSearchResults } from '../styles'

// local constants
import SharedWith from './SharedWith'
import SearchResult from './SearchResult'

// --------------------

const ShareOptions = ({ card }) => {
  const [names, setNames] = React.useState({})
  const [nameSearch, setNameSearch] = React.useState('')
  const [resultsReceived, setResultsReceived] = React.useState(false)
  const [results, setResults] = React.useState([])

  const { getCurrentUserId } = React.useContext(AuthContext)
  const { authAxios } = React.useContext(FetchContext)
  const currentUserId = getCurrentUserId()
  const cardId = card.id
  const isCreator = card.creator_id === currentUserId

  const users = [...card.editors, ...card.viewers].sort((a, b) =>
    names[a] < names[b] ? -1 : names[a] > names[b] ? 1 : 0,
  )

  React.useEffect(() => {
    const abortController = new AbortController()
    apiCardSharing(authAxios, cardId, {
      signal: abortController.signal,
      success: response => {
        setNames(response.names)
      },
    })
    return () => {
      abortController.abort()
    }
  }, [cardId, authAxios])

  React.useEffect(() => {
    const abortController = new AbortController()
    if (resultsReceived && nameSearch.length < 3) {
      setResultsReceived(false)
      setResults([])
    } else if (!resultsReceived && nameSearch.length === 3) {
      apiFindSomeone(authAxios, nameSearch, {
        signal: abortController.signal,
        success: response => {
          setResults(response.results)
          setResultsReceived(true)
        },
      })
    }
    return () => {
      abortController.abort()
    }
  }, [nameSearch, resultsReceived, authAxios])

  const updateShare = (id, fullName) => {
    setNames(n => ({ ...n, [id]: fullName }))
    setNameSearch('')
  }

  const handleNameSearchChange = e => {
    setNameSearch(e.target.value)
  }

  const matchesSearch = result => {
    if (card.editors.includes(result.id)) {
      return false
    }
    if (card.viewers.includes(result.id)) {
      return false
    }
    let text = nameSearch.toLowerCase()
    if (result.first_name.toLowerCase().includes(text)) {
      return true
    }
    if (result.last_name.toLowerCase().includes(text)) {
      return true
    }
    let full_name = `${result.first_name} ${result.last_name}`
    if (full_name.toLowerCase().includes(text)) {
      return true
    }
    return false
  }

  const filteredResults = resultsReceived ? results.filter(matchesSearch) : []

  return (
    <Alert variant='success'>
      <p className='mb-0'>
        <strong>Shared with:</strong>
      </p>
      <ShareGrid>
        {users
          .filter(id => id !== currentUserId)
          .map(id => (
            <SharedWith key={id} withId={id} fullName={names[id]} card={card} />
          ))}
      </ShareGrid>
      {!isCreator ? (
        <p>Created by {names[card.creator_id]}.</p>
      ) : (
        <div>
          <InputGroup>
            <InputGroup.Text>Share with:</InputGroup.Text>
            <FormControl
              onChange={handleNameSearchChange}
              placeholder='Start typing name ...'
              autoFocus
              type='text'
              value={nameSearch}
            />
          </InputGroup>
          {resultsReceived && nameSearch.length >= 3 && (
            <div>
              {filteredResults.length === 0 ? (
                <p>No one matches your search.</p>
              ) : (
                <NameSearchResults>
                  {filteredResults.map(r => (
                    <SearchResult
                      key={r.id}
                      result={r}
                      cardId={card.id}
                      updateShare={updateShare}
                    />
                  ))}
                </NameSearchResults>
              )}
            </div>
          )}
        </div>
      )}
    </Alert>
  )
}

ShareOptions.propTypes = {
  card: PropTypes.object.isRequired,
}

export default ShareOptions
