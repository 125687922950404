import React from 'react'
import PropTypes from 'prop-types'
// import packages

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Button, ButtonToolbar } from 'react-bootstrap'

// import components

// import styles

// local constants

// --------------------

const Confirm = props => {
  const [clicked, setClicked] = React.useState(false)
  const { open } = props

  React.useEffect(() => {
    setClicked(false)
  }, [open])

  const confirm = () => {
    setClicked(true)
    props.onConfirm()
  }

  const cancel = () => {
    setClicked(true)
    props.onCancel()
  }

  return (
    <div className='mt-3'>
      <p className='mb-0'>
        <strong>{props.content}</strong>
      </p>
      <ButtonToolbar>
        <Button
          size='sm'
          variant='primary'
          disabled={clicked}
          onClick={confirm}
        >
          {props.confirmButton || 'Yes'}
        </Button>
        <Button
          size='sm'
          variant='secondary'
          disabled={clicked}
          onClick={cancel}
        >
          {props.cancelButton || 'No'}
        </Button>
      </ButtonToolbar>
    </div>
  )
}

Confirm.propTypes = {
  content: PropTypes.node.isRequired,
  confirmButton: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelButton: PropTypes.node,
}

export default Confirm
