// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { micromark } from 'micromark'
import { gfm, gfmHtml } from 'micromark-extension-gfm'
import parse from 'html-react-parser'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities
import { withSuitSymbols } from 'helpers'

// import common elements

// import components

// import styles

// local constants

// --------------------

// Note that we are using withSuitSymbols rather that parseSuits so that text can be copy/pasted into other apps

const HtmlFromMarkdown = ({ markdown }) => {
  const html = micromark(withSuitSymbols(markdown), {
    extensions: [gfm()],
    htmlExtensions: [gfmHtml()],
  })
    .replace(/♦|♥/gi, x => `<span class='red-suit'>${x}</span>`)
    .replace(/♣|♠/gi, x => `<span class='black-suit'>${x}</span>`)

  return <div className='markdown-content'>{parse(html)}</div>
}

HtmlFromMarkdown.propTypes = {
  markdown: PropTypes.string.isRequired,
}

export default HtmlFromMarkdown
