// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiShareCard } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { updateCardAttributes } from 'redux/actions/cards'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { Button } from 'react-bootstrap'

// import components

// import styles
import { ShareRow, ShareName, ShareButtons, CardRole } from '../styles'

// local constants

// --------------------

const SharedWith = ({ withId, fullName, card }) => {
  const reduxDispatch = useDispatch()
  const { authAxios } = React.useContext(FetchContext)

  const otherRole = card.editors.includes(withId) ? 'viewer' : 'editor'

  const role = card.editors.includes(withId) ? (
    <CardRole editor>Editor</CardRole>
  ) : card.viewers.includes(withId) ? (
    <CardRole viewer>Read only</CardRole>
  ) : (
    0
  )

  const switchRole = () => {
    apiShareCard(
      authAxios,
      card.id,
      { with_id: withId, role: otherRole },
      {
        successDispatch: [reduxDispatch, updateCardAttributes],
      },
    )
  }

  const remove = () => {
    apiShareCard(
      authAxios,
      card.id,
      { with_id: withId, role: null },
      {
        successDispatch: [reduxDispatch, updateCardAttributes],
      },
    )
  }

  return (
    <ShareRow>
      <ShareName>
        <div>{fullName}</div>
        {role}
      </ShareName>
      <ShareButtons>
        <Button
          size='sm'
          variant={
            otherRole === 'viewer' ? 'outline-success' : 'outline-primary'
          }
          onClick={switchRole}
        >
          Change to {otherRole === 'viewer' ? 'read-only' : 'editor'}
        </Button>
        <Button size='sm' variant='outline-danger' onClick={remove}>
          Remove
        </Button>
      </ShareButtons>
    </ShareRow>
  )
}

SharedWith.propTypes = {
  card: PropTypes.object.isRequired,
  withId: PropTypes.number.isRequired,
  fullName: PropTypes.string,
}

export default SharedWith
