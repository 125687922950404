import { toast } from 'react-toastify'

// const handleFetchResponseErrors = response => {
//   if (!response.ok) {
//     if (response.status === 401) {
//       window.location.href = '/'
//     } else {
//       throw Error(response.statusText)
//     }
//   }
//   return response
// }

const catchFetchErrors = (ex, msg) => {
  if (ex.name === 'AbortError') {
    return
  }
  if (ex.message.includes('fetch') || ex.message.includes('unavailable')) {
    toast.error('Could not process request. Check internet connection.')
  } else {
    toast.error(`${msg}: ${ex.message}`)
  }
}

const request = async (
  authAxios,
  actionText,
  url,
  baseAttr,
  controls = {},
  isFileUpload = false,
) => {
  const attr = { ...baseAttr, url }
  // if (!isFileUpload) {
  //   attr.headers = ['Content-Type'] = 'application/json'
  // }
  if (controls.signal) {
    attr.signal = controls.signal
  }
  if (controls.loading) {
    controls.loading(true)
  }

  let skipFinally = false

  try {
    const { data } = await authAxios(attr)

    if (data.error) {
      if (controls.errorMsg) {
        toast.error(controls.errorMsg)
      }
      if (data.redirect) {
        setTimeout(() => {
          window.location.href = data.redirect
        }, 2000)
      }
      if (controls.error) {
        controls.error(data)
      }
      if (!controls.errorMsg && !controls.error) {
        toast.error(`Error ${actionText}: ${data.error}`, {
          autoClose: 10000,
        })
      }
    } else {
      if (data.success_message) {
        toast.success(data.success_message)
      } else if (controls.successMsg) {
        toast.success(controls.successMsg)
      }
      if (controls.successDispatch) {
        const [dispatch, action] = controls.successDispatch
        dispatch(action(data))
      }
      if (controls.success) {
        controls.success(data)
      }
      if (controls.skipFinally) {
        if (controls.skipFinally(data)) {
          skipFinally = true
        }
      }
      if (controls.successClose) {
        skipFinally = true
        if (typeof controls.successClose == 'function') {
          controls.successClose(data)
        }
      }
    }
  } catch (err) {
    catchFetchErrors(err, `Error ${actionText}`)
    if (controls.fetchError) {
      controls.fetchError()
    }
  } finally {
    if (controls.loading && !skipFinally) {
      controls.loading(false)
    }
  }
}

export const get = (authAxios, actionText, url, controls) => {
  const attr = {
    method: 'get',
  }
  request(authAxios, actionText, url, attr, controls)
}

export const post = (authAxios, actionText, url, data, controls) => {
  const attr = {
    method: 'post',
  }
  if (data) {
    attr.data = data
  }
  request(authAxios, actionText, url, attr, controls)
}

export const patch = (authAxios, actionText, url, data, controls) => {
  const attr = {
    method: 'patch',
  }
  if (data) {
    attr.data = data
  }
  request(authAxios, actionText, url, attr, controls)
}

export const uploadFile = (authAxios, actionText, url, data, controls) => {
  const attr = {
    method: 'patch',
    data,
  }
  request(authAxios, actionText, url, attr, controls, true)
}

export const destroy = (authAxios, actionText, url, controls) => {
  const attr = {
    method: 'delete',
  }
  request(authAxios, actionText, url, attr, controls)
}

export const getAsset = (authAxios, actionText, url, controls) => {
  const attr = {
    method: 'get',
    responseType: 'arraybuffer',
  }
  request(authAxios, actionText, url, attr, controls)
}

export const postAsset = (authAxios, actionText, url, data, controls) => {
  const attr = {
    method: 'post',
    data: data,
    responseType: 'arraybuffer',
  }
  request(authAxios, actionText, url, attr, controls)
}
