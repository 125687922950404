// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { CircularIcon } from 'components/elements/Icons'

// import components
import Footer from './Footer'

// import styles
import {
  FocusContainer,
  FocusedContent,
  FocusIcon,
  FocusTitle,
  FocusLinks,
} from './styles'

// local constants
import { Colors } from 'helpers/colors'

// --------------------

const Focus = props => {
  const { icon, title, links, children } = props
  return (
    <FocusContainer>
      <FocusedContent className='shadow-sm bg-light'>
        <div className='d-flex justify-content-between align-items-baseline'>
          <FocusIcon>
            <CircularIcon bg={Colors.secondary} color='#ffffff' icon={icon} />
          </FocusIcon>
          <FocusTitle>{title}</FocusTitle>
        </div>

        {children}

        <FocusLinks>
          {links.map((link, i) => (
            <div className='small text-uppercase' key={i} onClick={link[1]}>
              {link[0]}
            </div>
          ))}
        </FocusLinks>
      </FocusedContent>
      <Footer dark />
    </FocusContainer>
  )
}

Focus.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default Focus
