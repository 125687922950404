// import packages
import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

// import apis
import { apiResetUserPassword } from 'api/user'

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { Form, Button, Spinner, FloatingLabel, Row, Col } from 'react-bootstrap'

// import components
import { PageLayout } from 'components/elements/Layout'
import AuthenticatedNav from 'components/navs/AuthenticatedNav'

// import styles

// local constants

const ResetPasswordScheme = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
})

const ResetMyPassword = () => {
  const authContext = React.useContext(AuthContext)
  const { authState } = authContext
  const { userInfo } = authState
  const { authAxios } = React.useContext(FetchContext)

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const resetPassword = async (values, setErrors) => {
    apiResetUserPassword(authAxios, userInfo.id, values, {
      loading: setLoading,
      successMsg: 'Password updated.',
    })
  }

  return (
    <PageLayout title='Reset Password'>
      <AuthenticatedNav />
      <Formik
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        onSubmit={values => resetPassword(values)}
        validationSchema={ResetPasswordScheme}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const input = field => {
            return (
              <Form.Group
                className='mt-4'
                key={field.name}
                controlId={field.name}
              >
                <FloatingLabel controlId={field.name} label={field.label}>
                  <Form.Control
                    name={field.name}
                    type={field.type}
                    placeholder=' '
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[field.name]}
                    isInvalid={touched[field.name] && !!errors[field.name]}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors[field.name]}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            )
          }

          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={6}>
                  {input({
                    name: 'password',
                    type: 'text',
                    label: 'Password',
                  })}
                </Col>
                <Col xs={12} md={6}>
                  {input({
                    name: 'password_confirmation',
                    type: 'text',
                    label: 'Password confirmation',
                  })}
                </Col>
              </Row>

              <div className='d-grid my-3 '>
                <Button type='submit' variant='primary' disabled={loading}>
                  {loading && <Spinner />}
                  Reset Password
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </PageLayout>
  )
}

export default ResetMyPassword
