// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'
import { ThreeColGrid } from './styles/grids'

// local constants

// --------------------

const Signals = () => {
  return (
    <Section title='Signals' area='signals'>
      <Line flexStart>
        <Text>Primary Signals to:</Text>
      </Line>
      <Line>
        <Text>Declarer&apos;s Lead</Text>
        <Text>Partner&apos;s Lead</Text>
      </Line>

      <ThreeColGrid>
        <Check noPad field='signals#declarer_lead_att' />
        <Text noWrap>Attitude</Text>
        <Check noPad field='signals#partner_lead_att' />

        <Check noPad field='signals#declarer_lead_count' />
        <Text noWrap>Count</Text>
        <Check noPad field='signals#partner_lead_count' />

        <Check noPad field='signals#declarer_lead_pref' />
        <Text noWrap>Preference</Text>
        <Check noPad field='signals#partner_lead_pref' />
      </ThreeColGrid>

      <Line>
        <Input title='Exceptions' field='signals#exceptions' />
      </Line>

      <Line flexStart>
        <Text>First Discard:</Text>
        <Check title='Std' field='signals#first_discard_std' />
        <Check title='Upside Down' field='signals#first_discard_ud' />
      </Line>

      <Line flexStart>
        <Check title='Lavinthal' field='signals#lavinthal' />
        <Check title='Odd/Even' field='signals#odd_even' />
        <Check title='Other' field='signals#other' />
      </Line>

      <Line>
        <Input field='signals#other1' />
      </Line>

      <Line>
        <Input field='signals#other2' />
      </Line>
    </Section>
  )
}

export default Signals
