import {
  AUGMENT_CARDS,
  LOAD_CARDS,
  ADD_CARD,
  UPDATE_CARD,
  UPDATE_CARD_ATTRIBUTES,
  DELETE_CARD,
} from 'redux/action_types'

export function augmentCards(cards) {
  return {
    type: AUGMENT_CARDS,
    cards,
  }
}

export function loadCards(response) {
  return {
    type: LOAD_CARDS,
    ...response,
  }
}

export function addCard(response) {
  return {
    type: ADD_CARD,
    card: response.card,
  }
}

export function updateCard(response) {
  return {
    type: UPDATE_CARD,
    card: response.card,
  }
}

export function updateCardAttributes(response) {
  return {
    type: UPDATE_CARD_ATTRIBUTES,
    card: response.card,
  }
}

export function deleteCard(response) {
  return {
    type: DELETE_CARD,
    cardId: response.id,
  }
}
