import styled from 'styled-components'

export const NotesPage = styled.div`
  column-count: 2;
  column-gap: 2em;
  column-fill: auto;
  width: 100%;
  @media print {
    page-break-before: always;
    margin: 0.25in;
  }

  @media screen {
    margin: 0;
    background-color: #fff;
    border: 2px solid #000;
    padding: 10px;
    margin-top: 20px;
    max-width: 1280px;
  }
`
