// import packages
import React from 'react'
import { PDFDocument, PDFTextField, TextAlignment, cmyk } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

// import apis
import { apiGetPdf, apiGetLargePrintPdf, apiGetNotesPdf } from 'api/cards'

// import redux, selectors, actions
import { useSelector } from 'react-redux'

// import contexts
import { NavContext } from 'contexts/NavContext'
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities
import { toSnakeCase, withSuitSymbols } from 'helpers'
import { PAPER_SIZES, PAPER_SIZE_LABELS } from 'helpers/constants'

// import common elements
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import { Spinner } from 'components/elements/Misc'

// import components

// import styles

// local constants
const HOLLOW = true
const SPLIT_CHARS = [' ', ',', ';']
const TEXT_FONT_SIZES = {
  large: { min: 11, max: 13, names: 15 },
  standard: { min: 6, max: 8, names: 12 },
}
// const LINE_COLOR = cmyk(0, 0, 0, 1)
// const LINE_THICKNESS = 0.25
// const ALERT_LINE_COLOR = cmyk(0.15, 1, 1, 0)
// const LINE_OPACITY = 0.2

const cleanFileName = title =>
  toSnakeCase(title.replace(/[^a-zA-Z0-9_-]+/g, ''))

const getTextArray = string => {
  return string.split('')
}

const getNextWord = array => {
  let word = ''
  let nextChar
  do {
    nextChar = array.shift()
    if (typeof nextChar != 'undefined') {
      word += nextChar
    } else {
      nextChar = false
    }
  } while (nextChar && !SPLIT_CHARS.includes(nextChar))
  return word
}

// --------------------

const PdfModal = () => {
  const { pdfCardId, setPdfCardId } = React.useContext(NavContext)
  const { authAxios } = React.useContext(FetchContext)
  const { authState } = React.useContext(AuthContext)
  const { userInfo } = authState
  const [downloadActive, setDownloadActive] = React.useState(false)
  const [pdfSize, setPdfSize] = React.useState(userInfo.pdf_size)
  const [centered, setCentered] = React.useState(
    userInfo.text_align === 'center',
  )
  const card = useSelector(state => (pdfCardId ? state.cards[pdfCardId] : null))

  // sizes
  let widthPts, heightPts, offsetLeft, offsetRight, offsetBottom
  switch (pdfSize) {
    case 'letter':
      widthPts = 612
      heightPts = 792
      offsetLeft = 18
      offsetRight = 306
      offsetBottom = 144
      break
    case 'large_print':
      widthPts = 612
      heightPts = 792
      offsetLeft = 0
      offsetRight = 288
      offsetBottom = 0
      break
    case 'A4':
      widthPts = 595
      heightPts = 842
      offsetLeft = 9
      offsetRight = 298
      offsetBottom = 115
      break
    default:
      widthPts = 576
      heightPts = 612
      offsetLeft = 0
      offsetRight = 288
      offsetBottom = 0
      break
  }

  const fontSizes =
    pdfSize === 'large_print'
      ? TEXT_FONT_SIZES['large']
      : TEXT_FONT_SIZES['standard']

  const getPdf = async () => {
    if (downloadActive) {
      return
    }
    setDownloadActive(true)
    if (pdfSize === 'large_print') {
      apiGetLargePrintPdf(authAxios, pdfCardId, {
        success: createPdf,
        fetchError: () => setDownloadActive(false),
      })
    } else {
      apiGetPdf(authAxios, pdfCardId, {
        success: createPdf,
        fetchError: () => setDownloadActive(false),
      })
    }
  }

  const createPdf = async formResponse => {
    // Initialize document
    const pdfDoc = await PDFDocument.load(formResponse)

    // Initial size is 8inx8.5in, = 576pt x 612pt, except large print
    const cardPages = [pdfDoc.getPages()[0]]
    if (pdfSize !== 'card' && pdfSize !== 'large_print') {
      for (const cardPage of cardPages) {
        cardPage.setSize(widthPts, heightPts)
        cardPage.translateContent(offsetLeft, offsetBottom)
      }
    }

    const circleUrl = `${process.env.REACT_APP_API_URL}red-circle.png`
    const redCircle = await fetch(circleUrl).then(res => res.arrayBuffer())
    const redCircleImage = await pdfDoc.embedPng(redCircle)

    const fontUrl = `${process.env.REACT_APP_API_URL}bdex.ttf`
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)

    // Modify form fields based on data
    const footnotes = {}
    let footnoteNumber = 0
    let formField
    const form = pdfDoc.getForm()

    const findFieldPage = field => {
      const name = field.getName()
      const textField = form.getTextField(name)
      const widget = textField.acroField.getWidgets()[0]
      return pdfDoc.getPages().find(p => p.ref === widget.P())
    }

    for (const section in card.settings) {
      for (const field in card.settings[section]) {
        let requiredWidth
        let fieldWidth, line1Full, line2Full
        let fits = false
        let centerField = centered
        const fieldName = `${section}#${field}`
        let value = card.settings[section][field]
        if (value) {
        }
        if (value[0] === '/') {
          centerField = true
          value = value.substr(1)
        } else if (value[0] === '\\') {
          centerField = false
          value = value.substr(1)
        }
        if (centerField) {
          value = `${value}`.trim()
        }
        const valueWithSymbols = withSuitSymbols(value, HOLLOW)
        // testing without....
        const paddingAllowance = pdfSize === 'large_print' ? 0 : 0
        formField = form.getFieldMaybe(fieldName)
        if (formField) {
          if (formField instanceof PDFTextField) {
            // Figure width
            const fieldWidget = formField.acroField.getWidgets()[0]
            const currentRectangle = fieldWidget.getRectangle()
            fieldWidth = currentRectangle.width
            let fontSize = section === 'names' ? fontSizes.names : fontSizes.max
            while (fontSize >= fontSizes.min && !fits) {
              requiredWidth = customFont.widthOfTextAtSize(
                valueWithSymbols,
                fontSize,
              )
              if (requiredWidth <= fieldWidth + paddingAllowance) {
                // paddingAllowance accounts for padding in field
                fits = true
              } else {
                fontSize -= 0.5
              }
            }
            const textArray = getTextArray(valueWithSymbols)
            const useSecondLine = pdfSize !== 'large_print'
            let line1 = ''
            let line2 = ''

            // Replace the form field with a custom fit box, and copy overset text to footnote
            if (!fits) {
              // Fill line1, then line2 with whatever fits
              line1Full = false
              const footnoteText =
                textArray.length > 0 ? `…(${footnoteNumber + 1})` : ''
              line2Full = false
              let nextWord
              while (!line1Full && textArray.length > 0) {
                nextWord = getNextWord(textArray)
                if (
                  customFont.widthOfTextAtSize(
                    line1 + nextWord + (useSecondLine ? 0 : footnoteText),
                    fontSize,
                  ) <=
                  fieldWidth + paddingAllowance
                ) {
                  line1 += nextWord
                } else {
                  line1Full = true
                  if (useSecondLine) {
                    line2 += nextWord
                  } else {
                    line1 += footnoteText
                  }
                }
              }
              while (useSecondLine && !line2Full && textArray.length > 0) {
                nextWord = getNextWord(textArray)
                if (
                  customFont.widthOfTextAtSize(
                    line2 + nextWord + footnoteText,
                    fontSize,
                  ) <=
                  fieldWidth + paddingAllowance
                ) {
                  line2 += nextWord
                } else {
                  line2Full = true
                  line2 += footnoteText
                }
              }
              if (line2Full || (!useSecondLine && line1Full)) {
                // Queue up the footnote
                footnotes[section] ||= []
                footnoteNumber += 1
                footnotes[section].push({ value, number: footnoteNumber })
              }
            } else {
              line1 = valueWithSymbols
              line2 = ''
            }

            let cardPage = findFieldPage(formField)
            // cardPage.drawLine({
            //   start: { x: currentRectangle.x, y: currentRectangle.y },
            //   end: {
            //     x: currentRectangle.x + currentRectangle.width,
            //     y: currentRectangle.y + currentRectangle.height,
            //   },
            //   thickness: 0.5,
            //   color: LINE_COLOR,
            //   opacity: LINE_OPACITY,
            // })
            // cardPage.drawRectangle({
            //   x: currentRectangle.x,
            //   y: currentRectangle.y,
            //   width: currentRectangle.width,
            //   height: currentRectangle.height,
            //   color: cmyk(0, 0, 0, 1),
            //   opacity: 0.1,
            // })
            let x =
              currentRectangle.x +
              (centerField && fits
                ? (fieldWidth + paddingAllowance - requiredWidth) / 2
                : 0)
            // console.log(
            //   'in PdfModal.jsx - :',
            //   'section: ',
            //   section,
            //   ', field: ',
            //   field,
            //   ', requiredWidth: ',
            //   requiredWidth,
            //   ', fieldWidth: ',
            //   fieldWidth,
            // )
            cardPage.drawText(line1, {
              x: x,
              y: currentRectangle.y + (line2 ? fontSize : 0),
              font: customFont,
              size: fontSize,
              lineHeight: fontSize,
            })
            if (line2.length > 0) {
              cardPage.drawText(line2, {
                x: x,
                y: currentRectangle.y,
                font: customFont,
                size: fontSize,
                lineHeight: fontSize,
              })
            }
            form.removeField(formField)
          } else if ((formField = form.getCheckBox(fieldName))) {
            // Check a check box
            formField.check()
          }
        }
      }
    }
    // Handle character select field
    for (const pdfField of form.getFields()) {
      const name = pdfField.getName()
      if (name.includes('-')) {
        const [section, setting_field, selected] = name.split(/[#-]/)
        if (
          card.settings[section] &&
          card.settings[section][setting_field] === parseInt(selected)
        ) {
          pdfField.setImage(redCircleImage)
        } else {
          form.removeField(pdfField)
        }
        // } else if (pdfField instanceof PDFTextField) {
        //   let cardPage = findFieldPage(pdfField)
        //   const fieldWidget = pdfField.acroField.getWidgets()[0]
        //   const currentRectangle = fieldWidget.getRectangle()
        //   cardPage.drawLine({
        //     start: { x: currentRectangle.x, y: currentRectangle.y },
        //     end: {
        //       x: currentRectangle.x + currentRectangle.width,
        //       y: currentRectangle.y,
        //     },
        //     thickness: LINE_THICKNESS,
        //     color: LINE_COLOR,
        //     opacity: LINE_OPACITY,
        //   })
      }
    }

    // Handle the meta
    const metaField = form.getFieldMaybe('meta')
    if (metaField) {
      const timestamp = new Date(card.updated_at)
      metaField.setText(
        `${
          card.title
        }, ${timestamp.toLocaleDateString()}, ${timestamp.toLocaleTimeString()}`,
      )
      form.updateFieldAppearances(customFont)
      metaField.setFontSize(8)
      metaField.setAlignment(TextAlignment.Center)
    }

    form.updateFieldAppearances(customFont)
    form.flatten()

    appendNotes(pdfDoc, footnotes)
  }

  const appendNotes = async (pdfDoc, footnotes) => {
    if (card.notes.length > 0 || Object.keys(footnotes).length > 0) {
      apiGetNotesPdf(authAxios, pdfCardId, footnotes, pdfSize, {
        success: notesResponse => processNotes(notesResponse, pdfDoc),
        fetchError: () => setDownloadActive(false),
      })
    } else {
      finalizePdf(pdfDoc)
    }
  }

  const processNotes = async (notesResponse, pdfDoc) => {
    if (notesResponse) {
      // Embed the pages into the PDF, and then place them on alternate sides
      // of the pages. The notes rendered by the server are 3.75in aka 270pts.
      const notesDoc = await PDFDocument.load(notesResponse)

      const embeddedPages = await pdfDoc.embedPages(notesDoc.getPages())

      let leftSide = true
      let currentPage
      embeddedPages.forEach(page => {
        if (leftSide) {
          currentPage = pdfDoc.addPage([widthPts, heightPts])
        }
        // these are 4x8.5 or 4.24 x 11 aka 288x612/324X792, from server
        currentPage.drawPage(page, {
          width: pdfSize === 'large_print' ? 324 : 288,
          height: pdfSize === 'large_print' ? 792 : 612,
          x: leftSide ? offsetLeft : offsetRight,
          y: offsetBottom,
        })
        leftSide = !leftSide
      })
    }
    finalizePdf(pdfDoc)
  }

  const finalizePdf = async pdfDoc => {
    // Save and create downloadable link
    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `${cleanFileName(card.title)}.pdf`
    link.href = url
    link.click()
    setDownloadActive(false)
    setPdfCardId(null)
  }

  const close = () => {
    setDownloadActive(false)
    setPdfCardId(null)
  }

  return (
    <Modal show={!!pdfCardId} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Download PDF for {card ? card.title : '...'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <p>
            The card and any notes are formatted as 8in x 8.5in, but you can
            select the paper size for the generated PDF.
          </p>
          <p>
            Or, you can choose the large print version which prints on letter
            size paper.
          </p>

          <div>
            <ButtonGroup size='sm'>
              {PAPER_SIZES.map(size => (
                <Button
                  key={size}
                  variant={pdfSize === size ? 'primary' : 'outline-primary'}
                  onClick={() => setPdfSize(size)}
                >
                  {PAPER_SIZE_LABELS[size]}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          <div className='mt-3'>
            <p>
              Choose the default alignment for text fields on your card. You can
              override the alignment on any field by starting the text with a /
              for centered or a \ for left aligned.
            </p>
            <ButtonGroup size='sm'>
              <Button
                variant={centered ? 'primary' : 'outline-primary'}
                onClick={() => setCentered(true)}
              >
                Center text fields
              </Button>
              <Button
                variant={!centered ? 'primary' : 'outline-primary'}
                onClick={() => setCentered(false)}
              >
                Left align text fields
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          size='sm'
          variant='outline-info'
          onClick={getPdf}
          disabled={downloadActive}
        >
          <Spinner show={downloadActive} /> Download PDF
        </Button>
        <Button size='sm' variant='outline-secondary' onClick={close}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PdfModal
