import styled from 'styled-components'

export const MinorsGrid = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: min-content 1fr 2em 50%;
  grid-template-areas:
    'suits detail detail detail'
    'suits resp resp resp'
    'more more more more'
    'nt nt . raises';
`

export const RaisesGrid = styled.div`
  grid-area: raises;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr min-content min-content min-content;
  text-align: right;
`

export const NtResponseGrid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content min-content 1fr;
`

export const MajorsGrid = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: min-content auto 50%;
  column-gap: 1em;
  grid-template-areas:
    'suits       lengths     lengths'
    'suits       .           .'
    'nt-raises   nt-raises   .'
    'jump-raises jump-raises art-raises'
    'more        more        more';
`

export const NoTrumpsGrid1 = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 55% 1em 1fr;
  grid-template-areas:
    'nta-header ntb-header ntb-header'
    'nta . ntb'
    'other other other';
`

export const ThirdLeft = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 33% 1fr;
  column-gap: 1em;
  grid-template-areas: 'left right';
`
export const ThirdRight = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 40%;
  column-gap: 1em;
  grid-template-areas: 'left right';
`

export const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
`

export const CuebidsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
`

export const Vs1NTGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(7, auto);
  grid-auto-flow: column;
  align-items: flex-end;
`

export const VsTOGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content) auto;
  text-align: center;
`

export const ThreeColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  text-align: center;
`
