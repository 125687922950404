// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Diamond, Spade } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'
import { CuebidsGrid } from './styles/grids'

// local constants

// --------------------

const DirectCuebids = () => {
  return (
    <Section title='Direct Cuebids' area='direct-cuebids' dark>
      <CuebidsGrid>
        <Text noPad>Vs:</Text>
        <div>
          <div>
            <small>Art</small>
          </div>
          <div>
            <Club />
            <Diamond hollow />
          </div>
        </div>
        <div>
          <div>
            <small>Quasi</small>
          </div>
          <div>
            <Club />
            <Diamond hollow />
          </div>
        </div>
        <div>
          <div>
            <small>Nat</small>
          </div>
          <div>
            <Club />
            <Diamond hollow />
          </div>
        </div>
        <div>
          <div>
            <small>Nat</small>
          </div>
          <div>
            <Heart hollow />
            <Spade />
          </div>
        </div>

        <Text noPad>Michaels</Text>
        <div>
          <Check field='direct_cuebids#art_michaels' />
        </div>
        <div>
          <Check field='direct_cuebids#quasi_michaels' />
        </div>
        <div>
          <Check field='direct_cuebids#nat_minors_michaels' />
        </div>
        <div>
          <Check field='direct_cuebids#nat_majors_michaels' />
        </div>

        <Text noPad>Natural</Text>
        <div>
          <Check field='direct_cuebids#art_natural' />
        </div>
        <div>
          <Check field='direct_cuebids#quasi_natural' />
        </div>
        <div>
          <Check alert field='direct_cuebids#nat_minors_natural' />
        </div>
        <div>
          <Check alert field='direct_cuebids#nat_majors_natural' />
        </div>

        <Text noPad>Other</Text>
        <div>
          <Check field='direct_cuebids#art_other' />
        </div>
        <div>
          <Check field='direct_cuebids#quasi_other' />
        </div>
        <div>
          <Check alert field='direct_cuebids#nat_minors_other' />
        </div>
        <div>
          <Check alert field='direct_cuebids#nat_majors_other' />
        </div>
      </CuebidsGrid>

      <Line>
        <Input alert title='Describe' field='direct_cuebids#describe' />
      </Line>
    </Section>
  )
}
export default DirectCuebids
