import React from 'react'
import { PageLayout } from 'components/elements/Layout'

const Help = () => {
  return (
    <PageLayout title='Help'>
      <div>TBD</div>
    </PageLayout>
  )
}

export default Help
