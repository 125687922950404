import React from 'react'
import PropTypes from 'prop-types'
import raw from 'raw.macro'
import YAML from 'yaml'
const text = raw('./default_card.yml')
const blankCard = YAML.parse(text)

const CardStateContext = React.createContext(null)
const CardDispatchContext = React.createContext(null)

const initialState = props => {
  return {
    ...blankCard,
    ...props.settings,
    canEdit: props.canEdit,
    hasChanged: false,
    showOnlyActiveFields: false,
  }
}

const actionTypes = {
  update: 'UPDATE',
  updateCheck: 'UPDATE_CHECK',
  updateSelectedCharacter: 'UPDATE_SELECTED_CHARACTER',
  uploadCC: 'UPLOAD_CC',
  clear: 'CLEAR',
  resetChanged: 'RESET_CHANGED',
  setActiveOnly: 'SET_ACTIVE_ONLY',
}

function cardReducer(state, action) {
  const payload = action.payload
  let section, field, newValue, newState
  switch (action.type) {
    case actionTypes.update:
      ;[section, field] = payload.sectionField.split('#')
      return {
        ...state,
        hasChanged: true,
        [section]: { ...state[section], [field]: payload.value },
      }

    case actionTypes.updateCheck: {
      ;[section, field] = payload.sectionField.split('#')
      newValue = state[section][field] === 'on' ? null : 'on'
      return {
        ...state,
        hasChanged: true,
        [section]: { ...state[section], [field]: newValue },
      }
    }

    case actionTypes.updateSelectedCharacter: {
      ;[section, field] = payload.sectionField.split('#')
      return {
        ...state,
        hasChanged: true,
        [section]: { ...state[section], [field]: payload.selected },
      }
    }

    case actionTypes.uploadCC: {
      newState = { ...blankCard }
      for (const section in blankCard) {
        for (const field in blankCard[section]) {
          if (payload[section] && payload[section][field]) {
            newState[section][field] = payload[section][field]
          }
        }
      }
      return { ...state, ...newState }
    }

    case actionTypes.clear: {
      return { ...state, ...blankCard }
    }

    case actionTypes.resetChanged: {
      return { ...state, hasChanged: false }
    }

    case actionTypes.setActiveOnly: {
      return {
        ...state,
        showOnlyActiveFields: !state.canEdit && payload.activeOnly,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function CardProvider(props) {
  const [state, dispatch] = React.useReducer(cardReducer, initialState(props))
  return (
    <CardStateContext.Provider value={state}>
      <CardDispatchContext.Provider value={dispatch}>
        {props.children}
      </CardDispatchContext.Provider>
    </CardStateContext.Provider>
  )
}

function useCardState() {
  const context = React.useContext(CardStateContext)
  if (context === undefined) {
    throw new Error('useCardState must be used within a CardProvider')
  }
  const card = sectionField => {
    const [section, field] = sectionField.split('#')
    return context[section][field]
  }

  const { canEdit, hasChanged, showOnlyActiveFields } = context

  const cardData = () => {
    const data = {}
    for (const section in blankCard) {
      for (const field in blankCard[section]) {
        if (context[section][field]) {
          if (!data[section]) {
            data[section] = {}
          }
          data[section][field] = context[section][field]
        }
      }
    }
    return data
  }

  return { card, canEdit, hasChanged, cardData, showOnlyActiveFields }
}

function useCardDispatch() {
  const cardDispatch = React.useContext(CardDispatchContext)
  if (cardDispatch === undefined) {
    throw new Error('useCardDispatch must be used within a CardProvider')
  }

  const updateSelectedCharacter = (sectionField, selected) => {
    cardDispatch({
      type: actionTypes.updateSelectedCharacter,
      payload: { sectionField, selected },
    })
  }

  const updateCheck = sectionField => {
    cardDispatch({
      type: actionTypes.updateCheck,
      payload: { sectionField },
    })
  }

  const updateText = e => {
    cardDispatch({
      type: actionTypes.update,
      payload: { sectionField: e.target.name, value: e.target.value },
    })
  }

  const uploadCC = data => {
    cardDispatch({
      type: actionTypes.uploadCC,
      payload: data,
    })
  }

  const clear = () => {
    cardDispatch({ type: actionTypes.clear })
  }

  const resetChanged = () => {
    cardDispatch({ type: actionTypes.resetChanged })
  }

  const setActiveOnly = React.useCallback(
    activeOnly => {
      cardDispatch({ type: actionTypes.setActiveOnly, payload: { activeOnly } })
    },
    [cardDispatch],
  )

  return {
    cardDispatch,
    updateSelectedCharacter,
    updateCheck,
    updateText,
    uploadCC,
    clear,
    resetChanged,
    setActiveOnly,
  }
}

// remove CardStateContext once class components no longer need it
export { CardProvider, useCardState, useCardDispatch, actionTypes }

CardProvider.propTypes = {
  children: PropTypes.node,
}
