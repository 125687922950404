// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Diamond, Spade, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'
import GridArea from './parts/GridArea'

// import styles
import { Line, Text } from './styles/parts'
import { ThirdRight } from './styles/grids'
import { SectionIcon, SectionDivider } from './styles/sections'

// local constants

// --------------------

const TwoLevel = () => {
  return (
    <Section title='Two Level' area='two-level' dark>
      <ThirdRight>
        <GridArea area='left'>
          <Line>
            <SectionIcon>
              2<Club />
            </SectionIcon>
            <Input tight center field='two_level#2c_min' />
            <Input tight center title='to' field='two_level#2c_max' />
            <Input alert field='two_level#2c_range_desc' />
          </Line>
          <Line>
            <Check title='Very Str' field='two_level#2c_very_str' />
            <Check alert title='Str' field='two_level#2c_str' />
            <Check alert title='Nat' field='two_level#2c_nat' />
            <Check alert title='Conv' field='two_level#2c_conv' />
            <Input alert field='two_level#2c_conv_desc' />
          </Line>
        </GridArea>

        <GridArea area='right'>
          <Line flexStart>
            <Text>
              2<Diamond hollow />:
            </Text>
            <Check title='Neg' field='two_level#2c_2d_neg' />
            <Check title='Waiting' field='two_level#2c_2d_waiting' />
          </Line>
          <Line>
            <Check alert title='Steps' field='two_level#2c_steps' />
            <Input alert field='two_level#2c_steps_desc' />
            <Check
              alert
              title={
                <span>
                  2<Heart hollow /> Neg
                </span>
              }
              field='two_level#2c_2h_neg'
            />
          </Line>
          <Line>
            <Input alert title='Other' field='two_level#2c_other' />
          </Line>
        </GridArea>
      </ThirdRight>

      <SectionDivider />

      <Line>
        <SectionIcon>
          2<Diamond />
        </SectionIcon>
        <Input tight center field='two_level#2d_min' />
        <Input tight center title='to' field='two_level#2d_max' />
        <Input field='two_level#2d_desc' />
        <Check alert title='New Suit NF' field='two_level#2d_nsnf' />
      </Line>
      <Line>
        <Check title='Wk' field='two_level#2d_weak' />
        <Check alert title='Int' field='two_level#2d_int' />
        <Check alert title='Str' field='two_level#2d_str' />
        <Check alert title='Conv' field='two_level#2d_conv' />
        <Input
          title={
            <span>
              Rebids over 2<NoTrump />
            </span>
          }
          field='two_level#2d_rebids_2nt'
        />
        <Input title='Other' field='two_level#2d_other' />
      </Line>

      <SectionDivider />

      <Line>
        <SectionIcon>
          2<Heart />
        </SectionIcon>
        <Input tight center field='two_level#2h_min' />
        <Input tight center title='to' field='two_level#2h_max' />
        <Input field='two_level#2h_desc' />
        <Check alert title='New Suit NF' field='two_level#2h_nsnf' />
      </Line>
      <Line>
        <Check title='Wk' field='two_level#2h_weak' />
        <Check alert title='Int' field='two_level#2h_int' />
        <Check alert title='Str' field='two_level#2h_str' />
        <Check alert title='2 Suits' field='two_level#2h_2_suits' />
        <Input
          title={
            <span>
              Rebids over 2<NoTrump />
            </span>
          }
          field='two_level#2h_rebids_2nt'
        />
        <Input title='Other' field='two_level#2h_other' />
      </Line>

      <SectionDivider />

      <Line>
        <SectionIcon>
          2<Spade />
        </SectionIcon>
        <Input tight center field='two_level#2s_min' />
        <Input tight center title='to' field='two_level#2s_max' />
        <Input field='two_level#2s_desc' />
        <Check alert title='New Suit NF' field='two_level#2s_nsnf' />
      </Line>
      <Line>
        <Check title='Wk' field='two_level#2s_weak' />
        <Check alert title='Int' field='two_level#2s_int' />
        <Check alert title='Str' field='two_level#2s_str' />
        <Check alert title='2 Suits' field='two_level#2s_2_suits' />
        <Input
          title={
            <span>
              Rebids over 2<NoTrump />
            </span>
          }
          field='two_level#2s_rebids_2nt'
        />
        <Input title='Other' field='two_level#2s_other' />
      </Line>
    </Section>
  )
}

export default TwoLevel
