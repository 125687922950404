// import packages
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components

// import styles

// local constants
import { getIconClassname } from 'helpers/icons'
import { Colors } from 'helpers/colors'

// --------------------

const Icon = props => {
  let cls = getIconClassname(props.icon)
  if (!cls) {
    cls = ''
    console.warn(
      `---- UNRECOGNIZED ICON: %c${props.icon}`,
      'color: #aa0000; font-weight: bold;',
    )
    // } else {
    //   console.info('ICON: ', props.icon)
  }
  if (props.classes) {
    cls = cls + ' ' + props.classes
  }
  if (props.tag) {
    cls = cls + ' tag-icon'
  }
  const clickable = !(
    props.onClick === null || typeof props.onClick === 'undefined'
  )
  let color = null
  if (props.danger) {
    color = Colors.text.danger
  }
  if (props.success) {
    color = Colors.text.success
  }
  if (props.warning) {
    color = Colors.text.warning
  }
  if (props.info) {
    color = Colors.text.info
  }
  if (props.positive) {
    color = Colors.positive
  }
  if (props.negative) {
    color = Colors.negative
  }
  if (props.neutral) {
    color = Colors.neutral
  }
  if (props.transparent) {
    color = 'transparent'
  }
  if (props.color) {
    color = props.color
  }
  if (props.redSuit) {
    color = Colors.redSuit + ' !important'
  }
  if (props.blackSuit) {
    color = (props.inverted ? Colors.white : Colors.blackSuit) + ' !important'
  }

  return (
    <IconContainer
      className={cls}
      clickable={clickable}
      color={color}
      spaceBefore={props.spaceBefore}
      spaceAfter={props.spaceAfter}
      small={props.small}
      big={props.big}
      onClick={props.onClick}
      navSelector={props.navSelector}
    />
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.string,
  color: PropTypes.string,

  danger: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  info: PropTypes.bool,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  neutral: PropTypes.bool,
  transparent: PropTypes.bool,
  redSuit: PropTypes.bool,
  blackSuit: PropTypes.bool,

  inverted: PropTypes.bool,
  tag: PropTypes.bool,
  navSelector: PropTypes.bool,

  spaceAfter: PropTypes.bool,
  spaceBefore: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
}

Icon.defaultProps = {
  danger: false,
  success: false,
  warning: false,
  info: false,
  positive: false,
  negative: false,
  neutral: false,
  transparent: false,
  redSuit: false,
  blackSuit: false,

  inverted: false,
  tag: false,

  spaceAfter: false,
  spaceBefore: false,
  small: false,
  big: false,
}

export default Icon

const IconContainer = styled.span`
  ${p => (p.color ? `color: ${p.color};` : '')}
  ${p => (p.small ? 'font-size: 85%;' : '')}
  ${p => (p.big ? 'font-size: 22px;' : '')}
  ${p => (p.spaceBefore ? 'margin-left: 0.3em;' : '')}
  ${p => (p.spaceAfter ? 'margin-right: 0.3em;' : '')}
  ${p => (p.clickable ? 'cursor: pointer;' : '')}
`
