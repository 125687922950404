import styled from 'styled-components'

export const CardContainer = styled.div`
  border: 2px solid #000;
  border-bottom: none;
  font-size: 16px;
  overflow-y: hidden;
  min-height: 0.1%;
  overflow-x: auto;

  @media screen and (min-width: 1400px) {
    display: flex;
    width: 1288px;
  }
  @media screen and (max-width: 1399.98px) {
    display: block;
    width: 648px;
  }

  @media screen and (max-width: 767.98px) {
    display: block;
    width: min(calc(100vw - 2rem), 648px);
  }

  @media print {
    width: calc(8in + 1pt);
    // height: 8.5in;
    overflow-y: visible;
    .full-width-container & {
      width: 8in;
    }
    font-size: 7pt;
    border-width: 1pt;
    border-left: none;
    display: flex;
  }
`

const ColumnContainer = styled.div`
  position: relative;
  .logo {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 60px;
    img {
      height: 60px;
      object-fit: cover;
    }
  }
  @media print {
    .logo {
      top: 4pt;
      right: 4pt;
      height: 27pt;
      img {
        height: 27pt;
      }
    }
  }

  line-height: 1.4;
  color: #222;
  display: grid;
  grid-template-columns: 50% 50%;
  // background color fixes gaps between blocks showing a pixel or two of background
  background-color: #000;

  @media screen and (min-width: 1400px) {
    flex: 1 1 644px;
  }
  @media screen and (max-width: 1399.98) {
    width: 644px;
  }
  @media screen and (max-width: 767.98px) {
    width: max(calc(100vw - 2rem), 644px);
  }

  @media print {
    width: 4in;
    flex: 0 0 4in;
  }
`

export const LeftColumn = styled(ColumnContainer)`
  order: 1;

  grid-template-areas:
    'doubles nt-overcalls'
    'overcalls vs-1nt-opening'
    'direct-cuebids vs-takeout-double'
    'preempts vs-preempts'
    'slams slams'
    'carding signals'
    'leads-vs-suits leads-vs-nt';
`

export const RightColumn = styled(ColumnContainer)`
  order: 2;
  grid-template-areas:
    'names names'
    'overview overview'
    'minors minors'
    'majors majors'
    'no-trumps no-trumps'
    'two-level two-level'
    'other other';
`
