import React from 'react'
import styled from 'styled-components'

const CopyrightContainer = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  border-top: 1px solid #ccc;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default function Copyright(props) {
  return (
    <CopyrightContainer
      className={`mt-3 pt-3 mb-2 mtx-auto text-center text-uppercase lh-lg${
        props.dark ? 'text-white-50' : 'text-secondary'
      }`}
    >
      {'Copyright © '}
      <a className='text-reset' href='https://www.rsvpbridge.com'>
        RSVP Bridge LLC
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
      <br />
      Convention Card format based on the{' '}
      <a
        className='text-reset'
        href='https://www.acbl.org/tournament/#concards'
      >
        2022 ACBL Convention Card
      </a>
      .
    </CopyrightContainer>
  )
}
