const PRIMARY = '#206790'
const SECONDARY = '#426f08'

export const Colors = {
  white: '#ffffff',
  highlight: '#fce17e',
  negative: '#aa0000',
  positive: '#007700',
  neutral: '#cccccc',
  redSuit: '#aa0000',
  blackSuit: '#222222',

  primary: PRIMARY,
  secondary: SECONDARY,

  app: {
    bg: '#545450',
  },
  nav: {
    bg: PRIMARY,
  },
  content: {
    bg: '#eaeae4',
  },
  focused: {
    bg: '#ffffff',
  },
}

export const Shadows = {
  base: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
}
