// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { PageLayout } from 'components/elements/Layout'
import { Copyright } from 'components/elements/Misc'

// import components
import PublicNav from 'components/navs/PublicNav'

// import styles
import RSVPlogo from 'assets/images/rsvp-bridge-med.png'
import logo from 'assets/images/bridgodex.png'

// local constants

// --------------------

const About = () => {
  return (
    <PageLayout skipFooter>
      <PublicNav />
      <div className='mt-5 mb-3 text-center'>
        <img src={logo} width='60%' alt='Bridgodex' />
      </div>

      <h1 className='text-center px-5 mb-5'>
        Build and store your convention cards
        <br />
        and system notes in one place
      </h1>

      <h1 className='text-center px-5 '>
        Collaborate with other players, compare cards,
        <br />
        use online* or print
      </h1>

      <p className='text-center my-3 px-3'>
        * with any online play platform that supports our format
      </p>

      <p className='text-center my-3'>
        Brought to you by <a href='https://www.rsvpbridge.com'>RSVP Bridge</a>
        , an online platform
        <br />
        for club management, reservations, payments, electronic scoring, and
        online-play.
      </p>

      <div className='text-center my-3'>
        <a href='https://www.rsvpbridge.com'>
          <img
            className='shadow'
            src={RSVPlogo}
            width='200'
            alt='RSVP Bridge'
          />
        </a>
      </div>
      <p className='text-center my-3'>
        For more information, please&nbsp;contact&nbsp;
        <a href='mailto:brenda@rsvpbridge.com'>Brenda&nbsp;Egeland</a>.
      </p>
      <div className='text-center my-3 small'>
        <a className='text-reset' href='/privacy'>
          Privacy Policy
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='text-reset' href='/terms-and-conditions'>
          Terms and Conditions
        </a>
      </div>
      <Copyright />
    </PageLayout>
  )
}

export default About
