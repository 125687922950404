// import packages
import React from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Navbar, Nav, Container } from 'react-bootstrap'

// import components

// import styles
import logo from 'assets/images/bridgodex-horizontal.png'

// local constants

// --------------------

const PublicNav = props => {
  const history = useHistory()

  const goToSignup = () => history.push('/signup')
  const goToLogin = () => history.push('/login')

  return (
    <Navbar
      bg='primary'
      fixed='top'
      variant='dark'
      expand='sm'
      className='shadow-sm no-print'
    >
      <Container fluid='xl'>
        <Navbar.Brand>
          <RouterLink to='/'>
            <img src={logo} height='36' alt='Bridgodex' />
          </RouterLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='main-navbar-nav' />
        <Navbar.Collapse id='main-navbar-nav' className='justify-content-end'>
          <Nav className='h6'>
            <Nav.Link onClick={goToSignup}>Sign up</Nav.Link>
            <Nav.Link onClick={goToLogin}>Log in</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default PublicNav
