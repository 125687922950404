import styled from 'styled-components'
const ALERT = '#b23b3b'
const ANNOUNCE = '#0083b6'
// const MAJOR_SECTION = '#95958f'
const MAJOR_SECTION = '#8caab1'
const HIGHLIGHT_BG = '#fdfd8d'

export const Highlighted = styled.div`
  background-color: ${HIGHLIGHT_BG};
`
export const HighlightedText = styled.span`
  background-color: ${HIGHLIGHT_BG};
`

export const ScrollSpyContainer = styled.div`
  position: fixed;
  top: 68px;
  right: 0;
  z-index: 2000;
  @media screen and (min-width: 1400px) {
    right: max(calc((100vw - 1320px) / 2 - 42px), 21px);
  }
  @media screen and (max-width: 1399.98px) {
    right: max(calc((100vw - 1140px) / 2 - 42px), 21px);
  }
  @media screen and (max-width: 1199.98px) {
    right: max(calc((100vw - 960px) / 2 - 42px), 21px);
  }
  @media screen and (max-width: 991.98px) {
    right: max(calc((100vw - 720px) / 2 - 42px), 21px);
  }
  @media screen and (max-width: 767.98px) {
    right: 0;
  }
`

export const CardContainer = styled.div`
  @media screen and (min-width: 1000px) {
    display: grid;
    grid-template-columns: 768px 1fr;
    column-gap: 10px;
    grid-template-areas: 'display spy';
  }
`
export const ButtonSpyContainer = styled.div`
  @media screen and (min-width: 1000px) {
    display: none;
  }
`

export const SpySidebarContainer = styled.div`
  right: 0;
  top: 60px;
  position: sticky;
  overflow-y: auto;
  height: calc(100vh - 7rem);
  padding-left: 20px;
  border-left: 5px solid #aaa;
  .spy-section {
    cursor: pointer;
    color: #777;
    &.active {
      font-weight: bold;
      color: #000;
    }
  }
  @media screen and (max-width: 999.99px) {
    display: none;
  }
`

export const LabelContainer = styled.span`
  color: ${p =>
    p.highlight === 'announce'
      ? ANNOUNCE
      : p.highlight === 'alert'
      ? ALERT
      : '#000'};
`

export const SectionContainer = styled.div`
  max-width: 768px;
`
export const SectionLabel = styled.div`
  ${p =>
    p.separator &&
    'color: #777; font-weight: bold; text-transform: uppercase; border-bottom: 1px solid #777;'}
  text-align: ${p => (p.separator ? 'left' : 'right')};
`

// \0A00 is non breaking space, to ensure proper height
export const Separator = styled.div`
  border-bottom: 1px solid #777;
  &:before {
    content: '\\00a0';
  }
`

export const SectionContent = styled.div`
  display: grid;
  grid-template-columns: minmax(125px, 30%) 1fr;
  grid-gap: 10px;
  align-items: baseline;
  padding: 10px 10px 30px 10px;
`

export const MajorSectionTitle = styled.div`
  width: 100%;
  font-size: 1.5em;
  line-height: 1.8;
  background-color: ${MAJOR_SECTION};
  padding: 0 0.5em;
  color: white;
  letter-spacing: 2px;
  border-radius: 5px 5px 0 0;
  position: sticky;
  top: 60px;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto Slab', serif;
`

export const GroupContainer = styled.div`
  display: ${p => (p.inline ? 'flex' : 'block')};
  ${p => p.inline && 'align-items: baseline;'}
`

export const GroupLabel = styled.div``
export const GroupFields = styled.div`
  @media (min-width: 576px) {
    display: ${p => (p.inline ? 'flex' : 'block')};
    ${p => p.inline && 'flex-flow: row wrap;'}
  }
`

export const InputLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0.25rem 0;
`
export const InputLabel = styled.div`
  padding-right: 0.5rem;
`
export const ValueArea = styled.div`
  flex: ${p => (p.short ? ' 0 0 4rem' : '1 1 auto')};

  input {
    padding: 0 0.25rem;
    margin: 0;
    width: 100%;
    border: none;
    border-radius: 3px;
    background-color: #fff;
    color: '#000';
  }
  border-bottom: 1px dotted
    ${p =>
      p.highlight === 'announce'
        ? ANNOUNCE
        : p.highlight === 'alert'
        ? ALERT
        : '#444'};
`

export const RangeLine = styled.div`
  padding: 0.25rem 0;
  @media (min-width: 576px) {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (max-width: 575.99px) {
    display: grid;
    grid-template-columns: min-content 1fr;
  }
`
export const RangeLabel = styled.div`
  padding-right: 0.5rem;
  white-space: nowrap;
`

export const RangeTo = styled.div`
  flex: 0 0 auto;
  padding: 0 0.25rem;
`
export const CheckLine = styled.button`
  pointer-events: ${p => (p.onClick ? 'auto' : 'none')};
  border: none;
  margin: 0;
  padding: 0.25rem;
  background-color: transparent;
  display: inline-flex;
  justify-content: flex-start;
  text-align: left;
`

export const CheckLabel = styled.div`
  display: inline-block;
  padding-left: 0.25rem;
`

export const CheckArea = styled.div`
  display: inline-block;
`
export const CheckContainer = styled.div`
  padding: 0;
  margin-right: 0.5rem;

  ${LabelContainer},
  [class^='icon-'],
  [class*=' icon-'],
  .with-title, .value {
    color: ${p =>
      p.highlight === 'announce'
        ? ANNOUNCE
        : p.highlight === 'alert'
        ? ALERT
        : '#000'};
  }
`

export const InlineInput = styled.div`
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  color: ${p =>
    p.highlight === 'announce'
      ? ANNOUNCE
      : p.highlight === 'alert'
      ? ALERT
      : '#000'};
  input {
    padding: 0 0.25rem;
    margin: 0;
    width: ${p => (p.short ? '4rem' : '100%')};
    border: none;
    border-radius: 3px;
    color: '#000';
  }
  min-width: 3em;
  border-bottom: 1px dotted
    ${p =>
      p.highlight === 'announce'
        ? ANNOUNCE
        : p.highlight === 'alert'
        ? ALERT
        : '#444'};
`
export const CharacterSelectionContainer = styled.div`
  padding: 0;
  display: inline-block;
  margin-right: 0.5rem;
  border: 1px solid #999;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: #fff;
`

export const SelectableCharacter = styled.button`
  padding: 0 0.2rem;
  line-height: 1.1;
  border: 1px solid
    ${p =>
      p.selected ? '#aa0000' : p.defaultSelected ? '#888' : 'transparent'};
  border-radius: 50%;
  background-color: ${p =>
    p.selected ? '#aa0000' : p.defaultSelected ? '#545450' : 'transparent'};

  font-size: 1em;

  color: ${p => (p.selected ? 'white' : p.defaultSelected ? 'white' : '#000')};
  &.disabled {
    color: #000;
  }

  &:hover,
  &:focus {
    background-color: ${p =>
      p.disabled ? 'transparent' : p.selected ? '#aa0000' : 'yellow'};
    color: ${p => (p.disabled ? '#000' : p.selected ? 'white' : '#000')};
  }
`
