// import packages
import React from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'

// import apis
import { apiRelinquish } from 'api/user'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { emulatingUser, clearUserData } from 'redux/actions/memory'

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap'

// import components

// import styles
import logo from 'assets/images/bridgodex-horizontal.png'

// local constants

// --------------------

const AuthenticatedNav = props => {
  const { authAxios } = React.useContext(FetchContext)
  const reduxDispatch = useDispatch()
  const history = useHistory()
  const { isAuthenticated, authState, isAdmin, logout, setAuthState } =
    React.useContext(AuthContext)
  const admin = isAdmin()
  const emulating = authState.emulating
  const [showProfileDropdown, setShowProfileDropdown] = React.useState(false)

  const goToPasswordReset = () => history.push('/reset-my-password')
  const goToProfile = () => history.push('/profile')
  const goToAdminCards = () => history.push('/admin-cards')
  const goToAdminUsers = () => history.push('/admin-users')
  const goHome = () => history.push('/')

  const relinquish = () => {
    apiRelinquish(authAxios, {
      successMsg: 'Relinquished User',
      success: setAuthState,
      successDispatch: [reduxDispatch, emulatingUser],
    })
  }

  const logoutUser = () => {
    reduxDispatch(clearUserData())
    logout()
  }

  if (!isAuthenticated()) {
    return null
  }

  return (
    <Navbar
      bg='primary'
      fixed='top'
      variant='dark'
      expand='sm'
      className='shadow-sm no-print'
      onToggle={expanded => setShowProfileDropdown(expanded)}
    >
      <Container fluid='xl'>
        <Navbar.Brand>
          <RouterLink to='/'>
            <img src={logo} height='36' alt='Bridgodex' />
          </RouterLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='main-navbar-nav' />
        <Navbar.Collapse id='main-navbar-nav' className='justify-content-end'>
          <Nav className='h6'>
            <Dropdown
              as={Nav.Item}
              onClick={() => setShowProfileDropdown(t => !t)}
              show={showProfileDropdown}
            >
              <Dropdown.Toggle as={Nav.Link}>
                Hello, {authState.userInfo.first_name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={goHome}>Home</Dropdown.Item>
                <Dropdown.Item onClick={goToProfile}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={goToPasswordReset}>
                  Reset Password
                </Dropdown.Item>
                {admin && <Dropdown.Divider />}
                {admin && (
                  <Dropdown.Item onClick={goToAdminCards}>Cards</Dropdown.Item>
                )}
                {admin && (
                  <Dropdown.Item onClick={goToAdminUsers}>Users</Dropdown.Item>
                )}
                {emulating && (
                  <Dropdown.Item onClick={relinquish}>Relinquish</Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={logoutUser}>Log out</Dropdown.Item>{' '}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

AuthenticatedNav.propTypes = {}

export default AuthenticatedNav
