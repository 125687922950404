import PropTypes from 'prop-types'

import CircularIcon from './CircularIcon'
import Icon from './Icon'
import IconButton from './IconButton'
import WarningIcon from './WarningIcon'

const Club = () => <Icon small blackSuit icon='club' />
const Diamond = ({ hollow }) =>
  hollow ? (
    <Icon small icon='diamondHollow' />
  ) : (
    <Icon small redSuit icon='diamond' />
  )
const Heart = ({ hollow }) =>
  hollow ? (
    <Icon small icon='heartHollow' />
  ) : (
    <Icon small redSuit icon='heart' />
  )
const Spade = () => <Icon small blackSuit icon='spade' />
const NoTrump = () => <Icon small icon='noTrump' />

Diamond.propTypes = {
  hollow: PropTypes.bool,
}

Heart.propTypes = {
  hollow: PropTypes.bool,
}

export {
  CircularIcon,
  Icon,
  IconButton,
  WarningIcon,
  Club,
  Diamond,
  Heart,
  Spade,
  NoTrump,
}
