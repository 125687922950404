// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text, LogoSpacer } from './styles/parts'

// local constants

// --------------------

const Overview = () => {
  return (
    <Section title='Overview' area='overview' dark>
      <Line>
        <Text>General Approach</Text>
        <Input field='overview#general_approach' />
        <LogoSpacer />
      </Line>

      <Line>
        <Text>Min Expected HCP when Balanced: </Text>
        <Text leftPad>Opening</Text>
        <Input field='overview#min_exp_hcp_bal_opening' center />
        <Text leftPad>Responding</Text>
        <Input field='overview#min_exp_hcp_bal_responding' center />
      </Line>

      <Line>
        <Text>Forcing Open</Text>
        <Check
          alert
          title={
            <span>
              1<Club />
            </span>
          }
          field='overview#forcing_1c'
        />
        <Check
          title={
            <span>
              2<Club />
            </span>
          }
          field='overview#forcing_2c'
        />
        <Text leftPad alert>
          Other
        </Text>
        <Input field='overview#forcing_other' />
        <Text leftPad>
          1<NoTrump /> Open:
        </Text>
        <Check title='Str' field='overview#1nt_open_strong' />
        <Check title='Wk' field='overview#1nt_open_weak' />
        <Check title='Variable' field='overview#1nt_open_variable' />
      </Line>

      <Line>
        <Text>Bids that may require preparation</Text>
        <Input field='overview#bids_that_may_require_prep' />
      </Line>

      <Line>
        <Input field='overview#more' />
      </Line>
    </Section>
  )
}

export default Overview
