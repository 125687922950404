// import packages
import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Redirect, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

// import apis

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'

// import hooks

// import utilities
import { publicFetch } from 'helpers/fetch'

// import common elements
import { Form, Button, Spinner, FloatingLabel } from 'react-bootstrap'
import { Focus } from 'components/elements/Layout'

// import components
import PublicNav from 'components/navs/PublicNav'

// import styles

// local constants
const FIELDS = [
  { name: 'email', type: 'email', label: 'Email' },
  { name: 'password', type: 'password', label: 'Password' },
]

const INITIAL_VALUES = {
  email: '',
  password: '',
}
// --------------------

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const Login = () => {
  const history = useHistory()

  const authContext = React.useContext(AuthContext)
  const [redirectOnLogin, setRedirectOnLogin] = React.useState(false)
  const [loginLoading, setLoginLoading] = React.useState(false)

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const switchToSignup = () => {
    history.replace('/signup')
  }

  const switchToForgotPassword = () => {
    history.replace('/forgot-password')
  }

  const links = [
    ['Forgot password?', switchToForgotPassword],
    ['No account? Sign up', switchToSignup],
  ]

  const submitCredentials = async credentials => {
    try {
      setLoginLoading(true)
      const { data } = await publicFetch.post(`login`, credentials)
      authContext.setAuthState(data)
      toast.success(data.message)
      setTimeout(() => {
        setRedirectOnLogin(true)
      }, 700)
    } catch (error) {
      setLoginLoading(false)
      const { data } = error.response
      toast.error(data.message)
    }
  }

  return (
    <>
      {redirectOnLogin && <Redirect to='/' />}
      <Focus icon='login' title='Login' links={links}>
        <PublicNav />
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={(
            values,
            {
              authError,
              setError,
              setErrors,
              setSubmitting,
              resetForm,
              setFieldError,
            },
          ) => submitCredentials(values)}
          validationSchema={LoginSchema}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const input = field => {
              return (
                <Form.Group
                  className='mt-4'
                  key={field.name}
                  controlId={field.name}
                >
                  <FloatingLabel controlId={field.name} label={field.label}>
                    <Form.Control
                      name={field.name}
                      type={field.type}
                      placeholder=' '
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      isInvalid={touched[field.name] && !!errors[field.name]}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[field.name]}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              )
            }

            return (
              <Form noValidate onSubmit={handleSubmit}>
                {FIELDS.map(input)}
                <div className='d-grid my-3 '>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={loginLoading}
                  >
                    {loginLoading && <Spinner />}
                    Log in
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Focus>
    </>
  )
}

export default Login
