// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'
// import hooks

// import utilities

// import common elements

// import components

// import styles
import { CharacterSelectionContainer, SelectableCharacter } from './styles'

// local constants

// --------------------

const CharacterSelect = ({ field, sectionKey, getValue, canEdit }) => {
  const { showOnlyActiveFields } = useCardState()
  const { updateSelectedCharacter } = useCardDispatch()
  const fieldKey = `${sectionKey}#${field.field}`

  const characters = field.field.split('_').pop().split('')
  const selected = getValue(fieldKey)

  const dflt = field.default

  const select = i => {
    if (canEdit) {
      updateSelectedCharacter(
        fieldKey,
        characters[i - 1] === dflt ? null : i === selected ? null : i,
      )
    }
  }

  if (showOnlyActiveFields && typeof selected === 'undefined') {
    return null
  }

  return (
    <CharacterSelectionContainer>
      {characters.map((c, i) => (
        <SelectableCharacter
          key={`char_${i}_${c}`}
          disabled={!canEdit}
          selected={selected === i + 1 && c !== dflt}
          isDefault={c === dflt}
          defaultSelected={dflt === c && (selected === i || !selected)}
          onClick={() => select(i + 1)}
        >
          {c}
        </SelectableCharacter>
      ))}
    </CharacterSelectionContainer>
  )
}

CharacterSelect.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default CharacterSelect
