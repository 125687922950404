import React from 'react'
import { AuthContext } from 'contexts/AuthContext'

import About from 'components/pages/About'
import AdminUsers from 'components/pages/AdminUsers'
import AdminCards from 'components/pages/AdminCards'
import Card from 'components/pages/Card'
import PrintCard from 'components/pages/PrintCard'
import ForgotPassword from 'components/pages/ForgotPassword'
import ResetPassword from 'components/pages/ResetPassword'
import ResetMyPassword from 'components/pages/ResetMyPassword'
import Help from 'components/pages/Help'
import Home from 'components/pages/Home'
import Login from 'components/pages/Login'
import Profile from 'components/pages/Profile'
import Signup from 'components/pages/Signup'
import PrivacyPolicy from 'components/pages/PrivacyPolicy'
import Terms from 'components/pages/Terms'

const NavContext = React.createContext()
const { Provider } = NavContext

const navigation = {
  '/terms-and-conditions': {
    component: <Terms />,
    label: 'Terms of Use',
    show: 'public',
  },
  '/privacy': {
    component: <PrivacyPolicy />,
    label: 'Privacy Policy',
    show: 'public',
  },
  '/login': {
    component: <Login />,
    label: 'Log in',
    show: 'public',
  },
  '/signup': {
    component: <Signup />,
    label: 'Sign up',
    show: 'public',
  },
  '/forgot-password': {
    component: <ForgotPassword />,
    label: 'Forgot Password?',
    show: 'public',
  },
  '/reset-password/:token': {
    component: <ResetPassword />,
    label: 'Reset Password?',
    show: 'public',
  },
  '/reset-my-password': {
    component: <ResetMyPassword />,
    label: 'Reset Password',
    show: 'authenticated',
  },
  '/home': {
    component: <Home />,
    label: 'Home',
    icon: 'home',
    show: 'public',
  },
  '/about': {
    component: <About />,
    label: 'About',
    icon: 'info, about',
    show: 'public',
  },
  '/help': {
    component: <Help />,
    label: 'Help',
    icon: 'help',
    show: 'authenticated',
  },
  '/edit-card/:creator_id/:id/': {
    component: <Card edit />,
    label: 'Edit Card',
    show: 'authenticated',
  },
  '/card/:creator_id/:id': {
    component: <Card />,
    label: 'View Card',
    show: 'public',
  },
  '/convention-card/:creator_id/:id': {
    component: <PrintCard />,
    label: 'Print Card',
    show: 'public',
  },
  '/profile': {
    component: <Profile />,
    label: 'Profile',
    icon: 'user',
    show: 'authenticated',
  },
  '/admin-users': {
    component: <AdminUsers />,
    label: 'Users',
    icon: 'admin',
    show: 'admin',
  },
  '/admin-cards': {
    component: <AdminCards />,
    label: 'Cards',
    icon: 'admin',
    show: 'admin',
  },
}

const NavProvider = ({ children }) => {
  const auth = React.useContext(AuthContext)
  const isAuthenticated = auth.isAuthenticated()
  const isAdmin = isAuthenticated
    ? auth.authState.userInfo.role === 'admin'
    : false
  const [historyPoint, setHistoryPoint] = React.useState(null)

  const [pdfCardId, setPdfCardId] = React.useState(null)

  const retrieveHistoryPoint = () => {
    const loc = historyPoint || '/'
    setHistoryPoint(null)
    return loc
  }

  const canSee = path => {
    const level = navigation[path] && navigation[path].show
    switch (level) {
      case 'public':
        return true
      case 'authenticated':
        return isAuthenticated
      case 'admin':
        return isAdmin
      default:
        return false
    }
  }

  const rootComponent = auth.isAuthenticated() ? <Home /> : <About />

  return (
    <Provider
      value={{
        navigation,
        canSee,
        rootComponent,
        setHistoryPoint,
        retrieveHistoryPoint,
        pdfCardId,
        setPdfCardId,
      }}
    >
      {children}
    </Provider>
  )
}

export { NavContext, NavProvider }
