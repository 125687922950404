import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const PageContainer = styled.div``

export const PageContent = styled(Container)`
  padding: 1rem;
  padding-top: ${p => (p.print ? '1rem' : 'calc(62px + 1rem)')};
  min-height: calc(100vh - 50px);
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: 'title' 'content' 'footer';
`

export const FocusContainer = styled.div`
  padding: calc(62px + 1rem) 1rem 1rem 1rem;
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  & > div {
    flex: 0 0 auto;
  }
`

export const FocusedContent = styled.div`
  padding: 1rem;
  max-width: 400px;
  width: min(calc(100vw - 30px), 400px);
  margin: 1em auto 0;
  border-radius: 5px;
`

export const FocusIcon = styled.div`
  flex: 0 0 auto;
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-right: 1em;
`

export const FocusTitle = styled.h3`
  flex: 1 1 auto;
`

export const FocusLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 575) {
    flex-direction: column;
    text-align: center;
    align-items: stretch;
    & > div {
      line-height: 1.8;
    }
`
