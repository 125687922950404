// import packages
import React from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'

// import apis

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { switchCardFormat } from 'redux/actions/memory'

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { NavContext } from 'contexts/NavContext'

// import hooks

// import utilities

// import common elements
import { Navbar, Nav, Container, ButtonToolbar, Button } from 'react-bootstrap'

// import components
import { GetPdfButton } from 'components/elements/Pdf'

// import styles
import logo from 'assets/images/bridgodex-very-small.png'

// local constants

// --------------------

const CardNav = props => {
  const history = useHistory()
  const { retrieveHistoryPoint } = React.useContext(NavContext)
  const reduxDispatch = useDispatch()
  const { isAuthenticated } = React.useContext(AuthContext)

  const save = () => {
    props.saveCard()
  }

  const done = () => {
    history.push(retrieveHistoryPoint())
  }

  const login = () => {
    history.push('/login')
  }

  const switchToEdit = () => {
    history.push(`/edit-card/${props.card.creator_id}/${props.card.id}`)
  }

  const switchFormat = () => {
    window.scroll(0, 0)
    reduxDispatch(switchCardFormat())
  }
  const printCard = () =>
    history.push(`/convention-card/${props.card.creator_id}/${props.card.id}`)

  return (
    <div>
      <Navbar
        bg='primary'
        fixed='top'
        variant='dark'
        expand='sm'
        className='shadow-sm no-print'
      >
        <Container fluid='xl'>
          <Navbar.Brand>
            <RouterLink to='/'>
              <img src={logo} height='36' alt='Bridgodex' />
            </RouterLink>
          </Navbar.Brand>
          <ButtonToolbar style={{ flex: '1 1 auto' }}>
            {props.saveCard && (
              <Button
                className={props.somethingChanged ? 'glowing' : ''}
                size='sm'
                variant='light'
                disabled={!props.somethingChanged}
                onClick={save}
              >
                Save
              </Button>
            )}
            {isAuthenticated() ? (
              <Button size='sm' variant='light' onClick={done}>
                {props.somethingChanged ? 'Quit without saving' : 'Done'}
              </Button>
            ) : (
              <Button size='sm' variant='light' onClick={login}>
                Log in
              </Button>
            )}
          </ButtonToolbar>
          <Navbar.Toggle aria-controls='main-navbar-nav' />
          <Navbar.Collapse id='main-navbar-nav' className='justify-content-end'>
            <Nav className='h6'>
              <Nav.Link onClick={switchFormat}>
                Switch to {props.format === 'easy' ? 'Card' : 'Easy'} Format
              </Nav.Link>

              {props.canEdit && (
                <Nav.Link
                  size='sm'
                  variant='outline-info'
                  onClick={switchToEdit}
                >
                  Edit
                </Nav.Link>
              )}
              <Nav.Link
                size='sm'
                variant='outline-info'
                disabled={props.somethingChanged}
                onClick={printCard}
              >
                Printer-friendly
              </Nav.Link>
              <GetPdfButton
                cardId={props.card.id}
                disabled={props.somethingChanged}
                component={Nav.Link}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default CardNav
