// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'

// import hooks

// import utilities

// import common elements

// import components
import AuthenticatedNav from 'components/navs/AuthenticatedNav'
import PublicNav from 'components/navs/PublicNav'

// import styles

// local constants

// --------------------

const AppropriateNav = props => {
  const { isAuthenticated } = React.useContext(AuthContext)

  return isAuthenticated() ? <AuthenticatedNav /> : <PublicNav />
}

AppropriateNav.propTypes = {}

export default AppropriateNav
