// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiUpdateCard } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { updateCard } from 'redux/actions/cards'

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'
import { FetchContext } from 'contexts/FetchContext'

// import hooks
import { useCompare } from './useCompare'

// import utilities

// import common elements
import {
  FormControl,
  InputGroup,
  Collapse,
  ButtonToolbar,
  Button,
} from 'react-bootstrap'
import { Icon } from 'components/elements/Icons'

// import components
import CardNav from './CardNav'

// import styles
import { CardActionContainer } from './styles'

// local constants

// --------------------

const EditCardActions = props => {
  const { hasChanged, cardData } = useCardState()
  const { resetChanged } = useCardDispatch()
  const reduxDispatch = useDispatch()
  const { authAxios } = React.useContext(FetchContext)
  const [showEditTitle, setShowEditTitle] = React.useState(false)
  const [title, setTitle] = React.useState(props.card.title)

  const { compareButton, compareSelector } = useCompare(props.card.id)

  const titleChanged = title !== props.card.title
  const notesChanged = props.notes !== props.card.notes

  const saveCard = () => {
    apiUpdateCard(
      authAxios,
      props.card.id,
      { title, settings: cardData(), notes: props.notes },
      {
        successDispatch: [reduxDispatch, updateCard],
        successMsg: 'Card updated.',
        success: resetChanged,
      },
    )
  }

  const toggleEditTitle = () => setShowEditTitle(t => !t)

  const handleTitleChange = e => setTitle(e.target.value)

  const updateTitle = () => {
    props.updateTitle(title)
    setShowEditTitle(false)
  }

  const cancelEditTitle = () => {
    setShowEditTitle(false)
    setTitle(props.card.title)
  }

  const somethingChanged = hasChanged || titleChanged || notesChanged

  return (
    <div>
      <CardNav
        card={props.card}
        somethingChanged={somethingChanged}
        saveCard={saveCard}
        format={props.format}
      />

      <ButtonToolbar>
        <Button
          size='sm'
          className='btn-outline-white-bg'
          variant='outline-primary'
          onClick={toggleEditTitle}
        >
          Change title
        </Button>
        <Button
          size='sm'
          className='btn-outline-white-bg'
          variant='outline-primary'
          onClick={props.toggleShow}
        >
          Edit {props.show === 'card' ? 'Notes' : 'Card'}
        </Button>

        {props.format === 'easy' && compareButton}

        {somethingChanged && (
          <div
            className='btn btn-sm btn-danger'
            style={{ pointerEvents: 'none' }}
          >
            <span className='strong'>You have unsaved changes!</span>
          </div>
        )}
      </ButtonToolbar>

      <Collapse in={showEditTitle}>
        <div>
          <CardActionContainer>
            <InputGroup>
              <FormControl
                onChange={handleTitleChange}
                placeholder='Title...'
                autoFocus
                type='text'
                value={title}
              />
              <Button onClick={updateTitle} variant='outline-primary'>
                <Icon icon='checkmark' />
              </Button>
              <Button onClick={cancelEditTitle} variant='outline-secondary'>
                <Icon icon='remove' />
              </Button>
            </InputGroup>
          </CardActionContainer>
        </div>
      </Collapse>

      {props.format === 'easy' && compareSelector}

      <p>
        To create <strong>suit symbols</strong>, use !c, !d, !h, !s, which will
        automatically be converted when you print the card.
        <br />
        To force text in a field to be centered in the PDF versions of the card,
        prefix it with <strong>/</strong>. To force left alignment, prefix with{' '}
        <strong>\</strong>.
      </p>
    </div>
  )
}

EditCardActions.propTypes = {
  card: PropTypes.object.isRequired,
  notes: PropTypes.string.isRequired,
  show: PropTypes.string.isRequired,
  toggleShow: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
}

export default EditCardActions
