// import packages
import React from 'react'
// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Diamond, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'
import GridArea from './parts/GridArea'

// import styles
import { Line, Text } from './styles/parts'
import { MinorsGrid, RaisesGrid } from './styles/grids'
import { SectionDivider, SectionIcon } from './styles/sections'

// local constants

// --------------------

const Minors = () => {
  return (
    <Section title='Minors' area='minors'>
      <MinorsGrid>
        <GridArea area='suits'>
          <SectionIcon>
            1<Club />
          </SectionIcon>
        </GridArea>
        <GridArea area='detail'>
          <Line>
            <Text>Min Length:</Text>
            <Check title='5' field='minors#1c_min_len_5' />
            <Check title='4' field='minors#1c_min_len_4' />
            <Check title='3' field='minors#1c_min_len_3' />
            <Check announce title='NF 2' field='minors#1c_min_len_nf_2' />
            <Check
              announce
              noPad
              title={<small>(4432 only</small>}
              after={<small>)</small>}
              field='minors#1c_min_len_nf_2_4432'
            />
            <Check announce title='NF 1' field='minors#1c_min_len_nf_1' />
            <Check announce title='NF 0' field='minors#1c_min_len_nf_0' />
            <Check alert title='Art F' field='minors#1c_min_len_art_f' />
          </Line>
        </GridArea>

        <GridArea area='resp'>
          <Line>
            <Input title='Resp' field='minors#1c_response' />
            <Check title='Transfer Resp' field='minors#1c_trans_resp' />
          </Line>
        </GridArea>

        <GridArea area='more'>
          <Line>
            <Input field='minors#1c_more' />
          </Line>
        </GridArea>

        <GridArea area='nt'>
          <Line flexStart>
            <Input
              title={
                <span>
                  1<Diamond hollow />
                </span>
              }
              field='minors#1c_1d'
            />
            <Check title='Bypass 5+' field='minors#1c_1d_bypass_5' />
          </Line>
          <Line flexStart>
            <Input
              center
              title={
                <span>
                  1<NoTrump />
                </span>
              }
              field='minors#1c_1nt_min'
            />
            <Input center title='to' field='minors#1c_1nt_max' />
          </Line>
          <Line flexStart>
            <Input
              center
              title={
                <span>
                  2<NoTrump />
                </span>
              }
              field='minors#1c_2nt_min'
            />
            <Input center title='to' field='minors#1c_2nt_max' />
          </Line>
        </GridArea>

        <GridArea area='raises'>
          <RaisesGrid>
            <Text style={{ justifySelf: 'start', marginLeft: 0 }} grow>
              Single Raise:
            </Text>
            <Check title='NF' field='minors#1c_single_raise_nf' />
            <Check title='Inv+' alert field='minors#1c_single_raise_inv' />
            <Check title='GF' alert field='minors#1c_single_raise_gf' />

            <Text style={{ justifySelf: 'start', marginLeft: 0 }} grow>
              Jump Raise:
            </Text>
            <Check title='Wk' alert field='minors#1c_jump_raise_weak' />
            <Check title='Mixed' alert field='minors#1c_jump_raise_mixed' />
            <Check title='Inv' field='minors#1c_jump_raise_inv' />

            <Text style={{ justifySelf: 'start' }} leftPad grow>
              after overcall:
            </Text>
            <Check title='Wk' field='minors#1c_jump_raise_overcall_weak' />
            <Check title='Mixed' field='minors#1c_jump_raise_overcall_mixed' />
            <Check title='Inv' field='minors#1c_jump_raise_overcall_inv' />
          </RaisesGrid>
        </GridArea>
      </MinorsGrid>

      <SectionDivider />

      <MinorsGrid>
        <GridArea area='suits'>
          <SectionIcon>
            1<Diamond />
          </SectionIcon>
        </GridArea>
        <GridArea area='detail'>
          <Line>
            <Text>Min Length:</Text>
            <Check title='5' field='minors#1d_min_len_5' />
            <Check title='4' field='minors#1d_min_len_4' />
            <Check title='3' field='minors#1d_min_len_3' />
            <Check title='Unbal' field='minors#1d_min_len_unbal' />
            <Check announce title='NF 2' field='minors#1d_min_len_nf_2' />
            <Check announce title='NF 1' field='minors#1d_min_len_nf_1' />
            <Check announce title='NF 0' field='minors#1d_min_len_nf_0' />
            <Check alert title='Art F' field='minors#1d_min_len_art_f' />
          </Line>
        </GridArea>

        <GridArea area='resp'>
          <Line>
            <Input title='Resp' field='minors#1d_response' />
            <Check
              title={
                <span>
                  Same as over 1<Club />
                </span>
              }
              field='minors#1d_same_as_1c'
            />
          </Line>
        </GridArea>

        <GridArea area='more'>
          <Line>
            <Input field='minors#1d_more' />
          </Line>
        </GridArea>

        <GridArea area='nt'>
          <Line flexStart>
            <Input
              center
              title={
                <span>
                  1<NoTrump />
                </span>
              }
              field='minors#1d_1nt_min'
            />
            <Input center title='to' field='minors#1d_1nt_max' />
          </Line>
          <Line flexStart>
            <Input
              center
              title={
                <span>
                  2<NoTrump />
                </span>
              }
              field='minors#1d_2nt_min'
            />
            <Input center title='to' field='minors#1d_2nt_max' />
          </Line>
        </GridArea>
        <GridArea area='raises'>
          <RaisesGrid>
            <Text style={{ justifySelf: 'start', marginLeft: 0 }} grow>
              Single Raise:
            </Text>
            <Check title='NF' field='minors#1d_single_raise_nf' />
            <Check title='Inv+' alert field='minors#1d_single_raise_inv' />
            <Check title='GF' alert field='minors#1d_single_raise_gf' />

            <Text style={{ justifySelf: 'start', marginLeft: 0 }} grow>
              Jump Raise:
            </Text>
            <Check title='Wk' alert field='minors#1d_jump_raise_weak' />
            <Check title='Mixed' alert field='minors#1d_jump_raise_mixed' />
            <Check title='Inv' field='minors#1d_jump_raise_inv' />

            <Text style={{ justifySelf: 'start' }} leftPad grow>
              after overcall:
            </Text>
            <Check title='Wk' field='minors#1d_jump_raise_overcall_weak' />
            <Check title='Mixed' field='minors#1d_jump_raise_overcall_mixed' />
            <Check title='Inv' field='minors#1d_jump_raise_overcall_inv' />
          </RaisesGrid>
        </GridArea>
      </MinorsGrid>
    </Section>
  )
}
export default Minors
