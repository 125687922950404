// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiAddCard } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { addCard } from 'redux/actions/cards'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { FormControl, InputGroup, Button, Alert } from 'react-bootstrap'
// import components

// import styles

// local constants

// --------------------

const CreateCard = ({ fromId, closeAdd, openCard }) => {
  const [title, setTitle] = React.useState('')
  const { authAxios } = React.useContext(FetchContext)
  const reduxDispatch = useDispatch()

  const handleChange = e => setTitle(e.target.value)

  const createdCard = response => {
    reduxDispatch(addCard(response))
    closeAdd()
    openCard(response.card.id)
  }

  const create = () => {
    apiAddCard(authAxios, title, fromId, {
      successMsg: 'Card created.',
      success: createdCard,
    })
  }

  return (
    <Alert variant='success'>
      <p className='mb-0'>
        <strong>Provide title for new card:</strong>
      </p>

      <InputGroup>
        <FormControl
          onChange={handleChange}
          placeholder='Title...'
          autoFocus
          type='text'
          value={title}
        />
        <Button
          onClick={create}
          disabled={title.length === 0}
          variant='primary'
        >
          {fromId ? 'Clone' : 'Create'}
        </Button>
        <Button onClick={closeAdd} variant='light'>
          Cancel
        </Button>
      </InputGroup>
    </Alert>
  )
}

CreateCard.propTypes = {
  fromId: PropTypes.number,
  closeAdd: PropTypes.func.isRequired,
  openCard: PropTypes.func.isRequired,
}

export default CreateCard
