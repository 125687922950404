import React from 'react'
import { toast } from 'react-toastify'
import { PageLayout } from 'components/elements/Layout'
import AppropriateNav from 'components/navs/AppropriateNav'
import { AuthContext } from 'contexts/AuthContext'
import { Redirect } from 'react-router-dom'

const FourOhFour = () => {
  const { isAuthenticated } = React.useContext(AuthContext)
  const authenticated = isAuthenticated()
  const [redirect, setRedirect] = React.useState(false)

  React.useEffect(() => {
    if (!authenticated) {
      toast.error('Sorry, we cannot find what you were looking for.')
      setRedirect(true)
    }
  }, [authenticated])

  return (
    <>
      {redirect && <Redirect to='/' />}
      <PageLayout title='Uh-oh'>
        <AppropriateNav />
        <p>Sorry, we cannot find what you were looking for.</p>
      </PageLayout>
    </>
  )
}

export default FourOhFour
