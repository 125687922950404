// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiImportCardFromConfig } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { addCard } from 'redux/actions/cards'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { FormControl, InputGroup, Button, Alert } from 'react-bootstrap'
// import components

// import styles

// local constants

// --------------------

const ImportFromConfig = ({ fromId, closeAdd, openCard }) => {
  const [card, setCard] = React.useState(null)
  let fileReader

  const [title, setTitle] = React.useState('')
  const { authAxios } = React.useContext(FetchContext)
  const reduxDispatch = useDispatch()

  const handleChange = e => setTitle(e.target.value)

  const createdCard = response => {
    reduxDispatch(addCard(response))
    closeAdd()
    openCard(response.card.id)
  }

  const importCard = () => {
    apiImportCardFromConfig(
      authAxios,
      title,
      card.settings,
      card.notes,
      fromId,
      {
        successMsg: 'Card created.',
        success: createdCard,
      },
    )
  }

  const handleFileChosen = e => {
    fileReader = new FileReader()
    fileReader.onloadend = fileUploadComplete
    fileReader.readAsText(e.target.files[0])
  }

  const fileUploadComplete = () => {
    const content = fileReader.result
    setCard(JSON.parse(content))
  }

  return (
    <Alert variant='success'>
      <p className='mb-0'>
        <strong>Provide a title and choose an import file for new card:</strong>
      </p>

      <InputGroup>
        <FormControl
          onChange={handleChange}
          placeholder='Title...'
          autoFocus
          type='text'
          value={title}
        />
      </InputGroup>
      <InputGroup>
        <FormControl type='file' onChange={handleFileChosen} accept='.json' />
        <Button
          onClick={importCard}
          disabled={title.length === 0}
          variant='primary'
        >
          Import
        </Button>
        <Button onClick={closeAdd} variant='light'>
          Cancel
        </Button>
      </InputGroup>
    </Alert>
  )
}

ImportFromConfig.propTypes = {
  fromId: PropTypes.number,
  closeAdd: PropTypes.func.isRequired,
  openCard: PropTypes.func.isRequired,
}

export default ImportFromConfig
