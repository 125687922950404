// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import {
  SectionContainer,
  SectionTitle,
  SectionContent,
} from '../styles/sections'

// import styles

// local constants

// --------------------

const Section = props => {
  return (
    <SectionContainer area={props.area}>
      <SectionTitle>{props.title}</SectionTitle>
      <SectionContent dark={props.dark}>{props.children}</SectionContent>
    </SectionContainer>
  )
}

Section.propTypes = {
  area: PropTypes.string.isRequired,
  title: PropTypes.string,
  dark: PropTypes.bool,
  children: PropTypes.node,
}

export default Section
