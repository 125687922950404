// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Overview from './Overview'
import Names from './Names'
import Minors from './Minors'
import Majors from './Majors'
import NoTrumps from './NoTrumps'
import TwoLevel from './TwoLevel'
import Other from './Other'
import Doubles from './Doubles'
import NtOvercalls from './NtOvercalls'
import Overcalls from './Overcalls'
import Vs1Nt from './Vs1Nt'
import DirectCuebids from './DirectCuebids'
import VsTakeoutDouble from './VsTakeoutDouble'
import Preempts from './Preempts'
import VsPreempts from './VsPreempts'
import Slams from './Slams'
import Carding from './Carding'
import Signals from './Signals'
import LeadsVsSuits from './LeadsVsSuits'
import LeadsVsNt from './LeadsVsNt'

// import styles
import { CardContainer, LeftColumn, RightColumn } from './styles/structure'
import logo from 'assets/images/bridgodex.png'

// local constants

// --------------------

const EditCard = () => {
  return (
    <CardContainer>
      <RightColumn>
        <div className='logo'>
          <img src={logo} alt='Bridgodex' />
        </div>
        <Names />
        <Overview />
        <Minors />
        <Majors />
        <NoTrumps />
        <TwoLevel />
        <Other />
      </RightColumn>

      <LeftColumn>
        <Doubles />
        <NtOvercalls />

        <Overcalls />
        <Vs1Nt />

        <DirectCuebids />
        <VsTakeoutDouble />

        <Preempts />
        <VsPreempts />

        <Slams />

        <Carding />
        <Signals />

        <LeadsVsSuits />
        <LeadsVsNt />
      </LeftColumn>
    </CardContainer>
  )
}

export default EditCard
