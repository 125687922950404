import React from 'react'
import { Suit } from 'components/elements/Misc'

export const withSuitSymbols = (text, hollow = false) =>
  typeof text == 'string'
    ? text
        .replace(/!c/gi, '♣')
        .replace(/!d/gi, hollow ? '♢' : '♦')
        .replace(/!h/gi, hollow ? '♡' : '♥')
        .replace(/!s/gi, '♠')
    : text

export const arrayChunk = (arr, size) => {
  // [1,2,3,4,5] => [ [1,2], [3,4], [5] ]
  return arr.reduce(
    (chunks, el, i) =>
      (i % size ? chunks[chunks.length - 1].push(el) : chunks.push([el])) &&
      chunks,
    [],
  )
}

export const toTitleCase = str => {
  return str
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1)
    })
    .replace(/[_-]/g, ' ')
}

export const toSnakeCase = str => {
  let array = str.split('')
  let converted = array.map((a, i) => {
    if (i === 0) {
      return a.toLowerCase()
    } else {
      if (a === a.toUpperCase()) {
        return '_' + a.toLowerCase()
      } else {
        return a
      }
    }
  })
  return converted.join('')
}

export const safelyGet = (o, p) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

export const parseSuits = text => {
  const pattern = /(![CcDdHhSsNn]+)/g
  const matches = text.match(pattern)
  const parts = text.split(pattern)
  if (!matches) {
    return text
  }

  return parts.map((part, index) => (
    <React.Fragment key={part + index}>
      {matches.includes(part) ? <Suit suit={part[1]} /> : part}
    </React.Fragment>
  ))
}

export const getDisplayValue = v => {
  if (v.length > 0) {
    if (v[0] === '\\' || v[0] === '/') {
      return v.substr(1)
    }
  }
  return v
}

export const getDisplayAlignment = v => {
  if (v.length > 0) {
    if (v[0] === '\\') {
      return 'left'
    } else if (v[0] === '/') {
      return 'center'
    }
  }
  return 'inherit'
}

export const cardSorter = (a, b) => {
  const aa = a.title.toLowerCase()
  const bb = b.title.toLowerCase()
  return aa < bb ? -1 : aa > bb ? 1 : 0
}
