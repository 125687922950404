// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'
// import hooks

// import utilities
import { parseSuits } from 'helpers'

// import common elements
import { Icon } from 'components/elements/Icons'

// import components
import Label from './Label'

// import styles
import {
  CheckContainer,
  CheckLine,
  CheckLabel,
  CheckArea,
  InlineInput,
} from './styles'

// local constants

// --------------------

const CheckWithInput = ({ field, sectionKey, getValue, canEdit }) => {
  const { showOnlyActiveFields } = useCardState()
  const { updateCheck, updateText } = useCardDispatch()
  const checkKey = `${sectionKey}#${field.field}`
  const inputKey = `${sectionKey}#${field.with}`

  const toggle = () => (canEdit ? updateCheck(checkKey) : null)

  const checkValue = getValue(checkKey)
  const inputValue = getValue(inputKey) || ''

  const icon = checkValue === 'on' ? 'check-on' : 'check-off'

  if (showOnlyActiveFields && checkValue !== 'on' && inputValue === '') {
    return null
  }
  return showOnlyActiveFields ? (
    <div>
      {checkValue === 'on' && <Label field={field} />}
      {checkValue === 'on' && inputValue !== '' && ', '}
      {field.with_title && `${field.with_title} `}
      {inputValue}
    </div>
  ) : (
    <CheckContainer highlight={field.highlight}>
      <CheckLine
        onClick={canEdit ? toggle : null}
        checked={checkValue === 'on'}
        highlight={field.highlight}
      >
        <CheckArea>
          <Icon icon={icon} />
        </CheckArea>
        <CheckLabel>
          <Label field={field} />
        </CheckLabel>
      </CheckLine>
      {field.with_title && (
        <span className='with-title'> -- {field.with_title} </span>
      )}
      <InlineInput highlight={field.highlight} readOnly={!canEdit}>
        {canEdit ? (
          <input
            className='text-input'
            type='text'
            name={inputKey}
            value={inputValue}
            onChange={updateText}
          />
        ) : (
          <span className='value'>{parseSuits(inputValue)}</span>
        )}
      </InlineInput>
    </CheckContainer>
  )
}

CheckWithInput.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default CheckWithInput
