// import packages
import React from 'react'
// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'
import CharacterSelect from './parts/CharacterSelect'

// import styles
import { Line, Text } from './styles/parts'

// local constants

// --------------------

const LeadsVsSuits = () => {
  return (
    <Section title='Leads vs Suits' area='leads-vs-suits' dark>
      <Line>
        <Text>CIRCLE CARD LED (if not bold)</Text>
      </Line>
      <Line>
        <Text>Length Leads:</Text>
        <Check
          noPad
          title={
            <span>
              4<sup>th</sup>
            </span>
          }
          field='leads_vs_suits#length_4th'
        />
        <Check
          noPad
          title={
            <span>
              3<sup>rd</sup>/5<sup>th</sup>
            </span>
          }
          field='leads_vs_suits#length_3rd_5th'
        />
        <Check
          noPad
          title={
            <span>
              3<sup>rd</sup>/Low
            </span>
          }
          field='leads_vs_suits#length_3rd_low'
        />
      </Line>

      <Line indent flexStart>
        <Check title='Attitude' field='leads_vs_suits#attitude' />
        <Check title='Small from xx' field='leads_vs_suits#small_from_xx' />
      </Line>

      <Line flexStart indent>
        <CharacterSelect field='leads_vs_suits#length_leads_xx' />
        <CharacterSelect field='leads_vs_suits#length_leads_xxx' />
        <CharacterSelect field='leads_vs_suits#length_leads_xxxx' />
        <CharacterSelect field='leads_vs_suits#length_leads_xxxxx' />
      </Line>

      <Line flexStart indent>
        <CharacterSelect field='leads_vs_suits#length_leads_Hxx' />
        <CharacterSelect field='leads_vs_suits#length_leads_Hxxx' />
        <CharacterSelect field='leads_vs_suits#length_leads_Hxxxx' />
      </Line>

      <Line>
        <Input
          title={
            <span>
              After 1<sup>st</sup> trick
            </span>
          }
          field='leads_vs_suits#after_1st_trick'
        />
      </Line>

      <Line flexStart>
        <Text>Honor Leads:</Text>
      </Line>

      <Line indent>
        <CharacterSelect after='(+)' field='leads_vs_suits#honor_leads_AKx' />
        <Check title='Varies' field='leads_vs_suits#honor_leads_akx_varies' />
        <Input field='leads_vs_suits#honor_leads_akx_varies_desc' />
      </Line>

      <Line flexStart indent>
        <CharacterSelect dflt='K' field='leads_vs_suits#honor_leads_KQx' />
        <CharacterSelect dflt='Q' field='leads_vs_suits#honor_leads_QJx' />
        <CharacterSelect dflt='J' field='leads_vs_suits#honor_leads_JTx' />
        <CharacterSelect dflt='T' field='leads_vs_suits#honor_leads_T9x' />
      </Line>

      <Line flexStart>
        <Text>Interior Seq:</Text>
      </Line>

      <Line flexStart indent>
        <CharacterSelect
          dflt='J'
          field='leads_vs_suits#honor_interior_seq_KJTx'
        />
        <CharacterSelect
          dflt='T'
          field='leads_vs_suits#honor_interior_seq_KT9x'
        />
        <CharacterSelect
          dflt='T'
          field='leads_vs_suits#honor_interior_seq_QT9x'
        />
      </Line>

      <Line>
        <Input title='Exceptions' field='leads_vs_suits#exceptions' />
      </Line>
    </Section>
  )
}

LeadsVsSuits.propTypes = {}

export default LeadsVsSuits
