import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
// import packages

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

import { Icon } from 'components/elements/Icons'
// import common elements

// import components

// import styles

// local constants

// --------------------

const Spinner = props => {
  if (!props.show) {
    return props.altIcon ? <Icon icon={props.altIcon} /> : null
  }
  return (
    <Spin>
      <Icon icon='spinner' />
    </Spin>
  )
}

Spinner.propTypes = {
  show: PropTypes.bool,
  altIcon: PropTypes.string,
}

Spinner.defaultProps = {
  show: true,
}

export default Spinner

const SpinningAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spin = styled.span`
  display: inline-block;
  line-height: 1;
  animation: ${SpinningAnimation} 2s infinite steps(12);
`
