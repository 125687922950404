import styled from 'styled-components'

export const SectionContainer = styled.div`
  grid-area: ${p => p.area};
  display: flex;
  break-inside: avoid;
  border-bottom: 2px solid #000;
  @media print {
    border-bottom: 1pt solid #000;
  }
`

export const SectionTitle = styled.div`
  overflow: hidden;
  flex: 0 0 2rem;
  line-height: 2rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: white;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(180deg);
  @media print {
    flex: 0 0 12pt;
    line-height: 12pt;
    padding: 2pt 0;
    font-size: 8pt;
    font-weight: normal;
    -webkit-print-color-adjust: exact;
  }
`

export const SectionContent = styled.div`
  padding: 6px;
  flex: 1 1 auto;
  background-color: #fff;
  border-left: 2rem solid ${p => (p.dark ? '#444' : '#888')};
  margin-left: -2rem;
  @media print {
    border-left: 12pt solid ${p => (p.dark ? '#444' : '#888')};
    margin-left: -12pt;
    padding: 2pt;
  }
`

export const SectionDivider = styled.div`
  margin: 5px -8px;
  border-bottom: 2px solid #888888;
  @media print {
    margin: 0 -2pt;
  }
`

export const SectionIcon = styled.div`
  font-size: 200%;
  padding-right: 3px;
  line-height: 1;
  color: ${p => (p.announce ? '#3081a0' : '#000000')};
  white-space: nowrap;
  @media print {
    padding-right: 2pt;
  }
`
