// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities
import { parseSuits, getDisplayValue } from 'helpers'

// import common elements

// import components
import Label from './Label'

// import styles
import { InputLine, InputLabel, ValueArea } from './styles'

// local constants

// --------------------

const Input = ({ field, sectionKey, isSection, getValue, canEdit }) => {
  const { showOnlyActiveFields } = useCardState()
  const { updateText } = useCardDispatch()

  const fieldKey = `${sectionKey}#${field.field}`

  const value = getValue(fieldKey) || ''

  const displayValue = getDisplayValue(value)

  if (showOnlyActiveFields && value === '') {
    return null
  }

  return (
    <InputLine short={field.short}>
      {!isSection ? (
        field.title ? (
          <InputLabel>
            <Label field={field} />:
          </InputLabel>
        ) : (
          <div />
        )
      ) : null}
      <ValueArea
        highlight={field.highlight}
        short={field.short}
        readOnly={!canEdit}
      >
        {canEdit ? (
          <input
            className='text-input'
            type='text'
            name={fieldKey}
            value={value}
            onChange={updateText}
          />
        ) : (
          <span className='value'>{parseSuits(displayValue)}&nbsp;</span>
        )}
      </ValueArea>
    </InputLine>
  )
}

Input.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default Input
