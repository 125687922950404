// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities

// import common elements
import { Icon } from 'components/elements/Icons'

// import components

// import styles
import { CheckButton } from '../styles/parts'

// local constants

// --------------------

const Check = ({
  field,
  title,
  alert,
  announce,
  after,
  noPad,
  leftPad,
  smaller,
}) => {
  const { card, canEdit } = useCardState()
  const { updateCheck } = useCardDispatch()

  const styles = { alert, announce, smaller }

  const toggle = () => (canEdit ? updateCheck(field) : null)

  const icon = card(field) === 'on' ? 'check-on' : 'check-off'

  return (
    <CheckButton
      onClick={canEdit ? toggle : null}
      leftPad={leftPad}
      noPad={noPad}
      {...styles}
    >
      <span>{title}</span>
      <Icon spaceBefore icon={icon} />
      {after}
    </CheckButton>
  )
}

Check.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  after: PropTypes.node,
  alert: PropTypes.bool,
  noPad: PropTypes.bool,
  leftPad: PropTypes.bool,
  announce: PropTypes.bool,
  smaller: PropTypes.bool,
}

export default Check
