// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useHistory } from 'react-router-dom'

// import apis
import { apiDeleteCard, apiLeaveCard } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { deleteCard } from 'redux/actions/cards'

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities
import { safelyGet, toSnakeCase } from 'helpers'

// import common elements
import { Button, ButtonToolbar, Accordion, Alert } from 'react-bootstrap'
import { GetPdfButton } from 'components/elements/Pdf'
import { Confirm } from 'components/elements/Misc'

// import components
import CreateCard from './CreateCard'
import ShareOptions from './ShareOptions'

// import styles
import { CardRole } from './styles'

// local constants
const cleanFileName = title =>
  toSnakeCase(title.replace(/[^a-zA-Z0-9_-]+/g, ''))

// --------------------

const CardDetail = props => {
  const { card } = props
  const reduxDispatch = useDispatch()
  const { authAxios } = React.useContext(FetchContext)
  const history = useHistory()

  const { getCurrentUserId, hasEditingRights } = React.useContext(AuthContext)

  const currentUserId = getCurrentUserId()

  const timestamp = new Date(card.updated_at)

  const [showDetail, setShowDetail] = React.useState(null)

  const canEditCard = hasEditingRights(card)

  const canCloneCard = true

  const canShareCard = !card.is_public && canEditCard

  const canLeaveCard = !card.is_public && card.creator_id !== currentUserId

  const canDeleteCard = card.creator_id === currentUserId

  let names = safelyGet(card, ['settings', 'names', 'names'])
  if (names && (names[0] === '\\' || names[0] === '/')) {
    names = names.substr(1)
  }
  const generalApproach = safelyGet(card, [
    'settings',
    'overview',
    'general_appoach',
  ])

  const toggleDetail = show => setShowDetail(d => (d === show ? null : show))

  const toggleShareOptions = () => toggleDetail('share')

  const toggleCloneOptions = () => toggleDetail('clone')

  const toggleDelete = () => toggleDetail('delete')

  const toggleLeave = () => toggleDetail('leave')

  const cardRole = card.is_public ? (
    <CardRole public>Public Card</CardRole>
  ) : card.creator_id === currentUserId ? (
    <CardRole creator>Creator</CardRole>
  ) : card.editors.includes(currentUserId) ? (
    <CardRole editor>Editor</CardRole>
  ) : (
    <CardRole>Read Only</CardRole>
  )

  const editCard = () => {
    window.scrollTo(0, 0)
    history.push(`/edit-card/${card.creator_id}/${card.id}`)
  }

  const viewCard = () => {
    window.scrollTo(0, 0)
    history.push(`/card/${card.creator_id}/${card.id}`)
  }

  const printCard = () => {
    history.push(`/convention-card/${props.card.creator_id}/${props.card.id}`)
  }

  const exportConfiguration = () => {
    const fileName = cleanFileName(card.title) + '_' + new Date().toISOString()
    const blob = new Blob(
      [JSON.stringify({ settings: card.settings, notes: card.notes })],
      { type: 'text/plain' },
    )
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    // link.download = `${filename}.json`
    link.download = `${fileName}.json`
    link.href = url
    link.click()
  }

  const reallyDelete = () => {
    apiDeleteCard(authAxios, card.id, {
      successDispatch: [reduxDispatch, deleteCard],
      successMsg: 'Card deleted',
    })
  }

  const reallyLeave = () => {
    apiLeaveCard(authAxios, card.id, {
      successDispatch: [reduxDispatch, deleteCard],
      successMsg: 'Card removed',
    })
  }

  const toggle = () => props.openCard(card.id)

  return (
    <Accordion.Item eventKey={card.id} className='mt-2'>
      <Accordion.Header onClick={toggle}>
        <div className='w-100 d-flex justify-content-between align-items-baseline'>
          <div>
            {card.title}
            {card.creator_id !== currentUserId && (
              <div className='text-muted small mt-1 force-base-font'>
                Created by {card.creator_name}
              </div>
            )}
          </div>
          <div className='pe-3'>{cardRole}</div>
        </div>
      </Accordion.Header>

      <Accordion.Body className='bg-light pt-0 px-0'>
        <div className='bg-white p-2 rounded-bottom-3 border border-success border-2'>
          <div>
            {names && <p>Names on card: {names}</p>}
            {generalApproach && <p>General Approach: {generalApproach}</p>}
            {card.latest_editor && (
              <p>
                <small>
                  Last edited by {card.latest_editor} on{' '}
                  {timestamp.toLocaleDateString()},{' '}
                  {timestamp.toLocaleTimeString()}.
                </small>
              </p>
            )}
          </div>

          <div className='d-sm-block d-md-flex justify-content-between'>
            <ButtonToolbar>
              <Button size='sm' variant='outline-primary' onClick={viewCard}>
                View
              </Button>
              {canEditCard && (
                <Button size='sm' variant='outline-primary' onClick={editCard}>
                  Edit
                </Button>
              )}
              <Button size='sm' variant='outline-info' onClick={printCard}>
                Printer-friendly
              </Button>

              <GetPdfButton cardId={card.id} />

              <Button
                size='sm'
                variant='outline-primary'
                onClick={exportConfiguration}
              >
                Export Config
              </Button>
            </ButtonToolbar>
            <ButtonToolbar className='mt-3 mt-md-0'>
              {canShareCard && (
                <Button
                  size='sm'
                  variant={
                    showDetail === 'share' ? 'success' : 'outline-success'
                  }
                  onClick={toggleShareOptions}
                >
                  {showDetail === 'share' ? 'Close Sharing' : 'Sharing ...'}
                </Button>
              )}
              {canCloneCard && (
                <Button
                  size='sm'
                  variant={
                    showDetail === 'clone' ? 'success' : 'outline-success'
                  }
                  onClick={toggleCloneOptions}
                >
                  {showDetail === 'clone' ? 'Close Duplicate' : 'Duplicate ...'}
                </Button>
              )}
              {canLeaveCard && (
                <Button
                  size='sm'
                  variant={showDetail === 'leave' ? 'danger' : 'outline-danger'}
                  onClick={toggleLeave}
                >
                  {showDetail === 'leave' ? 'Cancel Leave' : 'Leave ...'}
                </Button>
              )}
              {canDeleteCard && (
                <Button
                  size='sm'
                  variant={
                    showDetail === 'delete' ? 'danger' : 'outline-danger'
                  }
                  onClick={toggleDelete}
                >
                  {showDetail === 'delete' ? 'Cancel Delete' : 'Delete ...'}
                </Button>
              )}
            </ButtonToolbar>
          </div>

          <TransitionGroup>
            <CSSTransition
              key={showDetail || 'nothing'}
              classNames='fade-on-change'
              exit={false}
              timeout={{ enter: 500, exit: 0 }}
            >
              {showDetail === 'leave' ? (
                <Alert variant='danger'>
                  <Confirm
                    content='Are you sure you want to leave this card? '
                    open={showDetail === 'leave'}
                    confirmButton='Yes, I want to leave'
                    onConfirm={reallyLeave}
                    cancelButton='Nope, changed my mind'
                    onCancel={toggleLeave}
                  />
                </Alert>
              ) : showDetail === 'delete' ? (
                <Alert variant='danger'>
                  <Confirm
                    content='Are you sure you want to delete this card? It cannot be restored.'
                    open={showDetail === 'delete'}
                    confirmButton='Yes, please delete'
                    onConfirm={reallyDelete}
                    cancelButton='Nope, changed my mind'
                    onCancel={toggleDelete}
                  />
                </Alert>
              ) : showDetail === 'clone' ? (
                <CreateCard
                  fromId={card.id}
                  openCard={props.openCard}
                  closeAdd={() => setShowDetail(null)}
                />
              ) : showDetail === 'share' ? (
                <ShareOptions card={card} />
              ) : (
                <div></div>
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

CardDetail.propTypes = {
  card: PropTypes.object.isRequired,
  openCard: PropTypes.func.isRequired,
}

export default CardDetail
