import styled from 'styled-components'

export const EditNotesContainer = styled.div`
  border: 1px solid #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
  column-gap: 20px;
  height: calc(100vh - 200px);
`

export const Heading = styled.div`
  font-weight: 900;
  color: #000;
  margin-bottom: 20px;
  a {
    font-weight: normal;
    font-size: 75%;
    margin-left: 20px;
  }
`

export const Editor = styled.div`
  padding-left: 10px;
  overflow-y: scroll;
  textarea {
    height: 100%;
  }
`

export const Content = styled.div`
  overflow-y: scroll;

}
`
