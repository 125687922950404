// import packages
import React from 'react'
import Scrollspy from 'react-scrollspy'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { ButtonGroup, Button, Dropdown } from 'react-bootstrap'

// import components

// import styles
import { ButtonSpyContainer } from './styles'

// local constants

// --------------------

const SectionSpy = ({
  sectionList,
  setScrollToSection,
  currentScrollSection,
  setCurrentScrollSection,
}) => {
  const sectionIds = Object.keys(sectionList)

  const goToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleSelection = eventKey => {
    if (eventKey === 'top') {
      goToTop()
    } else {
      setScrollToSection(eventKey)
    }
  }

  const scrolledTo = el => {
    if (el) {
      setCurrentScrollSection(el.id)
    }
  }

  return (
    <div>
      <ButtonSpyContainer>
        <Dropdown onSelect={handleSelection} as={ButtonGroup}>
          <Dropdown.Toggle split variant='primary' size='sm'>
            {sectionList[currentScrollSection] || '...Loading'}
          </Dropdown.Toggle>
          <Dropdown.Menu
            align='end'
            style={{ maxHeight: 'calc(100vh - 170px)', overflowY: 'auto' }}
          >
            {sectionIds.map(sId => (
              <Dropdown.Item key={sId} eventKey={sId}>
                {sectionList[sId]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
          <Button size='sm' variant='success' onClick={goToTop}>
            Top
          </Button>
        </Dropdown>
      </ButtonSpyContainer>
      <Scrollspy offset={-60} items={sectionIds} onUpdate={scrolledTo} />
    </div>
  )
}

export default SectionSpy
