// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis
import { apiShareCard } from 'api/cards'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { updateCardAttributes } from 'redux/actions/cards'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { ButtonToolbar, Button } from 'react-bootstrap'

// import components

// import styles
import { NameSearchRow } from '../styles'

// local constants

// --------------------

const SearchResult = ({ result, cardId, updateShare }) => {
  const reduxDispatch = useDispatch()
  const { authAxios } = React.useContext(FetchContext)

  const fullName = `${result.first_name} ${result.last_name}`

  const share = role => {
    apiShareCard(
      authAxios,
      cardId,
      { with_id: result.id, role },
      {
        successDispatch: [reduxDispatch, updateCardAttributes],
        success: () => updateShare(result.id, fullName),
      },
    )
  }

  const asEditor = () => share('editor')
  const asViewer = () => share('viewer')

  return (
    <NameSearchRow>
      <div>{fullName}</div>
      <div>
        <ButtonToolbar>
          <Button size='sm' variant='outline-success' onClick={asEditor}>
            Add as Editor
          </Button>
          <Button size='sm' variant='outline-primary' onClick={asViewer}>
            Add as Viewer
          </Button>
        </ButtonToolbar>
      </div>
    </NameSearchRow>
  )
}

SearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  cardId: PropTypes.number.isRequired,
  updateShare: PropTypes.func.isRequired,
}

export default SearchResult
