// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import { useCardState } from 'external/convention-cards/contexts/card-context'
// import hooks

// import utilities

// import common elements

// import components
import Field from './Field'
import Label from './Label'

// import styles
import { GroupContainer, GroupLabel, GroupFields } from './styles'

// local constants
const fieldsInField = field =>
  field.fields
    ? field.fields.map(f => fieldsInField(f))
    : field.with
    ? [field.field, field.with]
    : [field.field]

// --------------------

const Group = ({ field, sectionKey, isSection, canEdit, getValue }) => {
  const { showOnlyActiveFields, card } = useCardState()

  if (showOnlyActiveFields) {
    const allFields = fieldsInField(field).flat()
    if (
      allFields.filter(f => typeof card(`${sectionKey}#${f}`) !== 'undefined')
        .length === 0
    ) {
      return null
    }
  }

  return (
    <GroupContainer>
      {!isSection ? (
        field.title && field.title.length > 0 ? (
          <GroupLabel>
            <Label field={field} />:
          </GroupLabel>
        ) : (
          <div />
        )
      ) : null}
      <GroupFields inline={field.inline}>
        {field.fields.map((f, i) => (
          <Field
            key={`group_field_${i}`}
            inline={field.inline}
            field={f}
            sectionKey={sectionKey}
            canEdit={canEdit}
            getValue={getValue}
          />
        ))}
      </GroupFields>
    </GroupContainer>
  )
}

Group.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default Group
