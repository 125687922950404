// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'

// local constants

// --------------------

const Slams = () => {
  return (
    <Section title='Slams' area='slams' dark>
      <Line>
        <Text>
          4<Club /> Gerber:
        </Text>
        <Check
          title={
            <span>
              Directly over <NoTrump />
            </span>
          }
          field='slams#gerber_directly_over_nt'
        />
        <Check
          title={
            <span>
              Over <NoTrump /> Seq
            </span>
          }
          field='slams#gerber_over_nt_seq'
        />
        <Check
          title={
            <span>
              Non-
              <NoTrump /> Seq
            </span>
          }
          field='slams#gerber_over_non_nt_seq'
        />
        <Input
          title={
            <span>
              Non-
              <NoTrump /> Seq
            </span>
          }
          field='slams#gerber_other'
        />
      </Line>
      <Line>
        <Text>
          4<NoTrump />:
        </Text>
        <Check title='Blackwood' field='slams#4nt_blackwood' />
        <Check title='RKC 0314' field='slams#4nt_rkc_0314' />
        <Check title='RKC 1430' field='slams#4nt_rkc_1430' />
        <Input field='slams#4nt_more' />
      </Line>

      <Line>
        <Input title='Control Bids' field='slams#control_bids' />
      </Line>

      <Line>
        <Input title='Vs Interference' field='slams#vs_interference' />
      </Line>

      <Line>
        <Input title='Other' field='slams#other' />
      </Line>
    </Section>
  )
}

export default Slams
