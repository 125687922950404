import { combineReducers } from 'redux'
import cards from './cards'
import memory from './memory'

const rootReducer = combineReducers({
  cards,
  memory,
})

export default rootReducer
