// import packages
import React from 'react'
import PropTypes from 'prop-types'
import raw from 'raw.macro'
import YAML from 'yaml'

// import apis

// import redux, selectors, actions
import { useSelector } from 'react-redux'

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'

// import utilities
import { parseSuits } from 'helpers'

// import common elements

// import components
import Section from './Section'
import SectionSpy from './SectionSpy'
import SpySidebar from './SpySidebar'

// import styles
import { CardContainer, ScrollSpyContainer, HighlightedText } from './styles'

// local constants
const text = raw('./easy_card.yaml')
const structure = YAML.parse(text)

const sectionList = {}
for (const msKey in structure) {
  for (const section of structure[msKey].sections) {
    let title = structure[msKey].title
    if (section.title && section.title.length > 0) {
      title = `${title} — ${section.title}`
    }
    sectionList[section.id] = parseSuits(title)
  }
}

// --------------------

const EasyCard = ({ activeOnly }) => {
  const [scrollToSection, setScrollToSection] = React.useState('')
  const [currentScrollSection, setCurrentScrollSection] = React.useState('')
  const { canEdit, cardData, showOnlyActiveFields } = useCardState()
  const { setActiveOnly } = useCardDispatch()
  const activeSections = Object.keys(cardData())

  const compareCard = useSelector(state => state.memory.compareCard)

  React.useEffect(() => {
    setActiveOnly(activeOnly)
  }, [setActiveOnly, activeOnly])

  let displayMajorSections = Object.keys(structure)
  if (showOnlyActiveFields) {
    displayMajorSections = Object.keys(structure).filter(ms => {
      for (const section of structure[ms].sections) {
        if (activeSections.includes(section.key)) {
          return true
        }
      }
      return false
    })
  }

  return (
    <CardContainer>
      <ScrollSpyContainer fixed={currentScrollSection !== 'names'}>
        <SectionSpy
          sectionList={sectionList}
          setScrollToSection={setScrollToSection}
          currentScrollSection={currentScrollSection}
          setCurrentScrollSection={setCurrentScrollSection}
        />
      </ScrollSpyContainer>
      <SpySidebar
        sectionList={sectionList}
        setScrollToSection={setScrollToSection}
        currentScrollSection={currentScrollSection}
      />
      <div style={{ gridArea: 'display' }}>
        {canEdit && (
          <p>
            Note: Some of the input fields in this display appear larger than
            what can print on a printed card. Check the print format from time
            to time to see how things are looking.
          </p>
        )}
        {compareCard && (
          <p>
            Differences in the compared card are{' '}
            <HighlightedText>highlighted</HighlightedText>.
          </p>
        )}
        {displayMajorSections.map(majorSection => {
          return structure[majorSection].sections.map((section, i) => (
            <Section
              key={`section_${i}`}
              title={sectionList[section.id]}
              section={section}
              scrollToSection={scrollToSection}
              activeOnly={activeOnly}
            />
          ))
        })}
      </div>
    </CardContainer>
  )
}

EasyCard.propTypes = {
  activeOnly: PropTypes.bool,
}

export default EasyCard
