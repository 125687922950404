export const ADD_CARD = 'ADD_CARD'
export const AUGMENT_CARDS = 'AUGMENT_CARDS'
export const DELETE_CARD = 'DELETE_CARD'
export const LOAD_CARDS = 'LOAD_CARDS'
export const UPDATE_CARD = 'UPDATE_CARD'
export const UPDATE_CARD_ATTRIBUTES = 'UPDATE_CARD_ATTRIBUTES'

export const UPDATE_MEMORY = 'UPDATE_MEMORY'

export const EMULATING_USER = 'EMULATING_USER'

export const SWITCH_CARD_FORMAT = 'SWITCH_CARD_FORMAT'

export const TOGGLE_ACTIVE_ONLY = 'TOGGLE_ACTIVE_ONLY'

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
