// import packages
import React from 'react'
import { toast } from 'react-toastify'
import { useParams, Redirect } from 'react-router-dom'

// import apis
import { apiLoadCards } from 'api/cards'

// import redux, selectors, actions
import { useSelector, useDispatch } from 'react-redux'
import { loadCards } from 'redux/actions/cards'

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities
import { publicFetch } from 'helpers/fetch'

// import common elements
import { PageLayout } from 'components/elements/Layout'

// import components
import CardDisplay from './CardDisplay'
import ConventionCardPrint from 'external/convention-cards/components/ConventionCardPrint'

// import styles

// local constants

// --------------------

const Card = ({ edit, print }) => {
  const { id, creator_id } = useParams()
  const reduxDispatch = useDispatch()
  const { getCurrentUserId } = React.useContext(AuthContext)
  const { authAxios } = React.useContext(FetchContext)
  const currentUserId = getCurrentUserId()

  const card = useSelector(state => state.cards[id])
  const creatorId = parseInt(creator_id)

  const cardFormat = useSelector(state => state.memory.cardFormat)

  const [error, setError] = React.useState(false)
  const [title, setTitle] = React.useState(card ? card.title : 'Loading')

  React.useEffect(() => {
    const abortController = new AbortController()
    if (currentUserId && !error) {
      if (!card) {
        apiLoadCards(authAxios, {
          signal: abortController.signal,
          successDispatch: [reduxDispatch, loadCards],
          error: response => setError(response.error),
        })
      } else {
        if (card.creator_id !== creatorId) {
          setError('Card not found.')
        } else if (edit) {
          if (
            card.creator_id !== currentUserId &&
            !card.editors.includes(currentUserId)
          ) {
            setError('Sorry, you do not have permission to edit that card.')
          } else if (
            card.creator_id !== currentUserId &&
            !card.editors.includes(currentUserId) &&
            !card.views.includes(currentUserId) &&
            !card.is_public
          ) {
            setError('Sorry, you do not have permission to view that card.')
          }
        }
      }
    } else if (error) {
      toast.error(error)
    } else if (!card) {
      publicFetch
        .get(`cards/${id}?creator_id=${creatorId}`)
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.error)
            setError(true)
          } else {
            reduxDispatch(loadCards({ cards: [response.data.card] }))
          }
        })
        .catch(err => {
          toast.error(`Error: ${err.message}`, {
            autoClose: 10000,
          })
        })
    }
    return () => {
      abortController.abort()
    }
  }, [
    edit,
    card,
    error,
    id,
    creatorId,
    currentUserId,
    authAxios,
    reduxDispatch,
  ])

  React.useEffect(() => {
    setTitle(card ? card.title : 'Loading')
  }, [card])

  if (error) {
    return <Redirect to='/' />
  }

  return (
    <PageLayout title={title}>
      {card &&
        (print ? (
          <ConventionCardPrint card={card} />
        ) : (
          <CardDisplay
            format={cardFormat}
            card={card}
            editing={edit}
            updateTitle={setTitle}
          />
        ))}
    </PageLayout>
  )
}

export default Card
