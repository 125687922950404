// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Diamond, Spade, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'
import { VsTOGrid } from './styles/grids'

// local constants

// --------------------

const VsTakeoutDouble = () => {
  return (
    <Section title='vs Takeout Double' area='vs-takeout-double' dark>
      <Line>
        <Text>New Suit F:</Text>
        <Check title='2 Lvl' field='vs_to_double#new_suit_forcing_2_lvl' />
        <Check
          announce
          title='Trf'
          field='vs_to_double#new_suit_forcing_transfer'
        />
        <Input field='vs_to_double#new_suit_forcing_transfer_desc' />
      </Line>

      <Line>
        <Text>Jump Shift:</Text>
        <Check title='Wk' field='vs_to_double#jump_shift_weak' />
        <Check title='Inv' field='vs_to_double#jump_shift_inv' />
        <Check title='F' field='vs_to_double#jump_shift_f' />
        <Check alert title='Fit' field='vs_to_double#jump_shift_fit' />
      </Line>

      <Line>
        <Text>Rdbl:</Text>
        <Check title='10+' field='vs_to_double#redouble_10_plus' />
        <Check alert title='Conv' field='vs_to_double#redouble_conv' />
        <Input alert field='vs_to_double#redouble_conv_desc' />
      </Line>

      <VsTOGrid>
        <Text noWrap noPad>
          2<NoTrump /> Over:
        </Text>
        <Text noPad>Nat</Text>
        <Text noPad>Raise</Text>
        <Text>Range</Text>

        <div>
          <Club />
          <Diamond hollow />
        </div>
        <div>
          <Check field='vs_to_double#2nt_over_minors_nat' />
        </div>
        <div>
          <Check alert field='vs_to_double#2nt_over_minors_raise' />
        </div>
        <Line>
          <Input noPad field='vs_to_double#2nt_over_minors_min' />
          <Input noPad title='to' field='vs_to_double#2nt_over_minors_max' />
        </Line>

        <div>
          <Heart hollow />
          <Spade />
        </div>
        <div>
          <Check field='vs_to_double#2nt_over_majors_nat' />
        </div>
        <div>
          <Check alert field='vs_to_double#2nt_over_majors_raise' />
        </div>
        <Line>
          <Input noPad field='vs_to_double#2nt_over_majors_min' />
          <Input noPad title='to' field='vs_to_double#2nt_over_majors_max' />
        </Line>
      </VsTOGrid>

      <Line>
        <Input alert title='Other' field='vs_to_double#other' />
      </Line>
    </Section>
  )
}
export default VsTakeoutDouble
