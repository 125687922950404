import styled from 'styled-components'

export const Button = styled.div`
  display: inline-block;
  cursor:  ${p => (p.disabled || p.static ? 'default' : 'pointer')};
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  font-weight: 900!important;
  color: ${p =>
    p.disabled
      ? 'rgba(0,0,0,0.3)'
      : p.toggle
      ? p.selected
        ? 'white'
        : '#222'
      : p.static
      ? '#222'
      : 'white'};
  background-color: ${p =>
    p.toggle
      ? p.selected
        ? '#008000'
        : '#999'
      : p.danger
      ? '#990000'
      : p.static
      ? '#999'
      : p.info
      ? '#03a7c1'
      : '#0a58ca'};
  transition: color: .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in=out;
  &:hover {
    color: ${p =>
      p.static ? '#222' : p.disabled ? 'rgba(0,0,0,0.3)' : 'white'};
    background-color: ${p =>
      p.toggle
        ? p.selected
          ? '#0b950b'
          : '#aaa'
        : p.danger
        ? '#aa0000'
        : p.static
        ? '#999'
        : p.info
        ? '#1cb6cf'
        : '#0d6efd'};
  }
`
export const ButtonGroup = styled.div`
  display: inline-flex;
  ${Button} {
    border-radius: 0;
    border-right: 2px solid rgba(0, 0, 0, 0.3);
    &:first-child {
      border-radius: 0.25rem 0 0 0.25rem;
    }
    &:last-child {
      border-radius: 0 0.25rem 0.25rem 0;
      border-right: none;
    }
  }
`
