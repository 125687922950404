// import packages
import React from 'react'

// import apis
import { apiGetUsers, apiEmulate } from 'api/user'

// import redux, selectors, actions
import { useDispatch } from 'react-redux'
import { emulatingUser } from 'redux/actions/memory'

// import contexts
import { AuthContext } from 'contexts/AuthContext'
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { Table, Button } from 'react-bootstrap'
import { PageLayout } from 'components/elements/Layout'
import { Icon } from 'components/elements/Icons'

// import components
import AuthenticatedNav from 'components/navs/AuthenticatedNav'

// import styles

// local constants

// --------------------

const AdminUsers = () => {
  const { authAxios } = React.useContext(FetchContext)
  const { setAuthState } = React.useContext(AuthContext)

  const reduxDispatch = useDispatch()

  const [sortBy, setSortBy] = React.useState('name')
  const [sortAsc, setSortAsc] = React.useState(true)

  const [users, setUsers] = React.useState([])
  const [loadedUsers, setLoadedUsers] = React.useState(false)

  React.useEffect(() => {
    const abortController = new AbortController()

    if (!loadedUsers) {
      apiGetUsers(authAxios, {
        signal: abortController.signal,
        success: response => {
          setUsers(response)
          setLoadedUsers(true)
        },
      })
    }
    return () => {
      abortController.abort()
    }
  }, [loadedUsers, authAxios])

  React.useEffect(() => {
    setSortAsc(sortBy === 'latest' ? false : true)
  }, [sortBy])

  const emulate = id => {
    apiEmulate(authAxios, id, {
      success: setAuthState,
      successDispatch: [reduxDispatch, emulatingUser],
      successMsg: 'Emulating',
    })
  }

  const [DOWN, UP] = sortAsc ? [-1, 1] : [1, -1]

  const sortName = () => {
    if (sortBy === 'name') {
      setSortAsc(t => !t)
    } else {
      setSortBy('name')
    }
  }
  const sortEmail = () => {
    if (sortBy === 'email') {
      setSortAsc(t => !t)
    } else {
      setSortBy('email')
    }
  }
  const sortCount = () => {
    if (sortBy === 'count') {
      setSortAsc(t => !t)
    } else {
      setSortBy('count')
    }
  }
  const sortLatest = () => {
    if (sortBy === 'latest') {
      setSortAsc(t => !t)
    } else {
      setSortBy('latest')
    }
  }

  const nameSort = (a, b) => {
    const f1 = a.first_name
    const f2 = b.first_name
    const l1 = a.last_name
    const l2 = b.last_name
    return l1 < l2 ? DOWN : l1 > l2 ? UP : f1 < f2 ? DOWN : f1 > f2 ? UP : 0
  }
  const emailSort = (a, b) => {
    const c1 = a.email
    const c2 = b.email
    return c1 < c2 ? DOWN : c1 > c2 ? UP : 0
  }

  const cardSort = (a, b) => {
    const c1 = a.cards_count
    const c2 = b.cards_count
    return c1 < c2 ? DOWN : c1 > c2 ? UP : 0
  }

  const latestSort = (a, b) => {
    const l1 = a.latest_login || ''
    const l2 = b.latest_login || ''
    return l1 < l2 ? DOWN : l1 > l2 ? UP : 0
  }

  const currentSorter = () => {
    if (sortBy === 'name') {
      return nameSort
    } else if (sortBy === 'email') {
      return emailSort
    } else if (sortBy === 'count') {
      return cardSort
    } else {
      return latestSort
    }
  }

  const sortedUsers = users.sort(currentSorter())

  const sortIcon = field => {
    return field === sortBy ? (
      sortAsc ? (
        <Icon spaceAfter icon='up' />
      ) : (
        <Icon spaceAfter icon='down' />
      )
    ) : null
  }

  return (
    <PageLayout title='Users'>
      <AuthenticatedNav />

      {loadedUsers && (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th onClick={sortName}>{sortIcon('name')}Name</th>
              <th onClick={sortEmail}>{sortIcon('email')}Email</th>
              <th onClick={sortCount} className='text-center'>
                {sortIcon('count')}Cards
              </th>
              <th onClick={sortLatest}>{sortIcon('latest')}Latest Login</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map(u => (
              <tr key={u.id}>
                <td>
                  {u.first_name} {u.last_name}
                </td>
                <td>{u.email}</td>
                <td className='text-center'>{u.cards_count}</td>
                <td>{u.latest_login}</td>
                <td>
                  <Button
                    size='sm'
                    variant='link'
                    onClick={() => emulate(u.id)}
                  >
                    Emulate
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </PageLayout>
  )
}

export default AdminUsers
