// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'

// local constants

// --------------------

const Other = () => {
  return (
    <Section title='Other' area='other'>
      <Line>
        <Input title='Jump Shift Resp' field='other#jump_shift_resp' />
      </Line>
      <Line>
        <Input alert title='vs (Very)Str Open' field='other#vs_very_strong' />
        <Check alert title='NMF' field='other#nmf' />
        <Check alert title='2Way NMF' field='other#2_way_nmf' />
        <Check alert title='XYZ' field='other#xyz' />
        <Text alert>4th SF:</Text>
        <Check alert title='1 Rnd' field='other#fsf_1rnd' />
        <Check alert title='GF' field='other#fsf_gf' />
      </Line>
      <Line>
        <Input alert field='other#more1' />
      </Line>
      <Line>
        <Input alert field='other#more2' />
      </Line>
    </Section>
  )
}
export default Other
