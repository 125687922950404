// import packages
import React from 'react'
// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line } from './styles/parts'

// local constants

// --------------------

const Doubles = () => {
  return (
    <Section title='Doubles' area='doubles' dark>
      <Line>
        <Check title='Negative' field='doubles#negative' />
        <Input title='Thru' field='doubles#negative_thru' />
        <Check alert title='Penalty' field='doubles#penalty' />
      </Line>

      <Line>
        <Check title='Responsive' field='doubles#responsive' />
        <Input title='Thru' field='doubles#responsive_thru' />
        <Check title='Maximal' field='doubles#maximal' />
      </Line>

      <Line>
        <Check title='Support' field='doubles#support' />
        <Input title='Thru' field='doubles#support_thru' />
        <Check title='Rdbl' field='doubles#support_rdbl' />
      </Line>

      <Line>
        <Input title='T/O Style' field='doubles#to_style' />
      </Line>

      <Line>
        <Input field='doubles#other' />
      </Line>
    </Section>
  )
}
export default Doubles
