// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components

// import styles

// local constants

// --------------------

const GridArea = props => {
  return (
    <div style={{ gridArea: props.area, ...props.styles }}>
      {props.children}
    </div>
  )
}

GridArea.propTypes = {
  children: PropTypes.node,
  area: PropTypes.string,
  styles: PropTypes.object,
}

export default GridArea
