// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'

// local constants

// --------------------

const Overcalls = () => {
  return (
    <Section title='Overcalls' area='overcalls'>
      <Line>
        <Input title='1-Lvl' field='overcalls#1_lvl_min' />
        <Input title='to' field='overcalls#1_lvl_max' />
        <Check title='Often 4 cards' field='overcalls#1_lvl_4cards' />
      </Line>

      <Line flexStart>
        <Input title='2-Lvl' field='overcalls#2_lvl_min' />
        <Input title='to' field='overcalls#2_lvl_max' />
      </Line>

      <Line>
        <Text>Jump Overcalls</Text>
        <Check title='Weak' field='overcalls#jump_weak' />
        <Check alert title='Int' field='overcalls#jump_int' />
        <Check alert title='Str' field='overcalls#jump_strong' />
      </Line>

      <Line>
        <Check alert title='Conv' field='overcalls#conv' />
        <Input alert field='overcalls#conv_desc' />
      </Line>

      <Line>
        <Text>Responses:</Text>
      </Line>

      <Line>
        <Text>New Suit:</Text>
        <Check noPad title='F' field='overcalls#new_suit_forcing' />
        <Check title='NFCnst' field='overcalls#new_suit_nf_const' />
        <Check title='NF' field='overcalls#new_suit_nf' />
        <Check annoucne title='Tfr' field='overcalls#new_suit_tfr' />
      </Line>

      <Line>
        <Text>Jump Raise:</Text>
        <Check title='Wk' field='overcalls#jump_raise_wk' />
        <Check title='Mixed' field='overcalls#jump_raise_mixed' />
        <Check title='Inv' field='overcalls#jump_raise_inv' />
      </Line>

      <Line>
        <Input title='Cuebids' field='overcalls#cuebids' />
        <Check title='Support' field='overcalls#cue_support' />
      </Line>

      <Line>
        <Input alert title='Other' field='overcalls#other' />
      </Line>
    </Section>
  )
}

export default Overcalls
