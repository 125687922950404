import React, { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

const AuthContext = createContext()
const { Provider } = AuthContext

const AuthProvider = ({ children }) => {
  const history = useHistory()

  const token = localStorage.getItem('token')
  const userInfo = localStorage.getItem('userInfo')
  const expiresAt = localStorage.getItem('expiresAt')

  const [authState, setAuthState] = useState({
    token,
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
    emulating: false,
  })

  const setAuthInfo = ({ token, userInfo, expiresAt, emulating }) => {
    localStorage.setItem('token', token)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    localStorage.setItem('expiresAt', expiresAt)

    setAuthState({
      token,
      userInfo,
      expiresAt,
      emulating: !!emulating,
    })
  }

  const updateUserInfo = response => {
    localStorage.setItem('userInfo', JSON.stringify(response.user))
    setAuthState(s => ({ ...s, userInfo: response.user }))
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('expiresAt')
    setAuthState({})
    history.push('/')
  }

  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresAt) {
      return false
    }
    return new Date().getTime() / 1000 < authState.expiresAt
  }

  const getCurrentUserId = () => {
    return authState.userInfo && authState.userInfo.id
  }

  const hasEditingRights = card => {
    const id = authState.userInfo && authState.userInfo.id
    return card && id && (card.creator_id === id || card.editors.includes(id))
  }

  const isAdmin = () => {
    return authState.userInfo && authState.userInfo.role === 'admin'
  }

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
        getCurrentUserId,
        hasEditingRights,
        updateUserInfo,
      }}
    >
      {children}
    </Provider>
  )
}

export { AuthContext, AuthProvider }
