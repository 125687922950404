// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Input from './Input'
import Check from './Check'
import CheckWithInput from './CheckWithInput'
import Range from './Range'
import Group from './Group'
import CharacterSelect from './CharacterSelect'

// import styles
import { Separator } from './styles'

// local constants

// --------------------

const Field = props => {
  if (props.field.fields) {
    return <Group {...props} />
  }

  switch (props.field.type) {
    case 'separator':
      return <Separator {...props} />

    case 'input':
      return <Input {...props} />

    case 'check':
    case 'single_check':
      return <Check {...props} />

    case 'check_with_input':
      return <CheckWithInput {...props} />

    case 'range':
    case 'single_range':
      return <Range {...props} />

    case 'character_select':
      return <CharacterSelect {...props} />

    default:
      console.log('in Field.jsx - unrecognized:', 'field', props.field)
      return 'unrecognized field!'
  }
}

Field.propTypes = {
  isSection: PropTypes.bool,
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
}

export default Field
