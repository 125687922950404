import styled from 'styled-components'

export const Line = styled.div`
  display: flex;
  margin-left: ${p => (p.indent ? '8px' : 0)};
  justify-content: ${p => (p.flexStart ? 'flex-start' : 'space-between')};
  align-items: baseline;
`
export const Text = styled.div`
  flex: ${p => (p.grow ? '1 1 auto' : p.tight ? '0 0 2em' : '0 0 auto')};
  color: ${p => (p.alert ? '#aa0000' : p.announce ? '#3081a0' : '#222')};
  font-size: ${p => (p.bigger ? '110%' : p.smaller ? '85%' : '100%')};
  margin-left: ${p => (p.noPad ? '0!important' : '0.5em')};
  &:first-child {
    margin-left: ${p => (p.leftPad ? '0.5em' : '0')};
  }
  font-weight: 300;
  pointer-events: ${p => (p.onClick ? 'auto' : 'none')};
  &:hover {
    background-color: ${p => (p.onClick ? 'yellow' : 'inherit')};
  }
  ${p => p.noWrap && 'white-space: nowrap;'}
  input {
    pointer-events: auto;
    width: calc(100% - 0.5em);
    font-weight: normal;
    padding: 0.2em 0.2em 0 0.2em;
    margin-left: ${p => (p.noPad ? '0' : '0.2em')};
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: ${p =>
      p.alert ? '#aa0000' : p.announce ? '#00b7ff' : '#000000'};
    color: #000000;
    text-align: ${p => (p.center ? 'center' : 'left')};
    &:focus {
      background-color: yellow;
    }
    @media print {
      padding: 0;
      font-size: 1em;
      border-width: 0 0 0.25pt 0;
    }
  }
  .value {
    min-height: 1em;
    font-weight: normal;
    margin-left: ${p => (p.noPad ? '0' : '0.2em')};
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: ${p =>
      p.alert ? '#aa0000' : p.announce ? '#00b7ff' : '#000000'};
    color: #000000;
    text-align: ${p => (p.center ? 'center' : 'left')};
    @media print {
      padding: 0;
      border-width: 0 0 0.25pt 0;
    }
  }
`

export const SelectableCharacters = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 3px;
  @media print {
    margin-right: 3pt;
  }
`

export const Character = styled.button`
  pointer-events: auto;
  padding: 0 1px;
  line-height: 1.1;
  font-weight: ${p => (p.isDefault ? '900' : 'normal')};
  border: 1px solid ${p => (p.selected ? '#aa0000' : 'transparent')};
  border-radius: 50%;
  background-color: transparent;

  font-size: 1em;

  color: #000;
  &.disabled {
    color: #000;
  }

  &:hover,
  &:focus {
    background-color: ${p =>
      p.disabled ? 'transparent' : p.selected ? '#aa0000' : 'yellow'};
    color: ${p => (p.disabled ? '#000' : p.selected ? 'white' : '#000')};
  }
  @media print {
    padding: 0.25pt;
  }
`

export const CheckButton = styled.button`
  pointer-events: ${p => (p.onClick ? 'auto' : 'none')};
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  flex: ${p => (p.grow ? '1 1 auto' : p.tight ? '0 0 2em' : '0 0 auto')};
  color: ${p => (p.alert ? '#aa0000' : p.announce ? '#3081a0' : '#222')};
  font-size: ${p => (p.bigger ? '110%' : p.smaller ? '85%' : '100%')};
  margin-left: ${p => (p.noPad ? '0!important' : '0.5em')};
  &:first-child {
    margin-left: ${p => (p.leftPad ? '0.5em' : '0')};
  }
  font-weight: 300;
  &:hover,
  &:focus {
    background-color: ${p => (p.onClick ? 'yellow' : 'inherit')};
  }
  ${p => p.noWrap && 'white-space: nowrap;'}
`

export const LogoSpacer = styled.div`
  flex: 0 0 80px;
  @media print {
    flex: 0 0 36pt;
  }
`
