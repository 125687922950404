// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions
import { useSelector } from 'react-redux'

// import contexts
import { useCardState } from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities
import { safelyGet } from 'helpers'

// import common elements

// import components
import Field from './Field'
import Label from './Label'

// import styles
import {
  MajorSectionTitle,
  SectionContainer,
  SectionContent,
  SectionLabel,
  Highlighted,
} from './styles'

// local constants
const fieldsInField = field =>
  field.fields
    ? field.fields.map(f => fieldsInField(f))
    : field.with
    ? [field.field, field.with]
    : [field.field]

// --------------------

const Section = ({ section, scrollToSection, title }) => {
  const { canEdit, showOnlyActiveFields, card } = useCardState()
  const sectionId = section.id
  const sectionRef = React.useRef(null)

  const compareCard = useSelector(state =>
    state.memory.compareCard ? state.cards[state.memory.compareCard] : null,
  )

  const compareWithField = sectionField => {
    const [section, field] = sectionField.split('#')
    return compareCard
      ? safelyGet(compareCard.settings, [section, field])
      : null
  }

  const hasDifferences = field => {
    const allFields = fieldsInField(field).flat()
    for (const field of allFields) {
      const key = `${section.key}#${field}`
      // Using != , not !== because we want undefined to equal null
      // eslint-disable-next-line eqeqeq
      if (card(key) != compareWithField(key)) {
        return true
      }
    }
    return false
  }

  React.useEffect(() => {
    if (scrollToSection === sectionId) {
      window.scrollTo({
        left: 0,
        top: sectionRef.current.offsetTop - 120,
        behavior: 'smooth',
      })
    }
  }, [scrollToSection, sectionRef, sectionId])

  return (
    <SectionContainer ref={sectionRef} id={sectionId}>
      <MajorSectionTitle>{title}</MajorSectionTitle>
      <SectionContent editing={canEdit}>
        {section.fields.map((field, i) => {
          const allFields = fieldsInField(field).flat()
          if (
            showOnlyActiveFields &&
            allFields.filter(
              f => typeof card(`${section.key}#${f}`) !== 'undefined',
            ).length === 0
          ) {
            return null
          }
          return (
            <React.Fragment key={`field_${i}`}>
              <SectionLabel separator={field.type === 'separator'}>
                <Label field={field} />
              </SectionLabel>
              <div>
                <Field
                  isSection
                  field={field}
                  sectionKey={section.key}
                  canEdit={!!canEdit}
                  getValue={card}
                />
                {compareCard && hasDifferences(field) && (
                  <Highlighted>
                    <Field
                      isSection
                      field={field}
                      sectionKey={section.key}
                      canEdit={false}
                      getValue={compareWithField}
                    />
                  </Highlighted>
                )}
              </div>
            </React.Fragment>
          )
        })}
      </SectionContent>
    </SectionContainer>
  )
}

Section.propTypes = {
  section: PropTypes.object.isRequired,
}

export default Section
