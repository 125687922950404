// import packages
import React from 'react'
import { toast } from 'react-toastify'
import { useParams, useHistory, Redirect } from 'react-router-dom'

// import apis
import { apiFetchCard } from 'api/cards'

// import redux, selectors, actions

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements

// import components
import ConventionCardPrint from 'external/convention-cards/components/ConventionCardPrint'

// import styles

// local constants

// --------------------

const PrintCard = ({ edit, print }) => {
  const { id, creator_id } = useParams()
  const history = useHistory()
  const { authAxios } = React.useContext(FetchContext)
  const [card, setCard] = React.useState()
  const [error, setError] = React.useState()
  const creatorId = parseInt(creator_id)

  React.useEffect(() => {
    const abortController = new AbortController()

    if (!error) {
      if (!card) {
        apiFetchCard(authAxios, id, creatorId, {
          signal: abortController.signal,
          error: response => setError(response.error),
          success: response => setCard(response.card),
        })
      } else {
        if (card.creator_id !== creatorId) {
          setError('Card not found.')
        }
      }
    } else {
      toast.error(error)
    }
    return () => {
      abortController.abort()
    }
  }, [card, error, id, creatorId, authAxios])

  if (error) {
    return <Redirect to='/' />
  }

  return card ? (
    <ConventionCardPrint card={card} done={history.goBack} />
  ) : (
    <div>Loading...</div>
  )
}

export default PrintCard
