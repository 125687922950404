// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'
// import hooks

// import utilities

// import common elements
import { Icon } from 'components/elements/Icons'

// import components
import Label from './Label'

// import styles
import { CheckContainer, CheckLine, CheckLabel, CheckArea } from './styles'

// local constants

// --------------------

const Check = ({ field, sectionKey, getValue, canEdit }) => {
  const { showOnlyActiveFields } = useCardState()
  const { updateCheck } = useCardDispatch()
  const fieldKey = `${sectionKey}#${field.field}`

  const toggle = () => (canEdit ? updateCheck(fieldKey) : null)

  const value = getValue(fieldKey)

  const icon = value === 'on' ? 'check-on' : 'check-off'

  if (showOnlyActiveFields && value !== 'on') {
    return null
  }

  return (
    <CheckContainer highlight={field.highlight}>
      <CheckLine checked={value === 'on'} onClick={canEdit ? toggle : null}>
        <CheckArea>
          <Icon icon={icon} />
        </CheckArea>
        {field.type !== 'single_check' && (
          <CheckLabel>
            <Label field={field} />
          </CheckLabel>
        )}
      </CheckLine>
    </CheckContainer>
  )
}

Check.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default Check
