// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

// import apis

// import redux, selectors, actions
import { useSelector, useDispatch } from 'react-redux'
import { toggleActiveOnly, updateMemory } from 'redux/actions/memory'

// import contexts
import { CardProvider } from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities

// import common elements
import { HtmlFromMarkdown } from 'components/elements/Misc'

// import components
import EasyCard from 'external/convention-cards/components/EasyCard'
import EditCard from 'external/convention-cards/components/EditCard'
import EditNotes from 'external/convention-cards/components/EditNotes'
import ViewCardActions from './ViewCardActions'
import EditCardActions from './EditCardActions'

// import styles

// local constants

// --------------------

const CardDisplay = props => {
  const reduxDispatch = useDispatch()
  const activeOnly = useSelector(state => state.memory.activeOnly)

  const [show, setShow] = React.useState('card')
  const [notes, setNotes] = React.useState(props.card.notes)

  const toggleShow = () => setShow(t => (t === 'card' ? 'notes' : 'card'))
  const toggleActive = () => reduxDispatch(toggleActiveOnly())

  React.useEffect(() => {
    reduxDispatch(updateMemory({ compareCard: null }))
  }, [reduxDispatch])

  return (
    <CardProvider
      settings={props.card.settings}
      canEdit={props.editing}
      activeOnly={activeOnly}
    >
      {props.editing ? (
        <EditCardActions
          card={props.card}
          notes={notes}
          show={show}
          format={props.format}
          toggleShow={toggleShow}
          updateTitle={props.updateTitle}
        />
      ) : (
        <ViewCardActions
          card={props.card}
          format={props.format}
          hasNotes={notes.length > 0}
          show={show}
          toggleShow={toggleShow}
          activeOnly={activeOnly}
          toggleActive={toggleActive}
        />
      )}
      <TransitionGroup>
        <CSSTransition
          key={show}
          classNames='fade-on-change'
          exit={false}
          timeout={{ enter: 500, exit: 0 }}
        >
          {show === 'card' ? (
            props.format === 'easy' ? (
              <EasyCard activeOnly={activeOnly} />
            ) : (
              <EditCard />
            )
          ) : props.editing ? (
            <EditNotes
              cardId={props.card.id}
              notes={notes}
              updateNotes={setNotes}
            />
          ) : (
            <HtmlFromMarkdown markdown={notes} />
          )}
        </CSSTransition>
      </TransitionGroup>
    </CardProvider>
  )
}
CardDisplay.propTypes = {
  format: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  updateTitle: PropTypes.func.isRequired,
}

export default CardDisplay
