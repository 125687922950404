// import packages
import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// import apis

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'

// import hooks

// import utilities
import { publicFetch } from 'helpers/fetch'

// import common elements
import { Form, Button, Spinner, FloatingLabel, Row, Col } from 'react-bootstrap'

// import components
import { Focus } from 'components/elements/Layout'
import PublicNav from 'components/navs/PublicNav'

// import styles

// local constants

const ResetPasswordScheme = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
  token: Yup.string(),
})

const ResetPassword = () => {
  const history = useHistory()
  const { token } = useParams()
  const authContext = React.useContext(AuthContext)

  const [loginLoading, setLoginLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const switchToSignup = () => {
    history.replace('/signup')
  }

  const switchToLogin = () => {
    history.replace('/login')
  }
  const links = [
    ['Remembered password?', switchToLogin],
    ['No account? Sign up', switchToSignup],
  ]

  const submitCredentials = async credentials => {
    try {
      setLoginLoading(true)
      const { data } = await publicFetch.post(`password/reset`, credentials)
      authContext.setAuthState(data)
      toast.success(data.message)
      setTimeout(() => {
        setRedirect(true)
      }, 700)
    } catch (error) {
      setLoginLoading(false)
      const { data } = error.response
      toast.error(data.message)
    }
  }

  return (
    <>
      {redirect && <Redirect to='/' />}
      <Focus icon='password' title='Reset Password' links={links}>
        <PublicNav />
        <Formik
          initialValues={{
            password: '',
            password_confirmation: '',
            token,
          }}
          onSubmit={values => submitCredentials(values)}
          validationSchema={ResetPasswordScheme}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const input = field => {
              return (
                <Form.Group
                  className='mt-4'
                  key={field.name}
                  controlId={field.name}
                >
                  <FloatingLabel controlId={field.name} label={field.label}>
                    <Form.Control
                      name={field.name}
                      type={field.type}
                      placeholder=' '
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      isInvalid={touched[field.name] && !!errors[field.name]}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[field.name]}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              )
            }

            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    {input({
                      name: 'password',
                      type: 'text',
                      label: 'Password',
                    })}
                  </Col>
                  <Col xs={12}>
                    {input({
                      name: 'password_confirmation',
                      type: 'text',
                      label: 'Password confirmation',
                    })}
                  </Col>
                </Row>

                <div className='d-grid my-3 '>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={loginLoading}
                  >
                    {loginLoading && <Spinner />}
                    Reset Password
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Focus>
    </>
  )
}

export default ResetPassword
