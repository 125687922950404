// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Footer from './Footer'
import PageTitle from './PageTitle'

// import styles
import { PageContent } from './styles'

// local constants

// --------------------

const PageLayout = ({ title, actions, children, skipFooter, isPrint }) => {
  return (
    <PageContent fluid='xl' className='bg-light shadow-sm' print={isPrint}>
      <PageTitle title={title} actions={actions} />
      <div style={{ gridArea: 'content' }}>{children}</div>
      {!skipFooter && <Footer />}
    </PageContent>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node,
  skipFooter: PropTypes.bool,
}

export default PageLayout
