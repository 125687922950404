// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import { PageLayout } from 'components/elements/Layout'
import AppropriateNav from 'components/navs/AppropriateNav'

// import styles

// local constants

// --------------------

const PrivacyPolicy = props => {
  return (
    <PageLayout title='Privacy Policy'>
      <AppropriateNav />
      <p class='small'>Effective date: March 27, 2022</p>
      <div style={{ maxWidth: 768 }}>
        <p>
          RSVP Bridge LLC ("us", "we", or "our") operates the
          https://www.bridgodex.com website and the Bridgodex mobile application
          (hereinafter referred to as the "Service").
        </p>
        <p>
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions.
        </p>
        <h2>Definitions</h2>
        <ul>
          <li>
            <p>
              <strong>Service</strong>
            </p>
            <p>
              Service means the https://www.bridgodex.com website and the
              Bridgodex mobile application operated by RSVP Bridge LLC
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong>
            </p>
            <p>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong>
            </p>
            <p>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Cookies are small files stored on your device (computer or mobile
              device).
            </p>
          </li>
        </ul>
        <h2>Information Collection and Use</h2>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p>
          We may use your Personal Data to contact you with information about
          the Bridgodex convention cards you have created or shared, newsletters
          and other information that may be of interest to you. You may opt out
          of receiving any, or all, of these communications from us by
          contacting us at support@rsvpbridge.com.
        </p>
        <h4>Usage Data</h4>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a
          mobile device ("Usage Data").
        </p>
        <p>
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          When you access the Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
        </p>
        <h4>Tracking Cookies Data</h4>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </p>
        <p>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyse our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li>
            <strong>Session Cookies.</strong>
            We use Session Cookies to operate our Service.
          </li>
          <li>
            <strong>Preference Cookies.</strong>
            We use Preference Cookies to remember your preferences and various
            settings.
          </li>
          <li>
            <strong>Security Cookies.</strong>
            We use Security Cookies for security purposes.
          </li>
        </ul>
        <h2>Use of Data</h2>
        <p>RSVP Bridge LLC uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To notify you about transactions you have made within RSVP Bridge,
            or changes to your privacy and/or email preference settings
          </li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <h2>Transfer of Data</h2>
        <p>
          Your information, including Personal Data, may be transferred to
          &#x2014; and maintained on &#x2014; computers located outside of your
          state, province, country or other governmental jurisdiction where the
          data protection laws may differ from those of your jurisdiction.
        </p>
        <p>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          RSVP Bridge LLC will take all the steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organisation or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h2>Disclosure of Data</h2>
        <h3>Business Transaction</h3>
        <p>
          If RSVP Bridge LLC is involved in a merger, acquisition or asset sale,
          your Personal Data may be transferred. We will provide notice before
          your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h3>Disclosure for Law Enforcement</h3>
        <p>
          Under certain circumstances, RSVP Bridge LLC may be required to
          disclose your Personal Data if required to do so by law or in response
          to valid requests by public authorities (e.g. a court or a government
          agency).
        </p>
        <h3>Legal Requirements</h3>
        <p>
          RSVP Bridge LLC may disclose your Personal Data in the good faith
          belief that such action is necessary to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of RSVP Bridge LLC
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <h2>Security of Data</h2>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h2>
          Our Policy on "Do Not Track" Signals under the California Online
          Protection Act (CalOPPA)
        </h2>
        <p>
          We do not support Do Not Track ("DNT"). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked.
        </p>
        <p>
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </p>
        <h2>Service Providers</h2>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), provide the Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </p>
        <h3>Payments</h3>
        <p>
          We may provide paid products and/or services within the Service. In
          that case, we use third-party services for payment processing (e.g.
          payment processors).
        </p>
        <p>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, MasterCard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
        <p>The payment processors we work with are:</p>
        <ul>
          <li>
            <p>
              <strong>Stripe</strong>
            </p>
            <p>
              Their Privacy Policy can be viewed at
              <a href='https://stripe.com/us/privacy'>
                https://stripe.com/us/privacy
              </a>
            </p>
          </li>
        </ul>
        <h2>Links to Other Sites</h2>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <ul>
          <li>By email: support@rsvpbridge.com</li>
        </ul>
      </div>
    </PageLayout>
  )
}

export default PrivacyPolicy
