export const ICOMOON_ICONS = {
  'check-on': 'icon-checkbox-checked2',
  'check-off': 'icon-checkbox-unchecked2',
  club: 'icon-clubs',
  diamondHollow: 'icon-diamond3',
  diamond: 'icon-diamond4',
  heart: 'icon-heart3',
  heartHollow: 'icon-heart4',
  spade: 'icon-spades',
  noTrump: 'icon-no-trump',
  print: 'icon-printer',
  help: 'icon-question6',
  register: 'icon-clipboard5',
  login: 'icon-keyhole',
  forgotPassword: 'icon-question6',
  user: 'icon-user6',
  home: 'icon-home',
  down: 'icon-arrow-down2',
  up: 'icon-arrow-up2',
  left: 'icon-arrow-left2',
  right: 'icon-arrow-right2',
  checkmark: 'icon-checkmark',
  remove: 'icon-cross',
  spinner: 'icon-spinner2',
}

export const getIconClassname = icon => {
  return ICOMOON_ICONS[icon] || 'icon-tongue'
}
