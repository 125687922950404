import {
  UPDATE_MEMORY,
  EMULATING_USER,
  SWITCH_CARD_FORMAT,
  TOGGLE_ACTIVE_ONLY,
  CLEAR_USER_DATA,
} from 'redux/action_types'

export function updateMemory(memory) {
  return {
    type: UPDATE_MEMORY,
    memory,
  }
}

export function emulatingUser() {
  return {
    type: EMULATING_USER,
  }
}

export function switchCardFormat() {
  return {
    type: SWITCH_CARD_FORMAT,
  }
}

export function toggleActiveOnly() {
  return {
    type: TOGGLE_ACTIVE_ONLY,
  }
}

export function clearUserData() {
  return {
    type: CLEAR_USER_DATA,
  }
}
