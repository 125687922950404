// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'
import { ThreeColGrid } from './styles/grids'

// local constants

// --------------------

const Carding = () => {
  return (
    <Section title='Carding' area='carding'>
      <ThreeColGrid>
        <Text noPad>Suits</Text>
        <Text>&nbsp;</Text>
        <Text noPad>
          <NoTrump />
        </Text>

        <Check noPad field='carding#suits_std_att' />
        <Text noWrap>Standard - Attitude</Text>
        <Check noPad field='carding#nt_std_att' />

        <Check noPad field='carding#suits_std_count' />
        <Text noWrap>Standard - Count</Text>
        <Check noPad field='carding#nt_std_count' />

        <Check noPad field='carding#suits_ud_att' />
        <Text noWrap>Upside Down - Attitude</Text>
        <Check noPad field='carding#nt_ud_att' />

        <Check noPad field='carding#suits_ud_count' />
        <Text noWrap>Upside Down - Count</Text>
        <Check noPad field='carding#nt_ud_count' />
      </ThreeColGrid>

      <Line>
        <Input title='Exceptions' field='carding#exeptions' />
      </Line>

      <Line flexStart>
        <Text>Other Carding:</Text>
      </Line>

      <Line>
        <Text>Smith Echo:</Text>
        <Check title='Suits' field='carding#smith_echo_suits' />
        <Check
          title={
            <span>
              <NoTrump />
            </span>
          }
          field='carding#smith_echo_nt'
        />
        <Check title='Reverse' field='carding#smith_echo_reverse' />
      </Line>

      <Line>
        <Input field='carding#other' />
      </Line>

      <Line>
        <Input title='Trump Signals' field='carding#trump_signals' />
      </Line>
    </Section>
  )
}

export default Carding
