// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import { CardProvider } from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities

// import common elements
import { Button } from '../../styles/ui'
import { HtmlFromMarkdown } from 'components/elements/Misc'

// import components
import EditCard from 'external/convention-cards/components/EditCard'

// import styles
import { NotesPage } from './styles'

// local constants

// --------------------

const ConventionCardPrint = props => {
  const { card } = props
  const timestamp = new Date(card.updated_at)

  return (
    <CardProvider settings={card.settings} canEdit={false}>
      <div className='no-print tools'>
        <Button size='xs' onClick={() => window.print()}>
          Print
        </Button>
        {props.done && (
          <Button size='xs' onClick={props.done}>
            Close
          </Button>
        )}
        <p>
          You can also print your card in PDF format ... find the PDF button for
          your card on your home page or at the top right when viewing the card.
        </p>
      </div>
      <div className='title'>
        Card Title: <strong>{card.title}</strong>,{' '}
        <small>
          as of {timestamp.toLocaleDateString()},{' '}
          {timestamp.toLocaleTimeString()}
        </small>
      </div>
      <EditCard forPrint card={card} />
      {card.notes.length > 0 && (
        <NotesPage className='markdown-content'>
          <HtmlFromMarkdown markdown={card.notes} />
        </NotesPage>
      )}
    </CardProvider>
  )
}

ConventionCardPrint.propTypes = {
  card: PropTypes.object.isRequired,
  done: PropTypes.func,
}

export default ConventionCardPrint
