// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Copyright } from 'components/elements/Misc'

// import components

// import styles
import logo from 'assets/images/bridgodex.png'

// local constants

// --------------------

const Footer = props => {
  return (
    <div
      className={`mt-3 mtx-auto text-center ${
        props.dark ? 'text-white-50' : 'text-secondary'
      }`}
      style={{ gridArea: 'footer' }}
    >
      <div>
        <img src={logo} height='75' alt='Bridgodex' />
      </div>
      <p>Convention&nbsp;Card Management for&nbsp;Bridge&nbsp;Players</p>
      <div className='my-3 small'>
        <a className='text-reset' href='/privacy'>
          Privacy Policy
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className='text-reset' href='/terms-and-conditions'>
          Terms and Conditions
        </a>
      </div>
      <Copyright dark={props.dark} />
    </div>
  )
}

Footer.propTypes = {
  dark: PropTypes.bool,
}

export default Footer
