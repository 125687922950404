import { get, post, patch, destroy } from 'api/bdex_api'

export const apiUpdateUser = (authAxios, id, user, controls) => {
  patch(authAxios, 'updating user profile', `users/${id}`, { user }, controls)
}

export const apiResetUserPassword = (authAxios, id, user, controls) => {
  patch(
    authAxios,
    'resetting user password',
    `users/${id}/reset_password`,
    { user },
    controls,
  )
}

export const apiGetUsers = (authAxios, controls) => {
  get(authAxios, 'getting users', 'users', controls)
}

export const apiFindSomeone = (authAxios, text, controls) => {
  get(authAxios, 'finding someone', `users/find/?text=${text}`, controls)
}

export const apiEmulate = (authAxios, become_id, controls) => {
  patch(authAxios, 'emulating user', 'emulate', { become_id }, controls)
}

export const apiRelinquish = (authAxios, controls) => {
  patch(authAxios, 'relinquishing user', 'relinquish', {}, controls)
}
