// import packages
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { ToastContainer } from 'react-toastify'
import { FocusRingScope } from 'react-focus-rings'

// import apis

// import redux, selectors, actions

// import contexts
import { AuthProvider, AuthContext } from 'contexts/AuthContext'
import { NavProvider, NavContext } from 'contexts/NavContext'
import { FetchProvider } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { ScrollToTop } from 'components/elements/Misc'
import { PdfModal } from 'components/elements/Pdf'

// import components
import FourOhFour from 'components/pages/FourOhFour'

// import styles

// local constants

// --------------------

const AppRoutes = () => {
  const { navigation, rootComponent, canSee, setHistoryPoint } =
    React.useContext(NavContext)
  let location = useLocation()
  const path = location.pathname
  const auth = React.useContext(AuthContext)
  const isAuthenticated = auth.isAuthenticated()

  const containerRef = React.useRef()

  React.useEffect(() => {
    if (
      [
        '/',
        '/admin-cards',
        '/terms-and-conditions',
        '/privacy',
        '/admin-users',
      ].includes(path)
    ) {
      setHistoryPoint(path)
    }
  }, [path, setHistoryPoint])

  const isPrint = path.includes('/convention-card/')

  return (
    <div className={isPrint ? 'bg-white' : 'bg-dark'} ref={containerRef}>
      <FocusRingScope containerRef={containerRef}>
        <ToastContainer
          theme='colored'
          icon={false}
          position='top-center'
          autoClose={1500}
          draggable
          closeOnClick
          pauseOnFocusLoss
          hideProgressBar
        />

        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames='fade-on-change'
            exit={false}
            timeout={{ enter: 500, exit: 0 }}
          >
            <Switch location={location}>
              {Object.keys(navigation).map(path => (
                <Route
                  key={path}
                  path={path}
                  render={() =>
                    canSee(path) ? (
                      navigation[path].component
                    ) : (
                      <Redirect to='/' />
                    )
                  }
                />
              ))}

              <Route exact path='/'>
                {rootComponent}
              </Route>

              <Route path='*'>
                <FourOhFour />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        {isAuthenticated && <PdfModal />}
      </FocusRingScope>
    </div>
  )
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <FetchProvider>
          <NavProvider>
            <AppRoutes />
          </NavProvider>
        </FetchProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
