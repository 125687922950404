// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'
// import hooks

// import utilities
import { parseSuits } from 'helpers'

// import common elements

// import components
import Label from './Label'

// import styles
import { RangeLine, RangeLabel, RangeTo, InlineInput } from './styles'

// local constants

// --------------------

const Range = ({ field, sectionKey, getValue, canEdit }) => {
  const { showOnlyActiveFields } = useCardState()
  const { updateText } = useCardDispatch()
  const fromKey = `${sectionKey}#${field.field}`
  const toKey = `${sectionKey}#${field.with}`

  const fromValue = getValue(fromKey) || ''
  const toValue = getValue(toKey) || ''

  if (showOnlyActiveFields && fromValue === '' && toValue === '') {
    return null
  }

  const connectorWord = field.with_title || 'to'

  return (
    <RangeLine>
      {field.type !== 'single_range' && (
        <RangeLabel>
          <Label field={field} />
        </RangeLabel>
      )}
      <InlineInput short highlight={field.highlight} readOnly={!canEdit}>
        {canEdit ? (
          <input
            className='text-input'
            type='text'
            name={fromKey}
            value={fromValue}
            onChange={updateText}
          />
        ) : (
          <span>{parseSuits(fromValue)}</span>
        )}
      </InlineInput>
      <RangeTo highlight={field.highlight}>{connectorWord}</RangeTo>
      <InlineInput short highlight={field.highlight} readOnly={!canEdit}>
        {canEdit ? (
          <input
            className='text-input'
            type='text'
            name={toKey}
            value={toValue}
            onChange={updateText}
          />
        ) : (
          <span>{parseSuits(toValue)}</span>
        )}
      </InlineInput>
    </RangeLine>
  )
}

Range.propTypes = {
  field: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
}

export default Range
