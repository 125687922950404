// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Diamond, Spade, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'

// import styles
import { Line } from './styles/parts'
import { Vs1NTGrid } from './styles/grids'

// local constants

// --------------------

const Vs1Nt = () => {
  return (
    <Section title='vs 1NT Opening' area='vs-1nt-opening'>
      <Vs1NTGrid>
        <Line>
          <Input title='Vs' field='vs_1nt_opening#vs_a' />
        </Line>
        <Line>
          <Input title='Dbl' field='vs_1nt_opening#vs_a_dbl' />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Club />
              </span>
            }
            field='vs_1nt_opening#vs_a_2c'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Diamond hollow />
              </span>
            }
            field='vs_1nt_opening#vs_a_2d'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Heart hollow />
              </span>
            }
            field='vs_1nt_opening#vs_a_2h'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Spade />
              </span>
            }
            field='vs_1nt_opening#vs_a_2s'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<NoTrump />
              </span>
            }
            field='vs_1nt_opening#vs_a_2nt'
          />
        </Line>

        <Line>
          <Input title='Vs' field='vs_1nt_opening#vs_b' />
        </Line>
        <Line>
          <Input title='Dbl' field='vs_1nt_opening#vs_b_dbl' />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Club />
              </span>
            }
            field='vs_1nt_opening#vs_b_2c'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Diamond hollow />
              </span>
            }
            field='vs_1nt_opening#vs_b_2d'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Heart hollow />
              </span>
            }
            field='vs_1nt_opening#vs_b_2h'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<Spade />
              </span>
            }
            field='vs_1nt_opening#vs_b_2s'
          />
        </Line>
        <Line>
          <Input
            title={
              <span>
                2<NoTrump />
              </span>
            }
            field='vs_1nt_opening#vs_b_2nt'
          />
        </Line>
      </Vs1NTGrid>
      <Line>
        <Input alert title='Other' field='vs_1nt_opening#other' />
      </Line>
    </Section>
  )
}
export default Vs1Nt
