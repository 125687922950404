// import packages
import React from 'react'

// import apis
import { apiLoadCards } from 'api/cards'

// import redux, selectors, actions
import { useSelector, useDispatch } from 'react-redux'
import { loadCards } from 'redux/actions/cards'
import { updateMemory } from 'redux/actions/memory'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities
import { cardSorter } from 'helpers'

// import common elements
import { ButtonToolbar, Button, Collapse, Accordion } from 'react-bootstrap'
import { PageLayout } from 'components/elements/Layout'

// import components
import AuthenticatedNav from 'components/navs/AuthenticatedNav'
import CreateCard from './CreateCard'
import ImportFromConfig from './ImportFromConfig'
import CardDetail from './CardDetail'

// import styles
import { HomeContainer } from './styles'

// local constants

// --------------------

const Home = () => {
  const { authAxios } = React.useContext(FetchContext)
  const reduxDispatch = useDispatch()

  const cards = useSelector(state => state.cards)
  const activeCard = useSelector(state => state.memory.activeCard)
  const [cardsLoaded, setCardsLoaded] = React.useState(false)
  const [reloading, setReloading] = React.useState(false)

  const [show, setShow] = React.useState(null)
  const toggleAdd = () => setShow(t => (t === 'add' ? null : 'add'))
  const toggleImport = () => setShow(t => (t === 'import' ? null : 'import'))

  const setActiveCard = c => reduxDispatch(updateMemory({ activeCard: c }))
  const toggleCard = cardId => setActiveCard(activeCard === cardId ? 0 : cardId)

  React.useEffect(() => {
    const abortController = new AbortController()
    if (!cardsLoaded) {
      apiLoadCards(authAxios, {
        successDispatch: [reduxDispatch, loadCards],
        signal: abortController.signal,
        loading: setReloading,
        success: () => {
          setCardsLoaded(true)
        },
      })
    }
    return () => {
      abortController.abort()
    }
  }, [cardsLoaded, reduxDispatch, authAxios])

  const reloadCards = () => setCardsLoaded(false)

  const actions = [
    <Button
      key='reload'
      variant='outline-secondary'
      size='sm'
      disabled={reloading}
      onClick={reloadCards}
    >
      {reloading ? 'Loading...' : 'Reload Cards'}
    </Button>,
  ]

  const cardRows = []
  const sortedCards = Object.values(cards).sort(cardSorter)
  for (const sortCard of sortedCards) {
    const card = cards[sortCard.id]
    cardRows.push(
      <CardDetail key={card.id} card={card} openCard={toggleCard} />,
    )
  }

  return (
    <PageLayout title='My Convention Cards' actions={actions}>
      <AuthenticatedNav />
      <HomeContainer>
        <p>
          You can edit cards that you have created, or that others have shared
          with you and given you editing rights. You can view and copy all other
          cards.
        </p>
        <div className='mb-3'>
          <ButtonToolbar>
            <Button
              size='sm'
              className='btn-outline-white-bg'
              variant={show === 'add' ? 'success' : 'outline-success'}
              onClick={toggleAdd}
            >
              {show === 'add' ? 'Cancel Add' : 'Add a new card ...'}
            </Button>
            <Button
              size='sm'
              className='btn-outline-white-bg'
              variant={show === 'import' ? 'success' : 'outline-primary'}
              onClick={toggleImport}
            >
              {show === 'import'
                ? 'Cancel Import'
                : 'Import from Config File ...'}
            </Button>
          </ButtonToolbar>
          <Collapse in={show === 'add'}>
            <div>
              <CreateCard
                openCard={setActiveCard}
                closeAdd={() => setShow(null)}
              />
            </div>
          </Collapse>
          <Collapse in={show === 'import'}>
            <div>
              <ImportFromConfig
                openCard={setActiveCard}
                closeAdd={() => setShow(null)}
              />
            </div>
          </Collapse>
        </div>
        {cardRows.length === 0 ? (
          <p>You do not have any cards!</p>
        ) : (
          <Accordion
            defaultActiveKey='0'
            activeKey={activeCard}
            flush
            className='border-bottom border-1 border-dark'
          >
            {cardRows}
          </Accordion>
        )}
        <div className='mt-3'>
          <hr />
          <div className='alert-success p-2 mb-1 border border-1 border-dark'>
            <h5>Updates!</h5>
            <div>
              <small>July 19, 2023:</small>
            </div>
            <p className='ms-2'>
              <strong>
                You can now center text in the text fields in the PDFs.
              </strong>{' '}
              When creating the PDF, you can choose the default alignment. To
              override the alignment in a particular field, prefix it with / for
              centering and \ for left alignment.
            </p>
            <div>
              <small>July 13, 2023:</small>
            </div>
            <p className='ms-2'>
              <strong>Large print cards!</strong> You now have an option to
              print your card in a larger format, with a larger font.
            </p>
          </div>
          <h5>Need more detail?</h5>
          <p className='ms-2'>
            Be sure to check out the Edit Notes button on your card. You can
            write extensive notes that will be printed on the back of your card.
          </p>
          <h5>Too much content for a field?</h5>
          <p className='ms-2'>
            Bridgodex will automatically reduce font size, print multiple lines
            where possible, or continue text into a footnote.
          </p>
          <h5>Want to compare cards?</h5>
          <p className='px-3'>
            When editing or viewing card, you have the option to Compare with
            another card. Just click the Compare button, and choose the card for
            comparison. The screen will then display the differences between the
            two cards.
          </p>
          <h5>Some notes about those PDFs...</h5>
          <p className='px-3'>
            <strong>
              You have a choice for the size of the generated PDFs.
            </strong>{' '}
            They can be either the exact card size (8"x8.5"), letter size (8.5"
            x 11"), or A4 size (21cm x 29.7cm). The letter size has a 0.5" top
            margin, 2" bottom margin, and 0.25" size margins. The A4 size is
            centered on the page.
          </p>
          <p className='px-3'>
            <strong>You can also choose a large print version,</strong> which
            prints the card on two pages in a larger font.
          </p>
          <p className='px-3'>
            Check your print margins. You may need to adjust your print margins
            to print accurately. Using Acrobat Reader, or Preview (on macOs) may
            give better results than printing from a browser preview.
          </p>
          <hr />
          <h5>What are config files?</h5>
          <p className='px-3'>
            The config files are a minimal JSON-formatted version of your card.
            You might want to store a config file before making a series of
            updates to your card (so it can be restored if you change your
            mind). You can also import/export config files between applications
            that use the same format. For example, if you play on
            RSVP&nbsp;Bridge, you can import a config file of a card created on
            Bridgodex, or you can import into Bridgodex a card created on
            RSVP&nbsp;Bridge.
          </p>
          <hr />
          <h5>Support</h5>
          <p className='px-3'>
            Bridgodex is provided as a free service to the bridge community by{' '}
            <a href='https://rsvpbridge.com'>RSVP Bridge</a>. Please contact{' '}
            <a href='mailto:bridgodex@rsvpbridge.com?subject=Bridgodex Support'>
              bridgodex@rsvpbridge.com
            </a>{' '}
            for bug reports or feature suggestions.
          </p>
        </div>
      </HomeContainer>
    </PageLayout>
  )
}

export default Home
