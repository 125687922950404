// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line, Text } from './styles/parts'

// local constants

// --------------------

const NtOvercalls = () => {
  return (
    <Section title='NT Overcalls' area='nt-overcalls' dark>
      <Line>
        <Text>
          Direct 1<NoTrump />:
        </Text>
        <Input center noPad field='nt_overcalls#direct_1nt_min' />
        <Input center noPad title='to' field='nt_overcalls#direct_1nt_max' />
        <Check
          noPad
          smaller
          title='Systems On'
          field='nt_overcalls#direct_systems_on'
        />
      </Line>

      <Line>
        <Text>
          Balance 1<NoTrump />:
        </Text>
        <Input noPad center field='nt_overcalls#balance_1nt_min' />
        <Input noPad center title='to' field='nt_overcalls#balance_1nt_max' />
        <Check
          noPad
          smaller
          title='Systems On'
          field='nt_overcalls#balance_systems_on'
        />
      </Line>

      <Line>
        <Check alert title='Conv' field='nt_overcalls#conv' />
        <Input alert field='nt_overcalls#conv_desc' />
      </Line>

      <Line flexStart>
        <Text>
          Jump to 2<NoTrump />
        </Text>
        <Check
          title='2 Lowest Unbid'
          field='nt_overcalls#jump_2nt_2_lowest_unbid'
        />
      </Line>

      <Line>
        <Input alert title='Other' field='nt_overcalls#other' />
      </Line>
    </Section>
  )
}
export default NtOvercalls
