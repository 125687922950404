// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities

// import common elements

// import components

// import styles
import { Text, SelectableCharacters, Character } from '../styles/parts'

// local constants

// --------------------

const CharacterSelect = ({ field, dflt, alert, announce, after }) => {
  const { card, canEdit } = useCardState()
  const { updateSelectedCharacter } = useCardDispatch()

  const styles = { alert, announce }

  const characters = field.split('_').pop().split('')
  const selected = card(field)

  const select = i => {
    if (canEdit) {
      updateSelectedCharacter(
        field,
        characters[i - 1] === dflt ? null : i === selected ? null : i,
      )
    }
  }

  return (
    <>
      <Text {...styles}>
        <SelectableCharacters>
          {characters.map((c, i) => (
            <Character
              key={i}
              disabled={!canEdit}
              selected={selected === i + 1 && c !== dflt}
              isDefault={c === dflt}
              onClick={() => select(i + 1)}
            >
              {c}
            </Character>
          ))}
        </SelectableCharacters>
      </Text>
      {after && (
        <Text noPad {...styles}>
          {after}
        </Text>
      )}
    </>
  )
}

CharacterSelect.propTypes = {
  field: PropTypes.string.isRequired,
  dflt: PropTypes.string,
  after: PropTypes.node,
  alert: PropTypes.bool,
  announce: PropTypes.bool,
}

export default CharacterSelect
