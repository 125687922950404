import React from 'react'

import { ButtonToolbar } from 'react-bootstrap'

export default function PageTitle({ title, actions }) {
  return (
    <div
      className='d-flex justify-content-between'
      style={{ gridArea: 'title' }}
    >
      <h3 className='page-title'>{title}</h3>
      {actions && actions.length > 0 && (
        <ButtonToolbar>{actions}</ButtonToolbar>
      )}
    </div>
  )
}
