// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities
import { parseSuits } from 'helpers'

// import common elements

// import components

// import styles
import { LabelContainer } from './styles'

// local constants

// --------------------

const Label = ({ field }) => {
  return (
    <LabelContainer highlight={field.highlight}>
      {field.title !== null ? parseSuits(`${field.title}`) : ''}
    </LabelContainer>
  )
}

Label.propTypes = {
  field: PropTypes.any,
}

export default Label
