// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { Club, Heart, Spade, Diamond, NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'
import GridArea from './parts/GridArea'

// import styles
import { Line, Text } from './styles/parts'
import { MajorsGrid, RaisesGrid } from './styles/grids'
import { SectionIcon } from './styles/sections'

// local constants

// --------------------

const Majors = () => {
  return (
    <Section title='Majors' area='majors' dark>
      <MajorsGrid>
        <GridArea area='suits'>
          <SectionIcon>
            1<Heart />/<Spade />
          </SectionIcon>
        </GridArea>

        <GridArea area='lengths'>
          <Line flexStart>
            <Text>1st/2nd: Length</Text>
            <Check title='4' field='majors#min_len_1st_2nd_4' />
            <Check title='5' field='majors#min_len_1st_2nd_5' />

            <Text>&nbsp;&nbsp;&nbsp;&nbsp;3rd/4th: Length</Text>
            <Check title='4' field='majors#min_len_3rd_4th_4' />
            <Check title='5' field='majors#min_len_3rd_4th_5' />
          </Line>
        </GridArea>
        <GridArea area='nt-raises'>
          <Line flexStart>
            <Text announce>
              1<NoTrump />:
            </Text>
            <Check announce title='F' field='majors#1nt_forcing' />
            <Check announce title='Semi-F' field='majors#1nt_semi_forcing' />
            <Check
              announce
              title={
                <span>
                  Bypass <Spade />
                </span>
              }
              field='majors#bypass_1_spade'
            />
          </Line>
        </GridArea>

        <GridArea area='more'>
          <Line>
            <Input field='majors#more' />
          </Line>
        </GridArea>

        <GridArea area='art-raises'>
          <div>
            <Line flexStart>
              <Text alert>Art Raises:</Text>
              <Check
                alert
                title={
                  <span>
                    2<NoTrump />
                  </span>
                }
                field='majors#art_raises_2nt'
              />
              <Check
                alert
                title={
                  <span>
                    3<NoTrump />
                  </span>
                }
                field='majors#art_raises_3nt'
              />
              <Check
                alert
                title='Splinter'
                field='majors#art_raises_splinter'
              />
            </Line>
            <Line indent>
              <Input alert title='Other' field='majors#art_raises_other' />
            </Line>
            <Line indent>
              <Text alert>Drury:</Text>
              <Check
                alert
                title={
                  <span>
                    2<Club />
                  </span>
                }
                field='majors#drury_2c'
              />
              <Check
                alert
                title={
                  <span>
                    2<Diamond hollow />
                  </span>
                }
                field='majors#drury_2d'
              />
              <Check alert title='In Comp' field='majors#drury_in_comp' />
              <Input alert field='majors#drury_other' />
            </Line>
          </div>
        </GridArea>
        <GridArea area='jump-raises'>
          <div>
            <RaisesGrid>
              <Text style={{ justifySelf: 'start', marginLeft: 0 }} grow>
                Jump Raise:
              </Text>
              <Check title='Wk' alert field='majors#jump_raise_weak' />
              <Check title='Mixed' alert field='majors#jump_raise_mixed' />
              <Check title='Inv' field='majors#jump_raise_inv' />

              <Text style={{ justifySelf: 'start' }} leftPad grow>
                after overcall:
              </Text>
              <Check title='Wk' field='majors#jump_raise_overcall_weak' />
              <Check title='Mixed' field='majors#jump_raise_overcall_mixed' />
              <Check title='Inv' field='majors#jump_raise_overcall_inv' />
            </RaisesGrid>
          </div>
          <Line>
            <Input alert title='Other' field='majors#other' />
          </Line>
        </GridArea>
      </MajorsGrid>
    </Section>
  )
}
export default Majors
