import {
  ADD_CARD,
  AUGMENT_CARDS,
  DELETE_CARD,
  LOAD_CARDS,
  UPDATE_CARD,
  UPDATE_CARD_ATTRIBUTES,
  EMULATING_USER,
  CLEAR_USER_DATA,
} from 'redux/action_types'

const cards = (state = {}, action) => {
  let newState
  switch (action.type) {
    case LOAD_CARDS:
      if (action.cards) {
        newState = action.cards.reduce((map, card) => {
          map[card.id] = card
          return map
        }, {})
        return newState
      } else {
        return state
      }

    case AUGMENT_CARDS:
      if (action.cards) {
        newState = action.cards.reduce((map, card) => {
          map[card.id] = card
          return map
        }, {})
        return { ...state, ...newState }
      } else {
        return state
      }

    case ADD_CARD:
    case UPDATE_CARD:
      return {
        ...state,
        [action.card.id]: action.card,
      }

    case UPDATE_CARD_ATTRIBUTES:
      return {
        ...state,
        [action.card.id]: { ...state[action.card.id], ...action.card },
      }
    case DELETE_CARD:
      newState = { ...state }
      delete newState[action.cardId]
      return newState

    case CLEAR_USER_DATA:
    case EMULATING_USER:
      return {}

    default:
      return state
  }
}

export default cards
