// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements
import { NoTrump } from 'components/elements/Icons'

// import components
import Section from './parts/Section'
import Input from './parts/Input'
import Check from './parts/Check'

// import styles
import { Line } from './styles/parts'

// local constants

// --------------------

const VsPreempts = () => {
  return (
    <Section title='Vs Preempts' area='vs-preempts'>
      <Line>
        <Input
          title={
            <span>
              2<NoTrump /> Overcall
            </span>
          }
          field='vs_preempts#2nt_overcall'
        />
      </Line>
      <Line>
        <Input title='T/O Dbl Thru' field='vs_preempts#to_dbl_thru' />
        <Check alert title='Penalty' field='vs_preempts#to_dbl_penalty' />
      </Line>
      <Line>
        <Check
          alert
          title={
            <span>
              2<NoTrump /> Lebensohl Resp
            </span>
          }
          field='vs_preempts#2nt_lebensohl_resp'
        />
        <Input alert field='vs_preempts#2nt_lebensohl_resp_desc' />
      </Line>
      <Line>
        <Input title='Cuebid' field='vs_preempts#cuebid' />
      </Line>
      <Line>
        <Input title='Jump Overcalls' field='vs_preempts#jump_overcalls' />
      </Line>
      <Line>
        <Input alert title='Other' field='vs_preempts#other' />
      </Line>
    </Section>
  )
}

export default VsPreempts
