import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from './Icon'

const CircularIcon = ({ bg, color, ...rest }) => {
  return (
    <Circular bg={bg} color={color}>
      <Icon htmlColor={color} {...rest} />
    </Circular>
  )
}

export default CircularIcon

CircularIcon.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
}

CircularIcon.defaultProps = {
  bg: '#ffffff',
  color: 'inherit',
}

const Circular = styled.div`
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: ${p => p.bg};
  color: ${p => p.color};
  font-size: 1.25rem;
  text-align: center;
  line-height: 2.5rem;
`
