// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import { AuthContext } from 'contexts/AuthContext'

// import hooks
import { useCompare } from './useCompare'

// import utilities

// import common elements
import { Button, ButtonToolbar } from 'react-bootstrap'

// import components
import CardNav from './CardNav'

// import styles

// local constants

// --------------------

const ViewCardActions = props => {
  const { isAuthenticated, hasEditingRights } = React.useContext(AuthContext)
  const { compareButton, compareSelector } = useCompare(props.card.id)
  const canCompare = isAuthenticated() && props.format === 'easy'

  return (
    <div>
      <CardNav
        card={props.card}
        format={props.format}
        canEdit={hasEditingRights(props.card)}
      />

      <ButtonToolbar>
        {props.hasNotes && (
          <Button
            size='sm'
            className='btn-outline-white-bg'
            variant='outline-primary'
            onClick={props.toggleShow}
          >
            Show {props.show === 'card' ? 'notes' : 'card'}
          </Button>
        )}
        {props.format === 'easy' && (
          <Button
            size='sm'
            className='btn-outline-white-bg'
            variant='outline-info'
            onClick={props.toggleActive}
          >
            Show {props.activeOnly ? 'all' : 'only filled-in'} fields
          </Button>
        )}
        {canCompare && compareButton}
      </ButtonToolbar>
      {canCompare && compareSelector}
    </div>
  )
}

ViewCardActions.propTypes = {
  format: PropTypes.string,
  hasNotes: PropTypes.bool,
  show: PropTypes.string.isRequired,
  toggleShow: PropTypes.func.isRequired,
  activeOnly: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired,
}

export default ViewCardActions
