// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components
import Section from './parts/Section'
import Input from './parts/Input'

// import styles
import { Line, LogoSpacer } from './styles/parts'

// local constants

// --------------------

const Names = () => {
  return (
    <Section title=' ' area='names'>
      <Line>
        <Input title='Names' bigger field='names#names' />
        <LogoSpacer />
      </Line>
    </Section>
  )
}
export default Names
