// import packages
import React from 'react'

// import apis

// import redux, selectors, actions

// import contexts

// import hooks

// import utilities

// import common elements

// import components

// import styles
import { SpySidebarContainer } from './styles'

// local constants

// --------------------

const SpySidebar = ({
  sectionList,
  setScrollToSection,
  currentScrollSection,
}) => {
  const sectionIds = Object.keys(sectionList)

  const goToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleSelection = eventKey => {
    if (eventKey === 'top') {
      goToTop()
    } else {
      setScrollToSection(eventKey)
    }
  }

  return (
    <SpySidebarContainer>
      <div className='spy-section' onClick={goToTop}>
        Top
      </div>
      {sectionIds.map(sId => (
        <div
          className={`spy-section ${
            sId === currentScrollSection ? 'active' : ''
          }`}
          key={sId}
          onClick={() => handleSelection(sId)}
        >
          {sectionList[sId]}
        </div>
      ))}
    </SpySidebarContainer>
  )
}

SpySidebar.propTypes = {}

export default SpySidebar
