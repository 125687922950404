// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import { NavContext } from 'contexts/NavContext'

// import hooks

// import utilities

// import common elements
import { Button } from 'react-bootstrap'

// import components

// import styles

// local constants

// --------------------

const GetPdfButton = props => {
  const { setPdfCardId } = React.useContext(NavContext)

  const Container = props.component || Button

  const getPdf = async () => {
    setPdfCardId(props.cardId)
  }

  return (
    <Container
      size='sm'
      variant='outline-info'
      onClick={getPdf}
      disabled={props.disabled}
    >
      PDF
    </Container>
  )
}

GetPdfButton.propTypes = {
  cardId: PropTypes.number.isRequired,
  component: PropTypes.object,
  disabled: PropTypes.bool,
}

export default GetPdfButton
