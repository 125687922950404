import {
  UPDATE_MEMORY,
  EMULATING_USER,
  SWITCH_CARD_FORMAT,
  TOGGLE_ACTIVE_ONLY,
  CLEAR_USER_DATA,
} from 'redux/action_types'

const otherFormat = state => (state.cardFormat === 'easy' ? 'print' : 'easy')

const memory = (
  state = {
    activeCard: 0,
    cardFormat: 'easy',
    activeOnly: false,
    compareCard: null,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_MEMORY:
      return { ...state, ...action.memory }

    case EMULATING_USER:
      return { ...state, emulating: action.userId }

    case SWITCH_CARD_FORMAT:
      return {
        ...state,
        cardFormat: otherFormat(state),
      }

    case TOGGLE_ACTIVE_ONLY:
      return {
        ...state,
        activeOnly: !state.activeOnly,
      }

    case CLEAR_USER_DATA:
      return { activeCard: 0, cardFormat: 'easy', activeOnly: false }

    default:
      return state
  }
}

export default memory
