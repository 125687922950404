// import packages
import React from 'react'
import PropTypes from 'prop-types'

// import apis

// import redux, selectors, actions

// import contexts
import {
  useCardState,
  useCardDispatch,
} from 'external/convention-cards/contexts/card-context'

// import hooks

// import utilities
import { parseSuits, getDisplayValue } from 'helpers'

// import common elements

// import components

// import styles
import { Text } from '../styles/parts'

// local constants

// --------------------

const Input = ({
  field,
  title,
  after,
  alert,
  announce,
  center,
  bigger,
  smaller,
  noPad,
  tight,
}) => {
  const { card, canEdit } = useCardState()
  const { updateText } = useCardDispatch()

  const styles = { alert, announce, center, bigger, smaller, noPad }
  const value = card(field) || ''

  return (
    <>
      {title && <Text {...styles}>{title}</Text>}
      <Text noPad={!!title} grow={!tight} tight={tight} {...styles}>
        {canEdit ? (
          <input
            className='text-input'
            type='text'
            name={field}
            value={value}
            onChange={updateText}
          />
        ) : (
          <div className='value'>{parseSuits(getDisplayValue(value))}</div>
        )}
      </Text>
      {after && (
        <Text noPad {...styles}>
          {after}
        </Text>
      )}
    </>
  )
}

Input.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.node,
  after: PropTypes.node,
  alert: PropTypes.bool,
  announce: PropTypes.bool,
  center: PropTypes.bool,
  bigger: PropTypes.bool,
  smaller: PropTypes.bool,
  tight: PropTypes.bool,
  noPad: PropTypes.bool,
}

export default Input
