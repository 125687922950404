import { get, getAsset, postAsset, post, patch, destroy } from 'api/bdex_api'

export const apiLoadCards = (authAxios, controls) => {
  get(authAxios, 'loading cards', 'cards', controls)
}

export const apiLoadAllCards = (authAxios, controls) => {
  get(authAxios, 'loading all cards', 'cards/all_cards', controls)
}

export const apiAddCard = (authAxios, title, from_id, controls) => {
  post(authAxios, 'adding card', 'cards', { title, from_id }, controls)
}

export const apiImportCardFromConfig = (
  authAxios,
  title,
  settings,
  notes,
  from_id,
  controls,
) => {
  post(
    authAxios,
    'adding card',
    'cards',
    { title, settings, notes, from_id },
    controls,
  )
}

export const apiUpdateCard = (authAxios, cardId, card, controls) => {
  patch(authAxios, 'updating card', `cards/${cardId}`, { card }, controls)
}

export const apiDeleteCard = (authAxios, cardId, controls) => {
  destroy(authAxios, 'deleting card', `cards/${cardId}`, controls)
}

export const apiCardSharing = (authAxios, cardId, controls) => {
  get(authAxios, 'loading card sharing', `cards/${cardId}/sharing`, controls)
}

export const apiShareCard = (authAxios, cardId, data, controls) => {
  patch(
    authAxios,
    'sharing card',
    `cards/${cardId}/share`,
    { ...data },
    controls,
  )
}

export const apiLeaveCard = (authAxios, cardId, controls) => {
  patch(authAxios, 'leaving card', `cards/${cardId}/leave`, {}, controls)
}

export const apiFetchCard = (authAxios, id, creatorId, controls) => {
  get(
    authAxios,
    'fetching card',
    `cards/${id}?creator_id=${creatorId}`,
    controls,
  )
}

export const apiGetPdf = (authAxios, id, controls) => {
  getAsset(authAxios, 'fetching pdf', `cards/${id}/get_pdf`, controls)
}

export const apiGetLargePrintPdf = (authAxios, id, controls) => {
  getAsset(
    authAxios,
    'fetching large print pdf',
    `cards/${id}/get_large_print_pdf`,
    controls,
  )
}

export const apiGetNotesPdf = (
  authAxios,
  id,
  footnotes,
  pdf_size,
  controls,
) => {
  postAsset(
    authAxios,
    'fetching notes pdf',
    `cards/${id}/get_notes_pdf`,
    { footnotes, pdf_size },
    controls,
  )
}
