// import packages
import React from 'react'
import { useHistory } from 'react-router-dom'

// import apis
import { apiLoadAllCards } from 'api/cards'

// import redux, selectors, actions
import { useSelector, useDispatch } from 'react-redux'
import { loadCards } from 'redux/actions/cards'

// import contexts
import { FetchContext } from 'contexts/FetchContext'

// import hooks

// import utilities

// import common elements
import { Table, Button } from 'react-bootstrap'
import { PageLayout } from 'components/elements/Layout'
import { Icon } from 'components/elements/Icons'

// import components
import AuthenticatedNav from 'components/navs/AuthenticatedNav'

// import styles

// local constants

// --------------------

const AdminCards = () => {
  const { authAxios } = React.useContext(FetchContext)
  const history = useHistory()
  const cards = useSelector(state => Object.values(state.cards))

  const reduxDispatch = useDispatch()

  const [sortBy, setSortBy] = React.useState('name')
  const [sortAsc, setSortAsc] = React.useState(true)

  const [loadedCards, setLoadedCards] = React.useState(false)

  React.useEffect(() => {
    const abortController = new AbortController()

    if (!loadedCards) {
      apiLoadAllCards(authAxios, {
        signal: abortController.signal,
        successDispatch: [reduxDispatch, loadCards],
        success: response => {
          setLoadedCards(true)
        },
      })
    }
    return () => {
      abortController.abort()
    }
  }, [loadedCards, authAxios, reduxDispatch])

  React.useEffect(() => {
    setSortAsc(sortBy === 'latest' ? false : true)
  }, [sortBy])

  const [DOWN, UP] = sortAsc ? [-1, 1] : [1, -1]

  const view = card => {
    history.push(`card/${card.creator_id}/${card.id}`)
  }

  const sortTitle = () => {
    if (sortBy === 'title') {
      setSortAsc(t => !t)
    } else {
      setSortBy('title')
    }
  }
  const sortCreator = () => {
    if (sortBy === 'creator') {
      setSortAsc(t => !t)
    } else {
      setSortBy('creator')
    }
  }
  const sortUpdated = () => {
    if (sortBy === 'updated') {
      setSortAsc(t => !t)
    } else {
      setSortBy('updated')
    }
  }

  const titleSort = (a, b) => {
    const t1 = a.title
    const t2 = b.title
    return t1 < t2 ? DOWN : t1 > t2 ? UP : 0
  }
  const creatorSort = (a, b) => {
    const c1 = a.creator_name
    const c2 = b.creator_name
    return c1 < c2 ? DOWN : c1 > c2 ? UP : 0
  }
  const updatedSort = (a, b) => {
    const l1 = a.updated_at || ''
    const l2 = b.updated_at || ''
    return l1 < l2 ? DOWN : l1 > l2 ? UP : 0
  }

  const currentSorter = () => {
    if (sortBy === 'title') {
      return titleSort
    } else if (sortBy === 'creator') {
      return creatorSort
    } else {
      return updatedSort
    }
  }

  const sortedCards = cards.sort(currentSorter())

  const sortIcon = field => {
    return field === sortBy ? (
      sortAsc ? (
        <Icon spaceAfter icon='up' />
      ) : (
        <Icon spaceAfter icon='down' />
      )
    ) : null
  }

  return (
    <PageLayout title='Cards'>
      <AuthenticatedNav />

      {loadedCards && (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th onClick={sortTitle}>{sortIcon('title')}Title</th>
              <th onClick={sortCreator}>{sortIcon('creator')}Creator</th>
              <th onClick={sortUpdated}>{sortIcon('updated')}Updated at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedCards.map(card => (
              <tr key={card.id}>
                <td>{card.title}</td>
                <td>{card.creator_name}</td>
                <td>{card.updated_at}</td>
                <td>
                  <Button size='sm' variant='link' onClick={() => view(card)}>
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </PageLayout>
  )
}

export default AdminCards
